<template>
  <div class="d-flex flex-column">
    <RadioForm
      v-for="k in ['all', 'parts']"
      :key="k"
      v-model:model-value="courceType"
      :value="k"
      :label="courceMap[k]"
      :form-id="k"
      name="select-cource-type"
    />
    <div class="inner-checks"></div>
  </div>

  <div
    v-if="courceType == courceTypes.PARTS"
    class="parts-selector d-flex flex-column"
  >
    <CheckForm
      v-for="pc in partsCources"
      :key="pc.cource_part"
      v-model:model-value="selectedParts"
      :value="pc.cource_part"
      name="select-cource-part"
      :form-id="pc.cource_part"
      :label="pc.title"
    />
  </div>
</template>

<script setup>
import { computed } from "@vue/reactivity";
import { ref, watch } from "vue";
import { useCources } from "../../Composables";
import { CheckForm, RadioForm } from "/@/vue/Components/Common";

const props = defineProps({
  cources: {
    type: Array,
    default: () => [],
  },
  selectedCources: {
    type: Array,
    default: null,
  },
});

const emit = defineEmits(["update:selectedCources"]);

const courceMap = {
  all: "全身コース(60,000円)",
  parts: "パーツコース(各20,000円)",
};

const courceType = ref(null);
const selectedParts = ref([]);

const { courceTypes, getPartsCources } = useCources();

const partsCources = computed(() => {
  if (!props.cources) {
    return [];
  }

  return getPartsCources(props.cources);
});

watch(courceType, (ct) => {
  if (ct == courceTypes.ALL) {
    emit(
      "update:selectedCources",
      props.cources?.filter((c) => c.cource_type == courceTypes.ALL)
    );
  } else {
    emit("update:selectedCources", []);
  }
});

watch(selectedParts, (sps) => {
  emit(
    "update:selectedCources",
    partsCources.value?.filter((pc) => sps.includes(pc.cource_part))
  );
});
</script>

<style lang="scss" scoped>
.parts-selector {
  padding-left: 1rem;
}
</style>
