<template>
  <ModalBackground :show="!lightup" deep-dark />
  <div v-if="true" id="app">
    <PageHeader
      :show="showUI"
      :current-route="currentRoute"
      :is-admin-page="isAdminPage"
      :is-uuid-access="isUuidAccess"
      :is-customer-info="isCustomerInfo"
      :current-customer="currentCustomer"
      :customer="customer"
      :no-animation="!isIndex"
      :logiouting="logouting"
      @logout="logoutExchanger"
      @to-reservations-new="toRe"
      @to-admin-qr="toAdminQr"
      @open-flyer="openFlyer"
    />
    <div
      :class="`main-view ${
        isCustomerInfo && !isAdminPage ? 'customer-show-view' : ''
      } ${isAdminPage ? 'is-admin' : ''}`"
    >
      <router-view></router-view>
    </div>

    <ScrollTopButton v-if="isIndex" :show="showUI" />

    <PageFooter v-if="!isAdminPage" />
  </div>
  <TopLoading v-if="false && !removeLoading" :loading="loading" />
</template>

<script setup>
import { watch, onMounted, ref, computed } from "vue";
import { useStore } from "vuex";
import { useAuthorize, useRouterUtil, useCustomer } from "/@/vue/Composables";
import { PageFooter, PageHeader } from "/@/vue/Components/Layouts";
import { ModalBackground, TopLoading } from "/@/vue/Components/UI";
import { ScrollTopButton } from "/@/vue/Components/Common";
import { errorHandle } from "/@/modules/error";
import { useRoute, useRouter } from "vue-router";
import useSWRV from "swrv";
import { fetcher } from "/@/modules/axios";

const store = useStore();

const pageLoading = ref(true);
const fontLoading = ref(true);
const removeLoading = ref(false);
const lightup = ref(false);

const showUI = computed(() => {
  return store.state.ui.showUI;
});

const {
  currentRoute,
  isIndex,
  isAdminPage,
  isUuidAccess,
  isCustomerInfo,
  toReservationsNew,
  toAdminReservationsNew,
  toAdminQr,
} = useRouterUtil();

const router = useRouter();

onMounted(async () => {
  await router.isReady();

  if (route.fullPath != "/" || sessionStorage.getItem("access")) {
    store.commit("ui/SET_SHOW_UI", true);
    lightup.value = true;
    sessionStorage.setItem("access", true);
  }

  setTimeout(() => {
    fontLoading.value = false;
    pageLoading.value = false;
    removeLoading.value = true;
    store.commit("ui/SET_SHOW_UI", true);
  }, 8000);

  Typekit.load({
    async: true,
    active: () => {
      fontLoading.value = false;
    },
  });

  pageLoading.value = false;

  await new Promise((resolve) => setTimeout(resolve, 2200));
  lightup.value = true;

  await new Promise((resolve) => setTimeout(resolve, 6500));
  store.commit("ui/SET_SHOW_UI", true);
});

const loading = computed(() => {
  return pageLoading.value || fontLoading.value;
});

function openFlyer() {
  window.open("/visit_flyer.pdf", "_blank");
}

const { logout } = useAuthorize();

const route = useRoute();
const customerId = computed(() => {
  if (isCustomerInfo.value) {
    return route.params.id;
  }
  return null;
});

const { data: currentCustomer, error: getCurrentCustomerError } = useSWRV(
  "/api/v1/customers/current",
  fetcher
);

watch(getCurrentCustomerError, (e) => {
  errorHandle(e);
});

const { data: customer, error: getCustomerError } = useSWRV(
  () => customerId.value && "/api/v1/customers/" + customerId.value,
  fetcher
);

watch(getCustomerError, (e) => {
  errorHandle(e);
});

const { logout: customerLogout } = useCustomer();

// admin

const { data: currentUser } = useSWRV("/api/v1/users", undefined, {
  shouldRetryOnError: false,
});

const logouting = ref(false);

async function logoutExchanger() {
  logouting.value = true;

  if (currentUser.value) {
    await logout();
  } else if (currentCustomer.value) {
    await customerLogout();
  }
}

function toRe() {
  if (!isAdminPage.value) {
    toReservationsNew("care");
  } else {
    toAdminReservationsNew({ customerId: customerId.value });
  }
}
</script>

<style lang="scss" scoped>
.main-view {
  display: flex;
  justify-content: center;
  padding-top: calc(#{$header-height} + #{$header-bottom-height});
  padding-bottom: 20px;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  min-height: 100vh;
  overflow-x: hidden;
  overflow-y: hidden;

  &:before {
    content: "";
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-image: url("../../../assets/images/background_mobile.png");
    background-size: 100% 100vh;
    background-position: center;
    z-index: -100;

    @include mq(pc) {
      background-image: url("../../../assets/images/background_pc.png");
    }
  }

  &.customer-show-view {
    padding-top: 120px;

    @include mq(pc) {
      padding-top: 108px;
    }
  }

  &.is-admin {
    padding-left: 0.75rem;
    padding-right: 0.75rem;

    &:before {
      content: "";
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      background-image: url("../../../assets/images/background_admin_mobile.png");
      background-position: center;
      background-size: 110% 110%;
      z-index: -100;

      @include mq(pc) {
        background-image: url("../../../assets/images/background_admin_pc.png");
        max-height: 100vh;
        padding-top: $header-height;
        padding-bottom: 30px;
        display: flex;
        justify-content: center;
      }
    }
  }
}

.scroll-top-btn {
  position: fixed;
  right: 12px;
  bottom: 1rem;
  z-index: 1000;

  @include mq(pc) {
    right: 2rem;
    bottom: 3rem;
  }
}
</style>
