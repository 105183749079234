<template>
  <IndexMenu v-if="isMobile" />
  <div v-else class="admin-index">
    <div class="d-flex w-100 h-100">
      <Customers
        v-model:selected-customer-id="selectedCustomerId"
        :customers="customers"
        @update-info="updateInfo"
        @update-contract-cource="updatePaymentAt"
        @delete-r-d="openCancelRDModal"
        @add-reservation-detail="toAddReservationDetail"
        @edit-reservation-detail="toEditReservationDetail"
        @update-datum="updateDatum"
      />
      <div class="ps-4"></div>
      <Reservations
        :reservation-details="
          targetDateReservationDetails.filter((rd) => !rd.done)
        "
        :shop-events="targetDateShopEvents"
        @mutate-reservation-details="getReservationDetailsMutate"
        @mutate-shop-events="getShopEventsMutate"
        @open-customer-page="selectedCustomerId = $event"
        @edit-reservation-detail="toEditReservationDetail"
        @edit-shop-event="toEditShopEvent"
      />
      <div class="ps-4"></div>
      <div class="d-flex flex-column w-100">
        <div v-if="isVisited" class="admin-card-title">
          <AdminDaySelector
            v-model:date="selectedDates"
            @add-event="currentOperationMode = modeTypes.ADD_EVENT"
            @add-reservation="currentOperationMode = modeTypes.SELECT_CUSTOMER"
          />
        </div>

        <Operation
          v-model:mode="currentOperationMode"
          v-model:customer-id="targetCustomerId"
          :customers="customers"
          :shop-event-id="targetShopEventId"
          :reservation-detail-id="targetReservationDetailId"
          :finished-reservation-details="finishedReservationDetails"
          :selected-date="selectedDates?.at(0)"
          :is-active="!isVisited"
          @to-customer-page="selectedCustomerId = $event"
          @update-datum="updateDatum"
        />
      </div>
    </div>
  </div>
  <teleport to="body">
    <CancelModal
      ref="cancelRDModal"
      @close="closeCancelRDModal"
      @delete="deleteRD"
    />
    <ModalBackground :show="!isVisited" quick />
  </teleport>
</template>

<script setup>
import {
  useContractCources,
  useCustomer,
  useMqUtils,
  useReservationDetails,
  useReservations,
  useShopEvents,
} from "/@/vue/Composables";
import {
  IndexMenu,
  Customers,
  Reservations,
  Operation,
} from "/@/vue/Components/Admin";
import { CancelModal, ModalBackground } from "/@/vue/Components/UI";
import { AdminDaySelector } from "/@/vue/Components/Common";
import useSWRV from "swrv";
import { ref, watch, computed, onMounted } from "vue";
import { errorHandle } from "/@/modules/error";
import { updateWhenAnyChange } from "/@/modules/api";
import { fetcher } from "/@/modules/axios";
import { luxonNow } from "/@/modules/luxon";

const { isMobile } = useMqUtils();

// operation

const modeTypes = {
  VISITED: "visited",
  ADD_EVENT: "add-event",
  ADD_RESERVATION: "add-reservation",
  EDIT_RESERVATION: "edit-reservation",
  SELECT_CUSTOMER: "select-customer",
};

const currentOperationMode = ref(modeTypes.VISITED);

const isVisited = computed(() => {
  return currentOperationMode.value == modeTypes.VISITED;
});

const targetReservationDetailId = ref(null);

function toEditReservationDetail(reservationDetailId) {
  targetReservationDetailId.value = reservationDetailId;
  currentOperationMode.value = modeTypes.EDIT_RESERVATION;
}

const targetCustomerId = ref(null);

function toAddReservationDetail(customerId) {
  targetCustomerId.value = customerId;
  currentOperationMode.value = modeTypes.ADD_RESERVATION;
}

const targetShopEventId = ref(null);

function toEditShopEvent(shopEventId) {
  targetShopEventId.value = shopEventId;
  currentOperationMode.value = modeTypes.ADD_EVENT;
}

// customers
const {
  data: customers,
  error,
  mutate: getCustomersMutate,
} = useSWRV("/api/v1/customers");
watch(error, (e) => {
  errorHandle(e);
});

const selectedCustomerId = ref();

const { createCustomerObjForShow, updateCustomer } = useCustomer();
const emailChange = ref(false);

async function updateInfo(editCustomerForm) {
  const customer = customers.value.find(
    (c) => c.id == selectedCustomerId.value
  );

  emailChange.value = await updateWhenAnyChange(
    createCustomerObjForShow(customer),
    editCustomerForm,
    updateCustomer
  );
  getCustomersMutate();
}

const { updateContractCource } = useContractCources();
const { removeReservationDetail } = useReservationDetails();

// delete RD
const cancelRDModal = ref();
const deleteTargetRDId = ref();

function openCancelRDModal(id) {
  deleteTargetRDId.value = id;
  cancelRDModal.value?.openModal();
}

function closeCancelRDModal() {
  cancelRDModal.value?.closeModal();
  deleteTargetRDId.value = null;
}

async function deleteRD() {
  await removeReservationDetail(deleteTargetRDId.value);
  getCustomersMutate();
  closeCancelRDModal();
}

async function updatePaymentAt(contractCource) {
  if (await updateContractCource(contractCource)) {
    getCustomersMutate();
  } else {
    alert("更新に失敗しました。ページをリロードしてからもう一度お試し下さい。");
  }
}

//  reservations

const {
  data: reservationDetails,
  error: getReservationDetailsError,
  mutate: getReservationDetailsMutate,
} = useSWRV("/api/v1/reservation_details", fetcher);

const { setReservationDetails, selectedDates, targetDateReservationDetails } =
  useReservations();
watch(reservationDetails, (rds) => {
  setReservationDetails(rds);
});
watch(getReservationDetailsError, (e) => {
  errorHandle(e);
});

onMounted(() => {
  setReservationDetails(reservationDetails.value);
});

selectedDates.value = [luxonNow];

const finishedReservationDetails = computed(() => {
  return targetDateReservationDetails.value?.filter((rd) => {
    return rd.done;
  });
});

// shopEvent

const { getSelectedDateShopEvents } = useShopEvents();

const {
  data: shopEvents,
  error: getShopEventsError,
  mutate: getShopEventsMutate,
} = useSWRV("/api/v1/shop_events", fetcher);
watch(getShopEventsError, (e) => {
  errorHandle(e);
});

const targetDateShopEvents = computed(() => {
  return getSelectedDateShopEvents(
    shopEvents.value,
    selectedDates.value?.at(0)?.toISO()
  );
});

function updateDatum() {
  targetShopEventId.value = null;
  getShopEventsMutate();
  getReservationDetailsMutate();
  getCustomersMutate();
}
</script>

<style lang="scss">
.admin-index {
  max-height: 100%;
  min-height: 100%;
  max-width: $contents-area-max-width;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.day-selector {
  padding-right: 50px;
}

.plus-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #201d16;
  padding: 0.5rem;

  &:hover {
    background-color: #201d16;
  }
}

a {
  text-decoration: none;
}
</style>
