<template>
  <div class="back-btn-container d-flex align-items-start">
    <button class="btn" @click="back">
      <BackButtonIcon class="back-button-icon" />
    </button>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
import { BackButtonIcon } from "/@/vue/Components/Icons";

const props = defineProps({
  roleButton: {
    type: Boolean,
  },
});

const emit = defineEmits(["back"]);

const router = useRouter();

function back() {
  if (props.roleButton) {
    emit("back");
    return;
  }

  if (window.history.length > 1) {
    router.back();
  } else {
    router.push({ name: "Index" });
  }
}
</script>

<style lang="scss" scoped>
.back-btn-container {
  max-width: $contents-area-max-width;
  padding: 0;

  .btn {
    border: none !important;
    padding-left: 0;
  }

  .back-btn-icon {
    height: 24px;
    width: 24px;
  }
}
</style>
