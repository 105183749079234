import { ref } from "vue";
import { Toast } from "bootstrap";

export function useCollapse() {
  const isOpen = ref(false);

  function prepareCollapse(element) {
    if (element) {
      element.addEventListener("shown.bs.collapse", function () {
        isOpen.value = true;
      });

      element.addEventListener("hidden.bs.collapse", function () {
        isOpen.value = false;
      });
    }
  }

  return {
    isOpen,
    prepareCollapse,
  };
}

export function useToast() {
  function createToast(element, delay = 5000) {
    return new Toast(element, { autohide: true, delay: delay });
  }

  return {
    createToast,
  };
}
