export function useUi() {
  function handleScroll(evt, el) {
    if (window.scrollY > 50) {
      el.classList.add("scrolled");
    } else {
      el.classList.remove("scrolled");
    }
  }

  return {
    handleScroll,
  };
}
