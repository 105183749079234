<template>
  <form class="w-100">
    <div class="mt-3">
      <label for="shop-event-title">予定名</label>
      <InputForm
        :value="title"
        :is-valid="validater.title"
        form-id="shop-event-title"
        placeholder="機器の点検など（必須）"
        @update:value="$emit('update:title', $event)"
      />
    </div>
    <div class="mt-3">
      <label for="shop-event-details">詳細</label>
      <text-area
        :value="details"
        :is-valid="validater.details"
        form-id="shop-event-details"
        placeholder="予定に関する詳細"
        @update:value="$emit('update:details', $event)"
      />
    </div>
  </form>
</template>

<script setup>
import { computed } from "@vue/reactivity";
import { validaterCheck } from "/@/modules/validation";
import { InputForm, TextArea } from "/@/vue/Components/Common";

const props = defineProps({
  title: {
    type: String,
    default: null,
  },
  details: {
    type: String,
    default: null,
  },
});

defineEmits(["update:title", "update:details"]);

const validater = computed(() => {
  return {
    title: !!props.title,
    details: true,
  };
});

const isValid = computed(() => {
  return validaterCheck(validater.value);
});

defineExpose({
  isValid,
});
</script>

<style lang="scss" scoped></style>
