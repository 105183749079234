export const customers = {
  1: {
    id: 1,
    name_last: "深田",
    name_first: "沙耶",
    name_last_kana: "フカダ",
    name_first_kana: "サヤ",
    email: "s.fukada@fryth.co.jp",
    phone_number: "09011112222",
    memo: null,
    reservation_details: [1, 15, 16],
    is_confirmed: true,
  },
  2: {
    id: 2,
    name_last: "竹中",
    name_first: "福善",
    name_last_kana: "タケナカ",
    name_first_kana: "フクヨシ",
    email: "f.takenaka@fryth.co.jp",
    phone_number: "09011112222",
    memo: null,
    is_confirmed: true,
    reservation_details: [4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
  },
  3: {
    id: 3,
    name_last: "佐藤",
    name_first: "裕司",
    name_last_kana: "サトウ",
    name_first_kana: "ヒロシ",
    email: "s1200191@gmail.com",
    phone_number: "09011112222",
    memo: null,
    is_confirmed: true,
  },
  4: {
    id: 4,
    name_last: "古河",
    name_first: "智弥",
    name_last_kana: "フルカワ",
    name_first_kana: "トモヤ",
    email: "s1200191@gmail.com",
    phone_number: "09011112222",
    memo: "メール未認証",
    is_confirmed: false,
  },
};

export const reservation_details_next_id = 17;
export const reservation_details = {
  // 2022-11-10
  15: {
    id: 15,
    reservation_at: "2022-11-25T18:00:00+0900",
    reservation_type: "care",
    done: true,
    customer_id: 1,
  },
  16: {
    id: 16,
    reservation_at: "2022-11-26T18:00:00+0900",
    reservation_type: "care",
    done: true,
    customer_id: 1,
  },

  // 2022-12-01
  1: {
    id: 1,
    reservation_at: "2022-12-01T18:00:00+0900",
    reservation_type: "care",
    done: false,
    customer_id: 1,
  },
  4: {
    id: 4,
    reservation_at: "2022-12-01T20:30:00+0900",
    reservation_type: "care",
    done: false,
    customer_id: 2,
  },
  // 2022-12-02
  // 2022-12-03
  5: {
    id: 5,
    reservation_at: "2022-12-03T09:00:00+0900",
    reservation_type: "care",
    done: false,
    customer_id: 2,
  },
  6: {
    id: 6,
    reservation_at: "2022-12-03T10:15:00+0900",
    reservation_type: "care",
    done: false,
    customer_id: 2,
  },
  7: {
    id: 7,
    reservation_at: "2022-12-03T11:30:00+0900",
    reservation_type: "care",
    done: false,
    customer_id: 2,
  },
  8: {
    id: 8,
    reservation_at: "2022-12-03T12:30:00+0900",
    reservation_type: "care",
    done: false,
    customer_id: 2,
  },
  9: {
    id: 9,
    reservation_at: "2022-12-03T13:15:00+0900",
    reservation_type: "care",
    done: false,
    customer_id: 2,
  },
  10: {
    id: 10,
    reservation_at: "2022-12-03T14:00:00+0900",
    reservation_type: "care",
    done: false,
    customer_id: 2,
  },
  11: {
    id: 11,
    reservation_at: "2022-12-03T15:15:00+0900",
    reservation_type: "care",
    done: false,
    customer_id: 2,
  },
  12: {
    id: 12,
    reservation_at: "2022-12-03T16:15:00+0900",
    reservation_type: "care",
    done: false,
    customer_id: 2,
  },

  // 2022-12-04
  13: {
    id: 13,
    reservation_at: "2022-12-04T10:15:00+0900",
    reservation_type: "care",
    done: false,
    customer_id: 2,
  },
  14: {
    id: 14,
    reservation_at: "2022-12-04T12:00:00+0900",
    reservation_type: "care",
    done: false,
    customer_id: 2,
  },
};

export const shop_events_next_id = 3;
export const shop_events = {
  1: {
    id: 1,
    title: "備品の点検",
    start_at: "2022-12-02T18:00:00+09:00",
    finish_at: "2022-12-02T19:00:00+09:00",
    detail: "詳細内容",
  },
  2: {
    id: 2,
    title: "業者対応",
    start_at: "2022-12-01T19:15:00+09:00",
    finish_at: "2022-12-01T19:30:00+09:00",
    detail: "業者対応",
  },
};

export const contract_cources = {
  1: {
    id: 1,
    customer_id: 1,
    cource_id: 1,
    cource: {
      id: 1,
      title: "全身コース",
      cource_type: "all",
      cource_part: "all",
      price: 60_000,
      required_number: 5,
    },
    payment_at: null,
  },
  2: {
    id: 2,
    customer_id: 2,
    cource_id: 4,
    cource: {
      id: 4,
      title: "ワキ",
      cource_type: "parts",
      cource_part: "armpit",
      price: 20_000,
      required_number: 5,
    },
    payment_at: "2022-12-03T17:00:00+09:00",
  },
  3: {
    id: 3,
    customer_id: 3,
    cource_id: 8,
    cource: {
      id: 8,
      title: "説明・相談",
      cource_type: "explain",
      cource_part: "explain",
      price: 0,
      required_number: 1,
    },
    payment_at: null,
  },
};

export const cources = {
  1: {
    id: 1,
    title: "全身コース",
    cource_type: "all",
    cource_part: "all",
    price: 60_000,
    required_number: 5,
  },
  2: {
    id: 2,
    title: "腕",
    cource_type: "parts",
    cource_part: "arm",
    price: 20_000,
    required_number: 5,
  },
  3: {
    id: 3,
    title: "脚",
    cource_type: "parts",
    cource_part: "leg",
    price: 20_000,
    required_number: 5,
  },
  4: {
    id: 4,
    title: "ワキ",
    cource_type: "parts",
    cource_part: "armpit",
    price: 20_000,
    required_number: 5,
  },
  5: {
    id: 5,
    title: "背中",
    cource_type: "parts",
    cource_part: "back",
    price: 20_000,
    required_number: 5,
  },
  6: {
    id: 6,
    title: "胸",
    cource_type: "parts",
    cource_part: "chest",
    price: 20_000,
    required_number: 5,
  },
  7: {
    id: 7,
    title: "へそ周り",
    cource_type: "parts",
    cource_part: "navel",
    price: 20_000,
    required_number: 5,
  },
  8: {
    id: 8,
    title: "説明・相談",
    cource_type: "explain",
    cource_part: "explain",
    price: 0,
    required_number: 1,
  },
};

export const feeds = [
  {
    id: "108172928128027_423431959962037",
    message:
      "今日は念願叶って西地区の住民でもあり、ミスター社協の藤田さんとお話する機会をいただけました。当法人として具体的に地域貢献していくかたちが見出せました。藤田さん、貴重なお時間ありがとうございました。",
    created_time: "2022-11-04T15:06:35+0000",
    full_picture:
      "https://scontent-nrt1-1.xx.fbcdn.net/v/t39.30808-6/314011836_423370036634896_1289643747347676686_n.jpg?stp=cp1_dst-jpg_p180x540&_nc_cat=100&ccb=1-7&_nc_sid=110474&_nc_ohc=KkwSwp5k-EIAX-ZWaau&_nc_ht=scontent-nrt1-1.xx&edm=AKK4YLsEAAAA&oh=00_AfANi3H39f6gki3RUQ7m3X8OVs19VHDukJA_Zv8OWwZoDg&oe=63AAC0BD",
    attachments: {
      data: [
        {
          description:
            "今日は念願叶って西地区の住民でもあり、ミスター社協の藤田さんとお話する機会をいただけました。当法人として具体的に地域貢献していくかたちが見出せました。藤田さん、貴重なお時間ありがとうございました。",
          media: {
            image: {
              height: 540,
              src: "https://scontent-nrt1-1.xx.fbcdn.net/v/t39.30808-6/314011836_423370036634896_1289643747347676686_n.jpg?stp=cp1_dst-jpg_p180x540&_nc_cat=100&ccb=1-7&_nc_sid=110474&_nc_ohc=KkwSwp5k-EIAX-ZWaau&_nc_ht=scontent-nrt1-1.xx&edm=AKK4YLsEAAAA&oh=00_AfANi3H39f6gki3RUQ7m3X8OVs19VHDukJA_Zv8OWwZoDg&oe=63AAC0BD",
              width: 720,
            },
          },
          target: {
            id: "423370039968229",
            url: "https://www.facebook.com/photo.php?fbid=423370039968229&set=a.381064077532159&type=3",
          },
          type: "photo",
          url: "https://www.facebook.com/photo.php?fbid=423370039968229&set=a.381064077532159&type=3",
        },
      ],
    },
    actions: [
      {
        name: "Like",
        link: "https://www.facebook.com/384086547229912/posts/423431959962037",
      },
      {
        name: "Comment",
        link: "https://www.facebook.com/384086547229912/posts/423431959962037",
      },
      {
        name: "Share",
        link: "https://www.facebook.com/384086547229912/posts/423431959962037",
      },
    ],
    permalink_url:
      "https://www.facebook.com/384086547229912/posts/423431959962037",
  },
  {
    id: "108172928128027_406685251636708",
    message:
      "この度、西地区のご好意で「西地区ふくし第63号」に「ケアマネステーション金沢」を掲載していただきました。当事業所をあたたかく迎え入れてくださった西地区の皆様に感謝申し上げます。この御恩に何かしらのお返し出来ればと思います。お気軽にお声掛けください。",
    created_time: "2022-10-12T06:45:07+0000",
    full_picture:
      "https://scontent-nrt1-1.xx.fbcdn.net/v/t39.30808-6/311580124_406685318303368_9060732229245610743_n.jpg?stp=cp1_dst-jpg_p180x540&_nc_cat=111&ccb=1-7&_nc_sid=110474&_nc_ohc=d2D30Y4aUjoAX_3y591&_nc_ht=scontent-nrt1-1.xx&edm=AKK4YLsEAAAA&oh=00_AfDURH1CB72Ioc4AlSECTejktxjfXrR2NvzGBGKEzEm1LQ&oe=63AB47EB",
    attachments: {
      data: [
        {
          media: {
            image: {
              height: 540,
              src: "https://scontent-nrt1-1.xx.fbcdn.net/v/t39.30808-6/311580124_406685318303368_9060732229245610743_n.jpg?stp=cp1_dst-jpg_p180x540&_nc_cat=111&ccb=1-7&_nc_sid=110474&_nc_ohc=d2D30Y4aUjoAX_3y591&_nc_ht=scontent-nrt1-1.xx&edm=AKK4YLsEAAAA&oh=00_AfDURH1CB72Ioc4AlSECTejktxjfXrR2NvzGBGKEzEm1LQ&oe=63AB47EB",
              width: 720,
            },
          },
          subattachments: {
            data: [
              {
                media: {
                  image: {
                    height: 540,
                    src: "https://scontent-nrt1-1.xx.fbcdn.net/v/t39.30808-6/311580124_406685318303368_9060732229245610743_n.jpg?stp=cp1_dst-jpg_p180x540&_nc_cat=111&ccb=1-7&_nc_sid=110474&_nc_ohc=d2D30Y4aUjoAX_3y591&_nc_ht=scontent-nrt1-1.xx&edm=AKK4YLsEAAAA&oh=00_AfDURH1CB72Ioc4AlSECTejktxjfXrR2NvzGBGKEzEm1LQ&oe=63AB47EB",
                    width: 720,
                  },
                },
                target: {
                  id: "406685091636724",
                  url: "https://www.facebook.com/photo.php?fbid=406685091636724&set=a.381064077532159&type=3",
                },
                type: "photo",
                url: "https://www.facebook.com/photo.php?fbid=406685091636724&set=a.381064077532159&type=3",
              },
              {
                media: {
                  image: {
                    height: 540,
                    src: "https://scontent-nrt1-1.xx.fbcdn.net/v/t39.30808-6/311585282_406685328303367_597509271074220479_n.jpg?stp=cp1_dst-jpg_p180x540&_nc_cat=101&ccb=1-7&_nc_sid=110474&_nc_ohc=ps8nBthXxGoAX9oo_IF&_nc_ht=scontent-nrt1-1.xx&edm=AKK4YLsEAAAA&oh=00_AfDomLGvmuqjjFdWw6Vv4wKvdewxmEG9Wj82ckL9O0aFtQ&oe=63AA77E8",
                    width: 720,
                  },
                },
                target: {
                  id: "406685088303391",
                  url: "https://www.facebook.com/photo.php?fbid=406685088303391&set=a.381064077532159&type=3",
                },
                type: "photo",
                url: "https://www.facebook.com/photo.php?fbid=406685088303391&set=a.381064077532159&type=3",
              },
            ],
          },
          target: {
            id: "406685251636708",
            url: "https://www.facebook.com/384086547229912/posts/406685251636708",
          },
          title: "ケアマネステーション金沢さんの投稿の写真",
          type: "album",
          url: "https://www.facebook.com/384086547229912/posts/406685251636708",
        },
      ],
    },
    actions: [
      {
        name: "Like",
        link: "https://www.facebook.com/384086547229912/posts/406685251636708",
      },
      {
        name: "Comment",
        link: "https://www.facebook.com/384086547229912/posts/406685251636708",
      },
      {
        name: "Share",
        link: "https://www.facebook.com/384086547229912/posts/406685251636708",
      },
    ],
    permalink_url:
      "https://www.facebook.com/384086547229912/posts/406685251636708",
  },
  {
    id: "108172928128027_348351267443524",
    message:
      "今日は金沢市の西地区社協会長さん、西地区民生児童委員会長さんにお会いし、ケアマネステーション金沢が地域サロン、コミュニティーカフェでお手伝いさせて頂くご相談させていただきました。温かく迎えて下さり、また貴重なお時間を頂きまして、ありがとうございました。セッティングして下さった金沢市社会福祉協議会の職員さんにも感謝です。地域に根ざした事業所になれたらいいなぁ。",
    created_time: "2022-06-16T03:01:30+0000",
    full_picture:
      "https://scontent-nrt1-1.xx.fbcdn.net/v/t39.30808-6/288450836_348346100777374_2422979893969987400_n.jpg?stp=dst-jpg_p180x540&_nc_cat=108&ccb=1-7&_nc_sid=110474&_nc_ohc=NNbaELF96UAAX-wpCNe&_nc_ht=scontent-nrt1-1.xx&edm=AKK4YLsEAAAA&oh=00_AfDAYD49R-bJ5VJceMv_jQo7q7Dspg5XpiH6JTrVKucbug&oe=63ABDA62",
    attachments: {
      data: [
        {
          description:
            "今日は金沢市の西地区社協会長さん、西地区民生児童委員会長さんにお会いし、ケアマネステーション金沢が地域サロン、コミュニティーカフェでお手伝いさせて頂くご相談させていただきました。温かく迎えて下さり、また貴重なお時間を頂きまして、ありがとうございました。セッティングして下さった金沢市社会福祉協議会の職員さんにも感謝です。地域に根ざした事業所になれたらいいなぁ。",
          media: {
            image: {
              height: 540,
              src: "https://scontent-nrt1-1.xx.fbcdn.net/v/t39.30808-6/288450836_348346100777374_2422979893969987400_n.jpg?stp=dst-jpg_p180x540&_nc_cat=108&ccb=1-7&_nc_sid=110474&_nc_ohc=NNbaELF96UAAX-wpCNe&_nc_ht=scontent-nrt1-1.xx&edm=AKK4YLsEAAAA&oh=00_AfDAYD49R-bJ5VJceMv_jQo7q7Dspg5XpiH6JTrVKucbug&oe=63ABDA62",
              width: 720,
            },
          },
          target: {
            id: "348346104110707",
            url: "https://www.facebook.com/caremanagerstation/photos/a.114498610828792/348346104110707/?type=3",
          },
          type: "photo",
          url: "https://www.facebook.com/caremanagerstation/photos/a.114498610828792/348346104110707/?type=3",
        },
      ],
    },
    actions: [
      {
        name: "Like",
        link: "https://www.facebook.com/108172928128027/posts/348351267443524/",
      },
      {
        name: "Comment",
        link: "https://www.facebook.com/108172928128027/posts/348351267443524/",
      },
      {
        name: "Share",
        link: "https://www.facebook.com/108172928128027/posts/348351267443524/",
      },
    ],
    permalink_url:
      "https://www.facebook.com/108172928128027/posts/348351267443524/",
  },
  {
    id: "108172928128027_348345320777452",
    message:
      "今年も介護労働安定センターさんから、令和4年度介護労働講習にお声をかけてもらいました。\n自己研鑽の機会になり、感謝します。\n受講者さんと共に社会保障制度を学びたいとおもいます。",
    created_time: "2022-06-16T02:49:36+0000",
    full_picture:
      "https://scontent-nrt1-1.xx.fbcdn.net/v/t39.30808-6/288653807_348343080777676_1211669278473571568_n.jpg?stp=dst-jpg_p180x540&_nc_cat=103&ccb=1-7&_nc_sid=110474&_nc_ohc=gTjMjYjUOlcAX8J5CpM&_nc_ht=scontent-nrt1-1.xx&edm=AKK4YLsEAAAA&oh=00_AfDokPfZA8EhJJ7V9u9ZjQGl-u8mBmYOsWd_DLgsOCEG6w&oe=63AA7631",
    attachments: {
      data: [
        {
          description:
            "今年も介護労働安定センターさんから、令和4年度介護労働講習にお声をかけてもらいました。\n自己研鑽の機会になり、感謝します。\n受講者さんと共に社会保障制度を学びたいとおもいます。",
          media: {
            image: {
              height: 540,
              src: "https://scontent-nrt1-1.xx.fbcdn.net/v/t39.30808-6/288653807_348343080777676_1211669278473571568_n.jpg?stp=dst-jpg_p180x540&_nc_cat=103&ccb=1-7&_nc_sid=110474&_nc_ohc=gTjMjYjUOlcAX8J5CpM&_nc_ht=scontent-nrt1-1.xx&edm=AKK4YLsEAAAA&oh=00_AfDokPfZA8EhJJ7V9u9ZjQGl-u8mBmYOsWd_DLgsOCEG6w&oe=63AA7631",
              width: 720,
            },
          },
          target: {
            id: "348343087444342",
            url: "https://www.facebook.com/caremanagerstation/photos/a.114498610828792/348343087444342/?type=3",
          },
          type: "photo",
          url: "https://www.facebook.com/caremanagerstation/photos/a.114498610828792/348343087444342/?type=3",
        },
      ],
    },
    actions: [
      {
        name: "Like",
        link: "https://www.facebook.com/108172928128027/posts/348345320777452/",
      },
      {
        name: "Comment",
        link: "https://www.facebook.com/108172928128027/posts/348345320777452/",
      },
      {
        name: "Share",
        link: "https://www.facebook.com/108172928128027/posts/348345320777452/",
      },
    ],
    permalink_url:
      "https://www.facebook.com/108172928128027/posts/348345320777452/",
  },
];
