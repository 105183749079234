<template>
  <div class="contents-wrapper">
    <div class="contents-area index-card">
      <div :class="`d-flex justify-content-center align-items-start w-100`">
        <div
          :class="`service-area bg-white d-flex justify-content-center align-items-center ${
            isMobile ? '' : 'w-100'
          }`"
        >
          <div class="service-image-container d-flex flex-column-center h-100">
            <ServiceImage class="service-image" loading="lazy" />
          </div>

          <div v-if="!isMobile" class="flex-column-center h-100 service-text">
            <div class="w-100">
              <ServiceTitle />
            </div>

            <div class="flex-column-center">
              <div v-for="st in serviceTexts" :key="st" class="pt-4">
                <ServiceItem v-bind="st" />
              </div>
            </div>
          </div>

          <div class="h-100 bg-white">
            <VerticalTitle title="service" />
          </div>
        </div>
      </div>
      <template v-if="isMobile">
        <div class="w-100 pt-5">
          <ServiceTitle />
        </div>

        <div class="flex-column-center">
          <div v-for="st in serviceTexts" :key="st" class="pt-5">
            <ServiceItem v-bind="st" />
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
import { VerticalTitle } from "/@/vue/Components/Common";
import { IPL, SHR, ServiceImage } from "/@/vue/Components/Images";
import { useMqUtils } from "/@/vue/Composables/mq";
import ServiceItem from "./ServiceItem.vue";
import ServiceTitle from "./ServiceTitle.vue";

const { isMobile } = useMqUtils();

const serviceTexts = [
  {
    title: "SHR脱毛",
    texts: [
      "バルジ領域に低温の光を当てて行う脱毛。美肌効果も期待でき、産毛にもしっかり効き目を発揮。1 回の施術は約 30 分、高速連射技術を広範囲に施術できることで脱毛にかかる時間を従来の 1/3 に短縮。効果実感まで半年から 1年。熱さ・痛みも従来の脱毛方式に比べ軽減されています。",
    ],
    image: SHR,
  },
  {
    title: "IPL脱毛",
    texts: [
      "医療レーザーの出力を抑えた脱毛。毛のメラニンに反応し、毛母細胞にダメージを与え、再生を抑制。毛の周期に合わせた施術が必要なため、効果をしっかり実感できるのは4~5回目の脱毛を終えたころ。",
    ],
    image: IPL,
  },
];
</script>

<style lang="scss" scoped>
.contents-area.index-card {
  padding-bottom: 45px;

  @include mq(pc) {
    padding: 50px;
  }

  > * {
    padding: 0 30px;
  }
}
.service-area {
  @include mq(pc) {
    padding-left: 50px;
  }
}

.service-image-container {
  @include mq(pc) {
    width: 440px;
  }

  .service-image {
    width: 100%;
    height: 370px;
    object-fit: contain;

    @include mq(pc) {
      height: 580px;
      object-fit: contain;
    }
  }
}

.service-text {
  @include mq(pc) {
    padding-left: 48px;
  }
}

.text-area {
  padding: 0 30px;
}
</style>
