<template>
  <div class="contents-wrapper">
    <div class="contents-area">
      <NewProcess
        :all-reservation-details="reservationDetails"
        :all-shop-events="shopEvents"
        :holidays="holidays"
        :customer="customer"
        :after-submit="afterSubmit"
        :loading="loading"
        @submit="submit"
        @reset-error="resetError"
      />
      <Errors :errors="errors" />
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from "vue";
import { NewProcess } from "/@/vue/Components/Reservation";
import { Errors } from "/@/vue/Components/Common";
import { useCustomer, useReservationDetails } from "/@/vue/Composables";
import { errorHandle } from "/@/modules/error";
import useSWRV from "swrv";
import { fetcher } from "/@/modules/axios";

const { newReservationDetail } = useReservationDetails();

const { data: reservationDetails, mutate: getReservationDetailsMutate } =
  useSWRV("/api/v1/reservation_details?for_reserve=true", fetcher);
const { data: shopEvents, mutate: getShopEventsMutate } = useSWRV(
  "/api/v1/shop_events",
  fetcher
);
const { data: holidays } = useSWRV("/api/v1/calendar/get_holidays", fetcher);

// input Customer info
const { getCurrentCustomer } = useCustomer();
const { data: customer, error: getCustomerError } = getCurrentCustomer();

watch(getCustomerError, (e) => {
  errorHandle(e);
});

const loading = ref(false);
const errors = ref([]);
const afterSubmit = ref(false);

async function submit({ reservationDetail, customer, contractCources }) {
  loading.value = true;

  const success = await newReservationDetail(
    reservationDetail,
    customer,
    contractCources,
    errors
  );

  if (!success) {
    if (!errors.value.length) {
      errors.value = [
        "予約の作成に失敗しました。",
        "もう一度お試し頂くかお電話での御予約をお願いします。",
      ];
    }
  } else {
    afterSubmit.value = true;
    errors.value = [];
  }
  loading.value = false;
}

function resetError() {
  errors.value = [];
  getReservationDetailsMutate();
  getShopEventsMutate();
}
</script>

<style lang="scss" scoped></style>
