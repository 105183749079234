export function validaterCheck(validater) {
  for (let key in validater) {
    if (!validater[key]) {
      return false;
    }
  }
  return true;
}

export function validationToClass(isValid) {
  return isValid ? "is-valid" : "is-invalid";
}

export const emailMatcher =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export function isValidEmail(email) {
  if (!email) {
    return false;
  }
  return !!email.toLowerCase().match(emailMatcher);
}

if (import.meta.vitest) {
  const { it, expect } = import.meta.vitest;

  it("validaterCheck", () => {
    expect(validaterCheck({ a: true, b: false })).toBe(false);
    expect(validaterCheck({ a: true, b: true })).toBe(true);
  });

  it("validationToClass", () => {
    expect(validationToClass(true)).toBe("is-valid");
    expect(validationToClass(false)).toBe("is-invalid");
  });

  it("isValidEmail", () => {
    expect(isValidEmail("s1111@gmail.com")).toBe(true);
    expect(isValidEmail("s1111@gamil.c")).toBe(false);
  });
}
