import { createCustomerObj } from "/@/modules/customer";
import { fetcher } from "/@/modules/axios";
import * as DB from "./db";
import useSWRV from "swrv";

function getByEmail(customers, email) {
  return customers.find((c) => c.email == email);
}

export default ($axios) => ({
  namespaced: true,
  state: {
    customers: { 1: DB.customers[1], 2: DB.customers[2] },
    nextId: 3,
  },
  getters: {
    customersArray(state) {
      return [...Object.values(state.customers)];
    },
  },
  mutations: {
    ADD(state, customer) {
      const id = state.nextId;
      customer.id = id;
      state.customers[id] = { ...customer };
      state.nextId++;

      return state.customers[id];
    },
    UPDATE(state, { id, ...rest }) {
      const current = state.customers[id];
      state.customers[id] = {
        ...current,
        ...rest,
      };
    },
    ADD_RESERVATION_DETAIL_ID(state, { id, reservation_details }) {
      const current = state.customers[id].reservation_details;
      state.customers[id].reservation_details =
        current.concat(reservation_details);
    },
    DELETE_RESERVATION_DETAIL(state, { id, customerId }) {
      const current = state.customers[customerId].reservation_details;
      state.customers[customerId].reservation_details = current.filter(
        (rdId) => rdId != id
      );
    },
  },
  actions: {
    ADD({ commit, getters }, customer) {
      const customerObj = createCustomerObj(customer);

      const existsCustomer = getByEmail(
        getters.customersArray,
        customerObj.email
      );

      if (!existsCustomer) {
        commit("ADD", customerObj);
        const newCustomer = getByEmail(
          getters.customersArray,
          customerObj.email
        );
        return newCustomer;
      } else {
        commit("ADD_RESERVATION_DETAIL_ID", {
          id: existsCustomer.id,
          reservation_details: customerObj.reservation_details,
        });
        return existsCustomer;
      }
    },

    UPDATE({ commit }, customer) {
      commit("UPDATE", customer);
    },

    async GET_CURRENT_CUSTOMER() {
      return useSWRV("/api/v1/customers/current", fetcher);
    },

    async GET_CURRENT_CUSTOMER_AXIOS() {
      const result = await $axios
        .get("/api/v1/customers/current")
        .catch((e) => {
          console.error(e);
        });

      if (!result || !result.data) {
        return false;
      }

      const user = result.data;

      return user;
    },
  },
});
