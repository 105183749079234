<template>
  <div class="card card-body flex-column-center">
    <div class="flex-row-center justify-content-center w-100">
      <div class="mimoza-container">
        <Mimoza class="mimoza-image left" loading="lazy" />
      </div>
      <div class="d-flex flex-row">
        <span class="badge bg-yellow me-1 text-nowrap">{{ badgeText }}</span>
        <div class="text-nowrap">{{ subText }}</div>
      </div>

      <div class="mimoza-container">
        <Mimoza class="mimoza-image right" loading="lazy" />
      </div>
    </div>

    <div class="title">
      {{ titleText }}
    </div>

    <div class="d-flex flex-column align-items-end">
      <div class="d-flex justify-content-center w-100">
        <div
          v-for="(p, i) in parts"
          :key="p"
          :class="`badge parts-badge ${i != 0 ? 'ms-1' : ''}`"
        >
          {{ p }}
        </div>
      </div>
      <span class="small-font">※顔・VIO対象外</span>
    </div>

    <div class="d-flex align-items-center">
      <div class="yellow-glass flex-column-center me-3">
        <span class="text-nowrap">1クール</span>
        <span class="text-nowrap">(5回分)</span>
      </div>
      <div class="d-flex align-items-end">
        <span class="price">{{ price.toLocaleString() }}</span>
        <span class="unit">円(税込)</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { Mimoza } from "/@/vue/Components/Images";

defineProps({
  subText: {
    type: String,
    default: null,
  },
  badgeText: {
    type: String,
    default: null,
  },
  titleText: {
    type: String,
    default: null,
  },
  price: {
    type: Number,
    default: null,
  },
});

const parts = ["腕", "脚", "ワキ", "背中", "胸部", "へそ周り"];
</script>

<style lang="scss" scoped>
.bg-yellow {
  background-color: #ffdd00;
  color: black;
}

.card {
  position: relative;
  padding: 16px 18px;
  padding-top: 0;
  margin: 15px;
  box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
  background-color: #f6f6f6;

  @include mq(pc) {
    width: 387px;
    height: 273px;
  }

  &:before {
    content: "";
    position: absolute;
    top: -5px;
    bottom: 5px;
    right: 5px;
    left: -5px;
    border: 1px solid black;
    z-index: 10;
  }
}

.mimoza-container {
  .mimoza-image {
    width: 22px;
    height: 62px;

    &.left {
      transform: rotate(-90deg);
      margin-right: 18px;
    }

    &.right {
      transform: rotate(90deg);
      margin-left: 18px;
    }
  }
}

.title {
  margin-top: -10px;
  font-size: 36px;

  @include mq(pc) {
    font-size: 45px;
  }
}

.parts-badge {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #cccccc;
  color: black;
  height: 20px;
  min-width: 36px;
  font-weight: lighter;

  @include mq(pc) {
    font-size: 16px;
    height: 24px;
    min-width: 40px;
  }
}

.yellow-glass {
  border-radius: 50%;
  background-color: #ffdd00;
  height: 50px;
  width: 50px;
  position: relative;
  z-index: 1;

  > * {
    z-index: 2;
  }

  &:before {
    content: "";
    background: inherit; /*.bgImageで設定した背景画像を継承する*/
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(5px);
    position: absolute;
    /*ブラー効果で画像の端がボヤけた分だけ位置を調整*/
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    z-index: 0;
    border-radius: 50%;
  }
}

.price {
  font-size: 36px;

  @include mq(pc) {
    font-size: 45px;
  }
}

.unit {
  font-size: 17px;
  margin-bottom: 7px;

  @include mq(pc) {
    font-size: 20px;
    margin-bottom: 10px;
  }
}
</style>
