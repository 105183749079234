<template>
  <div v-if="isMobile" class="d-flex align-items-center">
    <div
      v-if="currentCustomer && !isAdminPage"
      class="d-flex flex-row align-items-center"
    >
      <span v-if="false">{{ currentCustomer.name_last }} 様</span>
      <button
        class="btn btn-logout flex-column-center"
        type="button"
        @click.stop="$emit('logout')"
      >
        <LogoutIcon class="icon" />
        <span class="small-font">logout</span>
      </button>
    </div>

    <router-link
      v-else-if="!isAdminPage"
      :to="{ name: 'CustomersSignIn' }"
      class="flex-column-center"
    >
      <LoginIcon class="icon" />
      <span class="small-font">login</span>
    </router-link>
    <button
      class="navbar-toggler"
      type="button"
      @click="$emit('toggleNavMenu')"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
  </div>

  <div v-else class="d-flex pe-3">
    <template v-if="isAdminPage">
      <div v-for="(mi, i) in []" :key="mi.to" :class="i != 0 ? 'ms-4' : ''">
        <NavMenuItem v-bind="mi" is-pc />
      </div>
      <button
        class="btn nav-menu-item d-flex flex-column-center ms-4"
        @click="$emit('toAdminQr')"
      >
        <span class="segoe nav-title admin">QR Test</span>
      </button>
      <button
        class="btn nav-menu-item d-flex flex-column-center ms-4"
        @click="$emit('logout')"
      >
        <span class="segoe nav-title admin">logout</span>
      </button>
    </template>
    <template v-else>
      <div
        v-for="(mi, i) in indexMenuItems"
        :key="mi.anchor"
        :class="i != 0 ? 'ms-4' : ''"
      >
        <NavMenuItem v-bind="mi" is-pc />
      </div>
      <div class="ms-4">
        <button
          class="btn nav-menu-item d-flex flex-column-center"
          @click="$emit('openFlyer')"
        >
          <span class="segoe nav-title">visit</span>
          <span class="jptitle">ご来店</span>
        </button>
      </div>
      <div v-if="!currentCustomer" class="ms-4">
        <NavMenuItem
          title="login"
          jptitle="ログイン"
          to="CustomersSignIn"
          is-pc
        ></NavMenuItem>
      </div>
      <div v-else class="ms-4">
        <button
          v-if="!logouting"
          class="btn nav-menu-item d-flex flex-column-center"
          type="button"
          @click="$emit('logout')"
        >
          <span class="segoe nav-title">logout</span>
          <span class="jptitle">ログアウト</span>
        </button>
        <Loading v-else />
      </div>
    </template>
  </div>
</template>

<script setup>
import { useIndexPage, useMqUtils } from "/@/vue/Composables";
import NavMenuItem from "./NavMenuItem.vue";
import { Loading, LoginIcon, LogoutIcon } from "/@/vue/Components/Icons";

defineProps({
  isAdminPage: {
    type: Boolean,
  },
  isCustomerInfo: {
    type: Boolean,
  },
  currentCustomer: {
    type: Object,
    default: () => {},
  },
  logouting: {
    type: Boolean,
  },
});

defineEmits(["toggleNavMenu", "logout", "openFlyer", "toAdminQr"]);

const { isMobile } = useMqUtils();
const { indexMenuItems } = useIndexPage();
</script>

<style lang="scss" scoped>
@import "./nav_menu_item.scss";

.navbar-toggler {
  border: none;
}

.btn {
  padding: 0.25rem;

  &.btn-logout {
    padding: 0;
  }
}

.admin.nav-title {
  font-size: $title-font-size;
}

a {
  text-decoration: none;
  cursor: pointer;
}
</style>
