export default ($axios) => ({
  namespaced: true,
  state: {
    user: null,
  },

  mutations: {
    SET_USER(state, user) {
      state.user = user;
    },

    LOGOUT(state) {
      state.user = null;
    },
  },

  actions: {
    SET_USER({ commit }, user) {
      commit("SET_USER", user);
    },

    async GET_USER({ dispatch }) {
      const result = await $axios.get("/api/v1/users").catch((e) => {
        //console.error(e);
      });

      if (!result || !result.data) {
        return false;
      }

      const user = result.data;
      dispatch("SET_USER", user);

      return user;
    },

    async SIGNUP({ _ }, inputForm) {
      const { name, email, password, passwordConfirmation } = inputForm;

      return await $axios.post("/api/v1/sign_up", {
        user: {
          name,
          email,
          password,
          password_confirmation: passwordConfirmation.value,
        },
      });
    },

    async LOGOUT({ commit }) {
      await $axios.post("/api/v1/logout").catch((e) => console.error(e));
      commit("LOGOUT");
    },
  },
});
