export function errorHandle(e) {
  console.error(e);

  if (e.response.status === 426) {
    window.alert("アップデートがあるためページを更新します");
    window.location.reload();
    return;
  }

  if (e.response.data && e.response.data.errors) {
    //errors.value = e.response.data.errors;
    console.error(e.response.data.errors);
    return e.response.data.errors;
  }

  return [];
}
