<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7.071 12.728">
    <g
      id="arrow_button_black"
      transform="translate(-13.435 -113.137) rotate(45)"
    >
      <line
        id="線_36"
        y2="8"
        transform="translate(94.5 66.5)"
        fill="none"
        stroke="#201D16"
        stroke-linecap="round"
        stroke-width="1"
      />
      <line
        id="線_37"
        y2="8"
        transform="translate(102.5 74.5) rotate(90)"
        fill="none"
        stroke="#201D16"
        stroke-linecap="round"
        stroke-width="1"
      />
    </g>
  </svg>
</template>
