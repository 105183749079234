<template>
  <div v-if="!showForm" class="d-flex flex-column-center">
    <div
      v-if="!selectedDateTime"
      class="top-image d-flex flex-column align-items-center w-100"
    >
      <WeekSelector
        v-model:dates="selectedDates"
        :now="now"
        :is-admin="isAdmin"
      />
      <div v-if="!isAdmin" class="top">
        <ReservationTop />
      </div>
    </div>
    <div>
      <CurrentReservations
        v-model:selected-date-time="selectedDateTime"
        :reservations="targetDateReservations"
        :holidays="holidays"
        :now="now"
        :is-admin="isAdmin"
      />
    </div>
    <div class="next pb-3">
      <div v-if="selectedDateTime" class="next-btn">
        <BasicButton @click="inputCustomerInfo">次へ</BasicButton>
      </div>
    </div>
  </div>
  <div v-else class="pt-4 d-flex flex-column align-items-center">
    <template v-if="!showConfirm">
      <span class="mb-5">お客様情報の入力</span>
      <New
        :customer="customer"
        :reservation_type="customer ? 'care' : 'explain'"
        :contract-cources="customerContractCources"
        @confirm="confirm"
        @cancel="cancel"
      ></New>
    </template>
    <template v-else>
      <Confirm
        :reservation="confirmedReservationDetail"
        :after-submit="afterSubmit"
        :loading="loading"
        :is-admin="isAdmin"
        :is-pc-admin="isPcAdmin"
        @back="backNew"
        @submit="submit"
        @to-top="$emit('toTop')"
        @to-admin-reservations="$emit('toAdminReservations')"
        @to-customers-index="$emit('toCustomersIndex')"
      />
    </template>
  </div>
</template>

<script setup>
import { onMounted, ref, computed, watch } from "vue";
import { luxonNow } from "/@/modules/luxon";
import { useReservations } from "/@/vue/Composables";
import { WeekSelector, BasicButton } from "/@/vue/Components/Common";
import { CurrentReservations, ReservationTop, New, Confirm } from ".";
import { scrollToTop } from "/@/modules/ui";

const props = defineProps({
  allReservationDetails: {
    type: Array,
    default: () => [],
  },
  allShopEvents: {
    type: Array,
    default: () => [],
  },
  holidays: {
    type: Object,
    default: () => {},
  },
  isAdmin: {
    type: Boolean,
  },
  isPcAdmin: {
    type: Boolean,
  },
  customer: {
    type: Object,
    default: null,
  },
  now: {
    type: Object,
    default: () => luxonNow,
  },
  afterSubmit: {
    type: Boolean,
  },
  loading: {
    type: Boolean,
  },
});

const emit = defineEmits([
  "submit",
  "resetError",
  "toTop",
  "toAdminReservations",
  "toCustomersIndex",
]);

const selectedDateTime = ref();
const {
  selectedDates,
  setHolidays,
  setReservationDetails,
  setShopEvents,
  targetDateReservations,
} = useReservations();

onMounted(() => {
  selectedDates.value = [
    props.now.plus({ day: 1 }).startOf("week").minus({ day: 1 }),
    props.now.plus({ day: 1 }).endOf("week").minus({ day: 1 }),
  ];
  setHolidays(props.holidays);

  if (selectedDates.value[1].hasSame(props.now, "day")) {
    selectedDates.value = selectedDates.value.map((d) => d.plus({ week: 1 }));
  }

  setReservationDetails(props.allReservationDetails);
  setShopEvents(props.allShopEvents);
});

watch(
  () => props.holidays,
  (hs) => {
    setHolidays(hs);
  }
);
watch(
  () => props.allReservationDetails,
  (ards) => {
    setReservationDetails(ards);
  }
);

watch(
  () => props.allShopEvents,
  (ases) => {
    setShopEvents(ases);
  }
);

const showForm = ref(false);

async function inputCustomerInfo() {
  scrollToTop();
  showForm.value = true;
}

const customerContractCources = computed(() => {
  if (props.customer) {
    return props.customer.contract_cources;
  } else {
    return [];
  }
});

const showConfirm = ref(false);
const confirmedReservationDetail = ref();

async function confirm(reservation) {
  // reservation = { customer: { name_first: ... }, done: false, reservation_type: ['care', 'explain'] }
  scrollToTop();

  confirmedReservationDetail.value = {
    ...reservation,
    reservation_at: selectedDateTime.value,
  };
  showConfirm.value = true;
}

function cancel() {
  showForm.value = false;
  selectedDateTime.value = null;
  confirmedReservationDetail.value = null;
}

function backNew() {
  showConfirm.value = false;
  if (props.customer) {
    cancel();
  }
  emit("resetError");
}

function submit() {
  const crd = confirmedReservationDetail.value;
  const reservationDetail = {
    reservation_type: crd.reservation_type,
    reservation_at: crd.reservation_at,
    customer_id: crd.customer_id,
  };
  const contractCources = crd.contract_cources;
  const customer = crd.customer;

  emit("submit", { reservationDetail, customer, contractCources });
}
</script>

<style lang="scss" scoped>
.top {
  padding-top: 50px;

  @include mq(pc) {
    padding-top: 100px;
  }
}
.next {
  margin-top: 46px;
  margin-bottom: 20px;

  @include mq(pc) {
    margin-top: 100px;
    margin-bottom: 100px;
  }
}

.next-btn {
  width: 100px;

  @include mq(pc) {
    width: 200px;
    height: 50px;
  }
}
</style>
