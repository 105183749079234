<template>
  <div
    class="pendant-wrapper"
    @mouseover="onLight = true"
    @mouseleave="onLight = false"
  >
    <PendantLeftOnImage v-if="onLight" class="pendant-image" />
    <PendantLeftOffImage v-else class="pendant-image" />

    <span class="pendant-text">お手入れのご予約</span>
  </div>
</template>

<script setup>
import { ref } from "vue";
import {
  PendantLeftOnImage,
  PendantLeftOffImage,
} from "/@/vue/Components/Images";

const onLight = ref(false);
</script>

<style lang="scss" scoped>
@import "./pendant.scss";
</style>
