<template>
  <div class="card card-body content-card shop-event-card">
    <Collapse :contents-id="`shop-event-${id}`">
      <template #header>
        <div class="d-flex justify-content-start">
          <label v-if="at" class="time-label me-3">
            {{ at.from }}~{{ at.to }}
          </label>
          <span>{{ title }}</span>
        </div>
      </template>
      <template #contents>
        <div class="list-group list-group-flush pt-3">
          <TableRow title="詳細">
            <span>{{ details }}</span>
          </TableRow>
        </div>
        <div class="actions">
          <div class="d-flex justify-content-around">
            <div class="p-2 w-50">
              <BasicButton @click="$emit('edit')"> 編集 </BasicButton>
            </div>
            <div class="p-2 w-50">
              <BasicButton @click="$emit('cancel')"> キャンセル </BasicButton>
            </div>
          </div>
        </div>
      </template>
    </Collapse>
  </div>
</template>

<script setup>
import { computed } from "@vue/reactivity";
import { getTime } from "/@/modules/luxon";
import { Collapse } from "/@/vue/Components/UI";
import { TableRow, BasicButton } from "/@/vue/Components/Common";

const props = defineProps({
  id: {
    type: Number,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  start_at: {
    type: String,
    required: true,
  },
  finish_at: {
    type: String,
    required: true,
  },
  details: {
    type: String,
    default: null,
  },
});

defineEmits(["edit", "cancel"]);

const at = computed(() => {
  const startAt = props.start_at;
  const finishAt = props.finish_at;
  return {
    from: getTime(startAt),
    to: getTime(finishAt),
  };
});
</script>

<style lang="scss" scoped></style>
