<template>
  <div class="customers-root">
    <router-view></router-view>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
.customers-root {
  width: 100%;
}
</style>
