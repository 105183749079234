<template>
  <div class="contents-wrapper">
    <div class="contents-area index-card">
      <VerticalTitle title="about" direction="left" />
      <div class="about-area">
        <div :class="`${isMobile ? 'w-100' : 'w-50'}`">
          <AboutImage class="about-image" loading="lazy" />
        </div>
        <div
          :class="`d-flex flex-column align-items-center ${
            isMobile ? '' : 'w-50'
          }`"
        >
          <div class="text-area">
            <div class="w-100">
              <div>ご存知ですか？</div>

              <div class="marker-animation">
                全職種と福祉職の平均年収を比べると福祉職は月約 8
                万円、年収にして約100万円も低いんです。
              </div>

              <div>勤務時間も不規則、自分の美容に時間やお金をかけにくい。</div>
              <div>きっとそれは学生さんたちも一緒。</div>
            </div>
            <div class="w-100 mt-4 w-100">
              <div class="marker-animation">
                私たちはそんなあなたを応援します。
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useMqUtils } from "/@/vue/Composables";
import { AboutImage } from "/@/vue/Components/Images";
import { VerticalTitle } from "/@/vue/Components/Common";

const { isMobile } = useMqUtils();
</script>

<style lang="scss" scoped>
.about-area {
  display: flex;
  flex-direction: column;
  min-height: 330px;
  padding: 0 30px;

  @include mq(pc) {
    flex-direction: row;
    min-height: 360px;
    padding: 50px 0;
  }
}

.about-image {
  margin-top: -90px;
  width: 100%;

  @include mq(pc) {
    margin-top: 0;
  }
}

.text-area {
  padding-top: 30px;
  padding-bottom: 30px;

  @include mq(pc) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 24px;
    height: 100%;
  }
}
</style>
