import useSWRV from "swrv";
import { computed } from "vue";
import { useStore } from "vuex";
import { fetcher } from "/@/modules/axios";

export function useCources() {
  const store = useStore();

  const cources = computed(() => {
    return store.state.cources.cources;
  });

  function getCources() {
    return useSWRV("/api/v1/cources", fetcher);
  }

  function getPartsCources(cources) {
    if (!cources) {
      return [];
    }

    return cources.filter((c) => c.cource_type == courceTypes.PARTS);
  }

  const courceTypes = {
    ALL: "all",
    PARTS: "parts",
    EXPLAIN: "explain",
  };

  return {
    cources,
    courceTypes,
    getCources,
    getPartsCources,
  };
}
