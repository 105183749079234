import { computed } from "vue";
import { useStore } from "vuex";
import {
  createCustomerObj,
  createCustomerObjForShow,
} from "../../modules/customer";
import useSWRV from "swrv";
import { axios, fetcher } from "/@/modules/axios";
import { errorHandle } from "/@/modules/error";
import * as DB from "/@/vue/store/db";
import { useRouter } from "vue-router";

export function useCustomer() {
  const store = useStore();

  // current customer

  function getCurrentCustomer() {
    return useSWRV("/api/v1/customers/current", fetcher);
  }

  // state

  const customers = computed(() => {
    return store.state.customers.customers;
  });

  function getCustomers() {
    return useSWRV("/api/v1/customers", fetcher);
  }

  function getCustomer(id) {
    return useSWRV(() => id && "/api/v1/customers/" + id, fetcher);
  }

  async function updateCustomer(customer) {
    try {
      await axios.patch(`/api/v1/customers/${customer.id}`, {
        customers: customer,
      });
    } catch (e) {
      errorHandle(e);
    }

    return;
  }

  async function updateCurrentCustomer(customer) {
    try {
      await axios.post("/api/v1/customers/update_current", {
        customers: customer,
      });
    } catch (e) {
      errorHandle(e);
    }
  }

  const akasatana = "アカサタナハマヤラワ";
  const akasatanaArray = [...akasatana];
  const akasatanaMap = {
    ア: [..."アイウエオ"],
    カ: [..."カキクケコ"],
    サ: [..."サシスセソ"],
    タ: [..."タチツテト"],
    ナ: [..."ナニヌネノ"],
    ハ: [..."ハヒフヘホ"],
    マ: [..."マミムメモ"],
    ヤ: [..."ヤユヨ"],
    ラ: [..."ラリルレロ"],
    ワ: [..."ワヲン"],
  };

  function createAkasatanaBitHeatMap(customers) {
    let m = {};

    customers.forEach((c) => {
      akasatanaArray.forEach((k) => {
        if (akasatanaMap[k].includes(c.name_last_kana[0])) {
          m[k] = true;
          return;
        }
      });
    });

    return m;
  }

  function createAkasatanaCustomers(customers) {
    if (!customers) {
      return {};
    }

    let m = {};

    customers.forEach((c) => {
      const firstNameChar = c.name_last_kana[0];
      if (!m[firstNameChar]) {
        m[firstNameChar] = [];
      }
      m[firstNameChar].push(c);
    });

    return m;
  }

  // filter

  function isExplain({ cource_type }) {
    return cource_type == customerTypes.EXPLAIN;
  }

  function onlyHaveExplain(customer) {
    return customer.contract_cources.every((cc) => isExplain(cc.cource));
  }

  function haventPaidYet(customer) {
    return customer.contract_cources.some((cc) => !cc.payment_at);
  }

  function matchType(customer, type) {
    // TODO: サーバーサイドでやるべき
    const isUnconfirmedCustomer = !customer.is_confirmed;
    const isExplainCustomer = onlyHaveExplain(customer);
    const isPaidDoneCustomer = !haventPaidYet(customer);

    switch (type) {
      case customerTypes.BEFORE_CONFIRMATION:
        // メール認証がまだ
        return isUnconfirmedCustomer;
      case customerTypes.EXPLAIN:
        // 説明予約のみのユーザー
        return !isUnconfirmedCustomer && isExplainCustomer;
      case customerTypes.NOT_PAYMENT:
        // 未払いのコースあり
        return (
          !isUnconfirmedCustomer && !isExplainCustomer && !isPaidDoneCustomer
        );
      case customerTypes.PAID:
        // なし
        return (
          !isUnconfirmedCustomer && !isExplainCustomer && isPaidDoneCustomer
        );
      case customerTypes.ALL:
        return true;

      default:
        return false;
    }
  }

  function matchWord(customer, word) {
    if (!word) {
      return true;
    }

    const values = Object.values(customer);
    const reg = new RegExp(word);

    return values.join().match(reg);
  }

  function getFilteredCustomers(customers, type, word) {
    return customers.filter((c) => {
      return matchType(c, type) && matchWord(c, word);
    });
  }

  const customerTypes = {
    ALL: "all",
    EXPLAIN: "explain",
    BEFORE_CONFIRMATION: "beforeConfirmation",
    NOT_PAYMENT: "notPayment",
    PAID: "paid",
  };

  function test() {
    const { it, expect } = import.meta.vitest;

    const customer1 = {
      ...DB.customers[1],
      contract_cources: [DB.contract_cources[1]],
    };
    const customer2 = {
      ...DB.customers[2],
      contract_cources: [DB.contract_cources[2]],
    };
    const customer3 = {
      ...DB.customers[3],
      contract_cources: [DB.contract_cources[3]],
    };

    it("haveExplain", () => {
      expect(onlyHaveExplain(customer1)).toBe(false);
      expect(onlyHaveExplain(customer3)).toBe(true);
    });

    it("haventPaidYet", () => {
      expect(haventPaidYet(customer1)).toBe(true);
      expect(haventPaidYet(customer2)).toBe(false);
    });

    it("matchWord", () => {
      expect(matchWord(customer1, "佐藤")).toBe(null);
      expect(matchWord(customer1, "深田")).toBeTruthy();
      expect(matchWord(customer2, "f.takenaka@fryth.co.jp")).toBeTruthy();
    });
  }

  // session

  const router = useRouter();

  async function logout() {
    try {
      await axios.post("/api/v1/customers/log_out");
      router.go({ name: "Index", force: true });
    } catch (e) {
      errorHandle(e);
    }
  }

  async function submitReconfirmation({ email }) {
    try {
      await axios.post("/api/v1/customers/reconfirmation", {
        customers: { email },
      });

      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  return {
    akasatanaArray,
    akasatanaMap,

    createCustomerObj,
    createCustomerObjForShow,
    customers,
    getCustomer,

    createAkasatanaBitHeatMap,
    createAkasatanaCustomers,

    // admin
    getCustomers,

    // cred
    updateCustomer,
    updateCurrentCustomer,

    // session
    getCurrentCustomer,
    logout,
    submitReconfirmation,

    // filter
    customerTypes,
    matchType,
    matchWord,
    getFilteredCustomers,

    // test
    test,
  };
}

if (import.meta.vitest) {
  const { test } = useCustomer();

  test();
}
