<template>
  <div v-if="!showConfirm" class="d-flex flex-column align-items-center w-100">
    <label class="content-label">コース選択</label>
    <span class="small-font">※料金はご来店時にお支払いいただきます</span>
    <div class="mt-3">
      <CourcesSelector
        :cources="cources"
        :selected-cources="selectedCources"
        @update:selected-cources="$emit('update:selectedCources', $event)"
      />
      <div class="mt-5 w-100 flex-row-center">
        <BasicButton :disabled="disabled" @click="showConfirm = true">
          次へ
        </BasicButton>
      </div>
    </div>
  </div>
  <div v-else class="d-flex flex-column align-items-center w-100">
    <label v-if="!afterSubmit" class="content-label">内容の確認</label>
    <label v-else class="content-label">コースの追加が完了しました</label>
    <div class="list-group list-group-flush mt-3">
      <CustomerShow v-bind="customer" />
      <CourcesShow :cources="selectedCources" />
    </div>
    <div v-if="!afterSubmit" class="d-flex align-items-center mt-5">
      <div class="p-2">
        <BasicButton @click="showConfirm = false">戻る</BasicButton>
      </div>
      <div class="p-2">
        <BasicButton @click="submit">追加を確定する</BasicButton>
      </div>
    </div>
    <div v-else class="flex-row-center mt-5">
      <router-link v-if="!isAdmin" :to="{ name: 'CustomersIndex' }">
        <BasicButton>マイページへ戻る</BasicButton>
      </router-link>
      <router-link
        v-else
        :to="{ name: 'AdminCustomersShow', params: { id: customer?.id } }"
      >
        <BasicButton>顧客ページへ戻る</BasicButton>
      </router-link>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { Show as CustomerShow } from "/@/vue/Components/Customer";
import {
  Show as CourcesShow,
  CourcesSelector,
} from "/@/vue/Components/Cources";
import { BasicButton } from "/@/vue/Components/Common";

const props = defineProps({
  cources: {
    type: Array,
    default: () => [],
  },
  selectedCources: {
    type: Array,
    default: null,
  },
  customer: {
    type: Object,
    default: () => {},
  },
  afterSubmit: {
    type: Boolean,
  },
  isAdmin: {
    type: Boolean,
  },
});

const emit = defineEmits(["update:selectedCources", "submit"]);

const disabled = computed(() => {
  return !props.selectedCources?.length;
});

const showConfirm = ref(false);

function submit() {
  showConfirm.value = false;
  emit("submit");
}

function reset() {
  showConfirm.value = false;
}

defineExpose({
  reset,
});
</script>

<style lang="scss" scoped></style>
