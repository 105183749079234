<template>
  <div class="padding-for-header"></div>
  <WeekSelector
    v-if="!showConfirm"
    v-model:dates="selectedDates"
    :now="now"
    :is-admin="isAdmin"
  />
  <div v-if="!showConfirm" class="d-flex flex-column align-items-center">
    <CurrentReservations
      v-model:selected-date-time="selectedDateTime"
      :reservations="targetDateReservations"
      :holidays="holidays"
      :is-admin="isAdmin"
      :now="now"
    />
    <div class="next pb-3">
      <div v-if="selectedDateTime" class="next-btn">
        <BasicButton @click="confirm">次へ</BasicButton>
      </div>
    </div>
  </div>
  <div v-else>
    <Confirm
      :reservation="confirmedReservationDetail"
      :before_reservation_at="reservationDetail.reservation_at"
      :after-submit="afterSubmit"
      :is-admin="isAdmin"
      :is-pc-admin="isPcAdmin"
      :loading="loading"
      @back="cancel"
      @submit="submit"
      @to-admin-reservations="callEmit('toAdminReservations')"
      @to-customers-index="callEmit('toCustomersIndex')"
    />
  </div>
</template>

<script setup>
import { ref, onMounted, watch, nextTick } from "vue";
import { WeekSelector } from "/@/vue/Components/Common/Selector";
import { BasicButton } from "/@/vue/Components/Common";
import { useReservations } from "/@/vue/Composables";
import { CurrentReservations, Confirm } from ".";
import { luxonNow } from "/@/modules/luxon";
import { scrollToTop } from "/@/modules/ui";

const props = defineProps({
  reservationDetail: {
    type: Object,
    default: () => {},
  },
  allReservationDetails: {
    type: Array,
    default: () => [],
  },
  allShopEvents: {
    type: Array,
    default: () => [],
  },
  holidays: {
    type: Object,
    default: () => {},
  },
  isAdmin: {
    type: Boolean,
  },
  isPcAdmin: {
    type: Boolean,
  },
  afterSubmit: {
    type: Boolean,
  },
  loading: {
    type: Boolean,
  },
  now: {
    type: Object,
    default: () => luxonNow,
  },
});

const emit = defineEmits([
  "submit",
  "resetError",
  "toAdminReservations",
  "toCustomersIndex",
]);

const {
  selectedDates,
  setHolidays,
  setReservationDetails,
  setShopEvents,
  targetDateReservations,
} = useReservations();

const selectedDateTime = ref();

onMounted(() => {
  selectedDates.value = [
    props.now.plus({ day: 1 }).startOf("week").minus({ day: 1 }),
    props.now.plus({ day: 1 }).endOf("week").minus({ day: 1 }),
  ];
  setHolidays(props.holidays);

  if (selectedDates.value[1].hasSame(props.now, "day")) {
    selectedDates.value = selectedDates.value.map((d) => d.plus({ week: 1 }));
  }

  setReservationDetails(props.allReservationDetails);
  setShopEvents(props.allShopEvents);
});

watch(
  () => props.holidays,
  (hs) => {
    setHolidays(hs);
  }
);

watch(
  () => props.allReservationDetails,
  (ards) => {
    setReservationDetails(ards);
  }
);

watch(
  () => props.allShopEvents,
  (ases) => {
    setShopEvents(ases);
  }
);

const showConfirm = ref(false);
const confirmedReservationDetail = ref();

async function confirm() {
  confirmedReservationDetail.value = {
    ...props.reservationDetail,
    reservation_at: selectedDateTime.value,
    contract_cources: props.reservationDetail.contract_cources,
  };
  showConfirm.value = true;

  await nextTick();

  scrollToTop();
}

function cancel() {
  showConfirm.value = false;
  selectedDateTime.value = null;
  confirmedReservationDetail.value = {};
  emit("resetError");
}

function submit() {
  emit("submit", confirmedReservationDetail.value);
}

function callEmit(to) {
  showConfirm.value = false;
  emit(to);
}
</script>

<style lang="scss" scoped>
.next {
  margin-top: 46px;
  margin-bottom: 20px;
}

.next-btn {
  width: auto;
}
</style>
