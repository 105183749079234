<template>
  <div class="list-group list-group-flush customer-list">
    <template v-for="k in akasatanaArray" :key="k">
      <template v-if="akasatanaBitHeatMap[k]">
        <TableTitle :title="k" />
        <template v-for="kk in akasatanaMap[k]" :key="`${k}-${kk}`">
          <template v-if="akasatanaCustomers[kk]?.length">
            <TableRow
              v-for="c in akasatanaCustomers[kk]"
              :key="c.id"
              :title="`${c.name_last} ${c.name_first}${
                !c.is_confirmed ? ' (認証前)' : ''
              }`"
              role-button
              no-fixed-width
              @click="selectCustomer(c.id)"
            ></TableRow>
          </template>
        </template>
      </template>
    </template>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
import { computed } from "@vue/reactivity";
import { useCustomer } from "/@/vue/Composables/customer";
import { TableRow, TableTitle } from "/@/vue/Components/Common";

const router = useRouter();

const props = defineProps({
  customers: {
    type: Object,
    default: () => Object,
  },
  roleButton: {
    type: Boolean,
  },
});

const emit = defineEmits(["selectCustomer"]);

const {
  akasatanaArray,
  akasatanaMap,
  createAkasatanaBitHeatMap,
  createAkasatanaCustomers,
} = useCustomer();

const akasatanaBitHeatMap = computed(() => {
  return createAkasatanaBitHeatMap(props.customers);
});

const akasatanaCustomers = computed(() => {
  return createAkasatanaCustomers(props.customers);
});

function selectCustomer(customerId) {
  if (props.roleButton) {
    emit("selectCustomer", customerId);
  } else {
    router.push({
      name: "AdminCustomersShow",
      params: { id: customerId },
    });
  }
}
</script>

<style lang="scss" scoped>
.customer-list {
  font-size: 16px;
}
</style>
