import { useCources } from "./cources";
import { axios } from "/@/modules/axios";
import { errorHandle } from "/@/modules/error";

export function useContractCources() {
  const { courceTypes } = useCources();

  function isValidContractCource(cc) {
    return !cc.is_done && cc.cource.cource_type != courceTypes.EXPLAIN;
  }

  function contractCourceToStr(cc) {
    return cc.cource.cource_type == courceTypes.PARTS
      ? "パーツコース " + cc.cource.title
      : cc.cource.title;
  }

  function contractCourcesToStr(ccs) {
    if (!ccs || !ccs.length) {
      return "";
    }

    return ccs.map((cc) => contractCourceToStr(cc)).join();
  }

  function constructContractCources(cources, customer) {
    return cources.map((c) => ({ cource_id: c.id, customer_id: customer?.id }));
  }

  async function createContractCources({ cources, customer }) {
    const params = constructContractCources(cources, customer);

    try {
      await axios.post("/api/v1/contract_cources/bulk_add", {
        contract_cources: {
          bulk_add_params: params,
        },
      });

      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  async function updateContractCource(contractCource) {
    const { id } = contractCource;

    try {
      await axios.patch(`/api/v1/contract_cources/${id}`, {
        contract_cources: contractCource,
      });

      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  return {
    // predicate
    isValidContractCource,

    // format
    contractCourceToStr,
    contractCourcesToStr,

    // class
    constructContractCources,

    // api
    createContractCources,
    updateContractCource,
  };
}
