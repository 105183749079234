import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { axios } from "/@/modules/axios";
import { errorHandle } from "/@/modules/error";

export function useAuthorize() {
  const store = useStore();
  const router = useRouter();

  async function logout() {
    await store.dispatch("user/LOGOUT");
    router.go({ name: "Index", force: true });
  }

  async function signUp(inputForm, { creating, message, confirmCase }) {
    confirmCase.value = false;
    creating.value = true;

    try {
      const result = await store.dispatch("user/SIGNUP", inputForm);

      if (confirmCase.value) {
        return;
      }

      if (result?.data?.state) {
        router.push({ name: "Index" });
      } else {
        creating.value = false;
        message.value = "アカウント作成に失敗しました。";
      }
    } catch (e) {
      if (e.response.status == 401) {
        creating.value = false;
        message.value =
          "アカウントを作成し認証用のメールを送信しました。15分以内にメールを認証して下さい。";
        confirmCase = true;
        return;
      }
      message.value = "アカウント作成に失敗しました。";
    }
  }

  async function customerSignUp(customer) {
    try {
      const { data } = await axios.post("/api/v1/customers/sign_up", {
        customers: customer,
      });
      return { data };
    } catch (e) {
      return { errors: errorHandle(e) };
    }
  }

  return {
    logout,
    signUp,

    customerSignUp,
  };
}
