<template>
  <div class="form-check">
    <input
      :id="formId"
      :checked="modelValue == value"
      type="radio"
      :value="value"
      class="form-check-input"
      :name="name"
      @change="onChange"
    />
    <label class="form-check-label" :for="formId">{{ label }}</label>
  </div>
</template>

<script setup>
defineProps({
  modelValue: {
    type: String,
    default: null,
  },
  value: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  formId: {
    type: String,
    required: true,
  },
  name: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(["update:modelValue"]);

function onChange(event) {
  emit("update:modelValue", event.target.value);
}
</script>

<style lang="scss" scoped></style>
