<template>
  <div id="session-sign-in" class="w-100">
    <div class="contents-wrapper">
      <div class="contents-area flex-column-center">
        <div class="message">
          {{ finalMessage }}
        </div>
        <div class="form-group login-form-group w-100">
          <label>メールアドレス</label>
          <input v-model="email" class="form-control" type="email" />
          <label>パスワード</label>
          <input v-model="password" class="form-control" type="password" />
        </div>

        <div class="mt-3">
          <BasicButton :disabled="disabled" @click="login">
            ログイン
          </BasicButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { useRouter } from "vue-router";
import { axios } from "/@/modules/axios";
import { BasicButton } from "/@/vue/Components/Common";

const router = useRouter();

const finalMessage = ref("");
const email = ref();
const password = ref();

const disabled = computed(() => {
  return ![email.value, password.value].every((x) => x);
});

async function login() {
  const result = await axios
    .post("/api/v1/login", {
      user: {
        email: email.value,
        password: password.value,
      },
    })
    .catch((e) => console.error(e));

  if (!result || !result.data) {
    finalMessage.value = "エラーが発生しました。";
    return;
  }

  if (result.data.state) {
    finalMessage.value = "ログイン成功";

    router.push({ name: "AdminIndex" });
  } else {
    finalMessage.value = "パスワードが間違っています。";
    return;
  }
}
</script>

<style lang="scss" scope>
#session-sign-in {
  margin-top: 100px;
}
</style>
