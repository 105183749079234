<template>
  <button v-if="!editMode" class="btn edit-btn" @click="startToggle">
    <PenIcon class="icon" />
  </button>
  <button v-else class="btn edit-btn" @click="finishToggle">
    <CheckIcon class="icon" />
  </button>
</template>

<script setup>
import { ref } from "vue";
import { PenIcon, CheckIcon } from "../../Icons";

const editMode = ref(false);

const emit = defineEmits(["start", "finish"]);

function startToggle() {
  editMode.value = true;
  emit("start");
}

function finishToggle() {
  editMode.value = false;
  emit("finish");
}
</script>

<style lang="scss" scoped>
.edit-btn {
  height: 28px;
  width: 28px;
  padding: 0;

  .icon {
    height: 20px;
    width: 20px;
  }
}
</style>
