<template>
  <button class="btn btn-dark">説明(無料)のご予約はこちら</button>
</template>

<script setup></script>

<style lang="scss" scoped>
.btn {
  color: #cccccc;
  width: 230px;
  font-size: 12px;
  background-color: #201d16;
  border-radius: 2px;

  @include mq(pc) {
    width: 400px;
    font-size: $pc-middle-font-size;
    padding: 11px 11px;
    border-radius: 4px;
  }
}
</style>
