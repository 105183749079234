<template>
  <div v-for="e in events" :key="`event-${e.id}-${e.contentType}`">
    <div class="mt-3">
      <ReservationCard
        v-if="e.contentType == eventTypes.RESERVATION_DETAIL"
        :reservation_detail="e"
        @edit="$emit('toAdminReservationsEditPage', $event)"
        @to-customer-page="$emit('toCustomerPage', $event)"
        @cancel="$emit('cancel', e)"
        @finish="$emit('finish', e)"
      />
      <ShopEventCard
        v-else-if="e.contentType == eventTypes.SHOP_EVENT"
        v-bind="
          (({ id, title, start_at, finish_at, details }) => ({
            id,
            title,
            start_at,
            finish_at,
            details,
          }))(e)
        "
        @edit="$emit('toAdminShopEventsNew', e.id)"
        @cancel="$emit('cancel', e)"
      ></ShopEventCard>
    </div>
  </div>
</template>

<script setup>
import { useEvents } from "../../Composables";
import { Card as ReservationCard } from "/@/vue/Components/Reservation";
import { Card as ShopEventCard } from "/@/vue/Components/ShopEvents";

defineProps({
  events: {
    type: Array,
    default: () => [],
  },
});

defineEmits([
  "toAdminReservationsEditPage",
  "toCustomerPage",
  "toAdminShopEventsNew",
  "cancel",
  "finish",
]);

const { eventTypes } = useEvents();
</script>

<style lang="scss" scoped></style>
