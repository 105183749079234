import useSWRV from "swrv";
import { axios, fetcher } from "/@/modules/axios";
import { errorHandle } from "/@/modules/error";

export function useReservationDetails() {
  function constructCareReservationDetail(customer, contractCources) {
    const {
      id,
      name_first,
      name_last,
      name_first_kana,
      name_last_kana,
      phone_number,
      email,
    } = customer;

    return {
      customer: {
        id,
        name_first,
        name_last,
        name_first_kana,
        name_last_kana,
        phone_number,
        email,
      },
      done: false,
      reservation_type: "care",
      contract_cources: contractCources?.map((cc) => cc.id),
    };
  }

  function typeToJP(type) {
    switch (type) {
      case "care":
        return "お手入れ";
      case "explain":
        return "説明・相談";
      default:
        throw Error("no match typeToJP", type);
    }
  }

  function getReservationDetails(option = { forReserve: false }) {
    const { forReserve } = option;

    let path = "/api/v1/reservation_details";
    if (forReserve) {
      path += "?for_reserve=true";
    }

    return useSWRV(path, fetcher);
  }

  function getReservationDetail(id) {
    return useSWRV(`/api/v1/reservation_details/${id}`, fetcher);
  }

  async function newReservationDetail(
    reservationDetail,
    customer,
    contractCources,
    errors = undefined
  ) {
    try {
      await axios.post("/api/v1/reservation_details", {
        reservation_details: reservationDetail,
        customer: customer,
        contract_cources: { target_cources: contractCources },
      });

      return true;
    } catch (e) {
      const es = errorHandle(e);

      if (errors) {
        console.log("es", es);
        errors.value = es;
      }
      return false;
    }
  }

  async function updateReservationDetail({ id, ...params }) {
    try {
      await axios.patch(`/api/v1/reservation_details/${id}`, {
        reservation_details: params,
      });
      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  async function removeReservationDetail(id) {
    try {
      await axios.delete(`/api/v1/reservation_details/${id}`);
      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  return {
    typeToJP,
    constructCareReservationDetail,

    // api
    getReservationDetails,
    getReservationDetail,
    newReservationDetail,
    updateReservationDetail,
    removeReservationDetail,
  };
}
