export default ($axios) => ({
  namespaced: true,
  state: {
    news: [
      {
        id: 2,
        title: "年末年始の休業について",
        created_at: "2022-12-20T09:00:00+09:00",
      },
      {
        id: 1,
        title: "OPENしました！",
        created_at: "2022-12-01T00:00:00+09:00",
      },
    ],
  },

  mutations: {
    SET_NEWS(state, news) {
      state.news = news;
    },
  },

  actions: {
    SET_NEWS({ commit }, news) {
      commit("SET_NEWS", news);
    },

    async GET_NEWS({ dispatch }) {
      const result = await $axios.get("/api/v1/news").catch((e) => {
        console.error(e);
      });

      if (!result || !result.data) {
        return false;
      }

      const news = result.data;
      dispatch("SET_NEWS", news);

      return news;
    },
  },
});
