<template>
  <div>
    <div class="form-line multi-form">
      <label for="name-last" class="d-flex justify-content-between">
        <span>お名前（漢字）</span>
        <span>姓</span>
      </label>
      <div class="input-wrapper w-100">
        <InputForm
          :value="name_last"
          form-id="name-last"
          :is-valid="validater.name_last"
          autocomplete="off"
          @update:value="$emit('update:name_last', $event)"
        />
      </div>

      <label for="name-first" class="second text-end">名</label>
      <div class="input-wrapper">
        <InputForm
          :value="name_first"
          form-id="name-first"
          :is-valid="validater.name_first"
          autocomplete="off"
          @update:value="$emit('update:name_first', $event)"
        />
      </div>
    </div>
    <div class="form-line multi-form">
      <label for="name-last-kana" class="d-flex justify-content-between">
        <span>お名前（カナ）</span>
        <span>セイ</span>
      </label>
      <div class="input-wrapper w-100">
        <InputForm
          :value="name_last_kana"
          :is-valid="validater.name_last_kana"
          form-id="name-last-kana"
          autocomplete="off"
          @update:value="$emit('update:name_last_kana', $event)"
        />
      </div>

      <label for="name-first-kana" class="second text-end">メイ</label>
      <div class="input-wrapper">
        <InputForm
          :value="name_first_kana"
          form-id="name-first-kana"
          :is-valid="validater.name_first_kana"
          autocomplete="off"
          @update:value="$emit('update:name_first_kana', $event)"
        />
      </div>
    </div>
    <div class="form-line">
      <label for="phone-number">ご連絡先電話番号</label>
      <div class="input-wrapper">
        <InputForm
          :value="phone_number"
          form-id="phone-number"
          :is-valid="validater.phone_number"
          autocomplete="tel"
          @update:value="$emit('update:phone_number', $event)"
        />
      </div>
    </div>
    <div class="form-line">
      <label for="email">メールアドレス</label>
      <div class="input-wrapper">
        <EmailForm
          ref="emailForm"
          :email="email"
          with-error-message
          @update:email="$emit('update:email', $event)"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from "@vue/reactivity";
import { ref } from "vue";
import { EmailForm, InputForm } from "/@/vue/Components/Common";
import { validaterCheck } from "/@/modules/validation";

const props = defineProps({
  name_first: {
    type: String,
    default: null,
  },
  name_last: {
    type: String,
    default: null,
  },
  name_first_kana: {
    type: String,
    default: null,
  },
  name_last_kana: {
    type: String,
    default: null,
  },
  phone_number: {
    type: String,
    default: null,
  },
  email: {
    type: String,
    default: null,
  },
});

defineEmits([
  "update:name_first",
  "update:name_last",
  "update:name_first_kana",
  "update:name_last_kana",
  "update:phone_number",
  "update:email",
]);

const emailForm = ref();

const validater = computed(() => {
  return {
    name_first: !!props.name_first,
    name_last: !!props.name_last,
    name_first_kana: !!props.name_first_kana,
    name_last_kana: !!props.name_last_kana,
    phone_number: !!props.phone_number && props.phone_number.length >= 11,
    email: emailForm.value?.isValid,
  };
});

const isValid = computed(() => validaterCheck(validater.value));

defineExpose({
  isValid,
});
</script>

<style lang="scss" scoped>
@import "./customer_form.scss";
</style>
