<template>
  <div class="flex-column-center">
    <TimeList
      :times="times"
      :exists-map="existsMap"
      :selected-times="selectedTimes"
      @select-time="$emit('update:selectedTime', $event)"
    />
    <ColorNotification />
  </div>
</template>

<script setup>
import { computed } from "@vue/reactivity";
import ColorNotification from "./ColorNotification.vue";
import TimeList from "./TimeList.vue";

const props = defineProps({
  times: {
    type: Array,
    default: () => [],
  },
  existsMap: {
    type: Object,
    default: () => {},
  },
  selectedTime: {
    type: String,
    default: null,
  },
});

defineEmits(["update:selectedTime"]);

const selectedTimes = computed(() => {
  return props.selectedTime ? [props.selectedTime] : [];
});
</script>

<style lang="scss" scoped></style>
