<template>
  <div class="d-flex mt-3 align-items-center">
    <div class="time-card possible"></div>
    <span class="ms-2">予約可能</span>
    <div class="time-card impossible"></div>
    <span class="ms-2">予約不可</span>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
@import "./timecard.scss";

.time-card {
  max-height: 25px;
  max-width: 100px;
}

.impossible {
  margin-left: 1rem;

  @include mq(pc) {
    margin-left: 30px;
  }
}
</style>
