<template>
  <div class="list-group list-group-flush">
    <TableRow title="TEL" slim without-x-padding>
      <div class="d-flex flex-column pb-3">
        <TelButton />
        <div class="small-font d-flex flex-column">
          <span>※来店のご予約・キャンセルは当サイトにて受付しております。</span>
          <span>ご予約の変更・キャンセルは、</span>
          <span> 会員登録がお済みの方はログイン後マイページで、 </span>
          <span> 会員登録がお済みでない方は上記にご連絡ください。 </span>
        </div>
      </div>
    </TableRow>
    <TableRow title="所在地" slim without-x-padding>
      <a :href="salonGmap" class="d-flex flex-column pb-3">
        <span>石川県金沢市西念2丁目35-23 奥伸ビル102</span>
        <span>金沢駅から徒歩16分</span>
        <span>店舗前駐車場完備</span>
      </a>
    </TableRow>
    <TableRow title="営業時間" slim without-x-padding>
      <table class="table table-borderless pb-3">
        <tr>
          <td class="head">月~金</td>
          <td>18:00~20:00(last19:30)</td>
        </tr>
        <tr>
          <td class="head">土・日・祝</td>
          <td>10:00~16:00(last15:30)</td>
        </tr>
      </table>
    </TableRow>
    <TableRow title="定休日" slim without-x-padding>
      <div class="d-flex flex-column pb-3">
        <span>なし</span>
        <span class="small-font"
          >※臨時の休業に関しては当サイトお知らせ欄にて随時更新いたします</span
        >
      </div>
    </TableRow>
  </div>
</template>

<script setup>
import { TableRow, TelButton } from "/@/vue/Components/Common";

const salonGmap =
  "https://www.google.com/maps/place/%E6%97%A5%E6%9C%AC%E3%80%81%E3%80%92921-0024+%E7%9F%B3%E5%B7%9D%E7%9C%8C%E9%87%91%E6%B2%A2%E5%B8%82%E8%A5%BF%E5%BF%B5%EF%BC%92%E4%B8%81%E7%9B%AE%EF%BC%93%EF%BC%95%E2%88%92%EF%BC%92%EF%BC%93+%E5%A5%A5%E4%BC%B8%E3%83%93%E3%83%AB+102+%E3%82%B1%E3%82%A2%E3%83%9E%E3%83%8D%E3%82%B9%E3%83%86%E3%83%BC%E3%82%B7%E3%83%A7%E3%83%B3%E9%87%91%E6%B2%A2/@36.5863494,136.6398137,17z/data=!4m6!3m5!1s0x5ff833a7eb71076b:0xfe190e24c16a83!8m2!3d36.5863494!4d136.6398137!16s%2Fg%2F11nngspmm0?hl=ja-JP&gl=US";
</script>

<style lang="scss" scoped>
.table {
  .head {
    width: 80px;

    @include mq(pc) {
      width: 100px;
    }
  }
}
</style>
