<template>
  <div class="admin-card">
    <AdminCardHeader title="店内予定の作成" jp />
    <NewProcess
      :all-reservation-details="reservationDetails"
      :all-shop-events="shopEvents"
      :holidays="holidays"
      :shop-event="shopEvent"
      :after-submit="afterSubmit"
      @submit="submit"
      @to-top="goto({ name: 'AdminReservations' })"
    />
  </div>
</template>

<script setup>
import { ref, watch } from "vue";
import { computed } from "@vue/reactivity";
import { useShopEvents, useRouterUtil } from "/@/vue/Composables";
import { NewProcess } from "/@/vue/Components/ShopEvents";
import useSWRV from "swrv";
import { useRoute } from "vue-router";
import { fetcher } from "/@/modules/axios";
import { errorHandle } from "/@/modules/error";
import { AdminCardHeader } from "/@/vue/Components/Admin";

const route = useRoute();

const { goto } = useRouterUtil();

const shopEventId = computed(() => {
  return route.query.id;
});

const { addShopEvent, editShopEvent } = useShopEvents();

const { data: reservationDetails, mutate: getReservationDetailsMutate } =
  useSWRV("/api/v1/reservation_details?for_reserve=true", fetcher);

const { data: shopEvents, mutate: getShopEventsMutate } = useSWRV(
  "/api/v1/shop_events?for_reserve=true",
  fetcher
);
const { data: holidays } = useSWRV("/api/v1/calendar/get_holidays", fetcher);

const {
  data: shopEvent,
  error: getShopEventError,
  mutate: getShopEventMutate,
} = useSWRV(
  () => shopEventId.value && "/api/v1/shop_events/" + shopEventId.value,
  fetcher
);
watch(getShopEventError, (e) => {
  errorHandle(e);
});

// add

const errors = ref([]);
const afterSubmit = ref(false);

async function submit(shopEvent) {
  let result;

  if (shopEvent.id) {
    result = await editShopEvent(shopEvent);
  } else {
    result = await addShopEvent(shopEvent);
  }

  if (result) {
    afterSubmit.value = true;
  } else {
    errors.value = [
      "イベントの作成に失敗しました。",
      "すでに指定した時間に店舗イベントが存在します。",
    ];
    getShopEventMutate();
    getReservationDetailsMutate();
    getShopEventsMutate();
  }
}
</script>

<style lang="scss" scoped>
.btn-container {
  display: flex;
  min-width: 100px;

  @include mq(pc) {
    min-width: 200px;
  }
}

.form-wrapper {
  width: 300px;

  @include mq(pc) {
    width: 650px;
    padding-top: 3rem;
  }

  &.confirm {
    @include mq(pc) {
      width: 400px;
    }
  }
}
</style>
