<template>
  <div class="contents-wrapper">
    <div class="backbtn-container">
      <BackButton />
    </div>

    <div class="contents-area">
      <div class="d-flex flex-column">
        <div
          :class="`d-flex w-100 ${
            isMobile ? 'flex-column' : 'flex-row justify-content-between'
          }`"
        >
          <ShowAndEditInfo
            v-model:customer="editCustomerForm"
            @update-info="updateInfo"
          />

          <ShowNextReservationDetail
            :reservation_detail="nextReservationDetail"
            @edit="toReservationsEditPage"
            @delete="openCancelModal"
          />
        </div>

        <ShowAndEditContractCources
          :customer="customer"
          hide-edit
          @to-contract-cources-new="goto({ name: 'ContractCourcesNew' })"
        />
      </div>
    </div>
  </div>

  <teleport to="body">
    <CancelModal
      ref="cancelModal"
      @close="closeCancelModal"
      @delete="removeRD"
    />
  </teleport>
</template>

<script setup>
import { watch, ref, onMounted, computed } from "vue";
import { errorHandle } from "/@/modules/error";
import { updateWhenAnyChange } from "/@/modules/api";
import {
  ShowAndEditInfo,
  ShowAndEditContractCources,
  ShowNextReservationDetail,
} from "/@/vue/Components/Customer";
import { CancelModal } from "/@/vue/Components/UI";
import {
  useCustomer,
  useRouterUtil,
  useReservationDetails,
  useMqUtils,
} from "/@/vue/Composables";
import { BackButton } from "/@/vue/Components/Common";

const { isMobile } = useMqUtils();

// customer

const editCustomerForm = ref({});

const { getCurrentCustomer, createCustomerObjForShow, updateCurrentCustomer } =
  useCustomer();
const { data: customer, error, mutate } = getCurrentCustomer();

watch(error, (e) => {
  errorHandle(e);
});

onMounted(() => {
  editCustomerForm.value = { ...customer.value };
});

watch(customer, (c) => {
  editCustomerForm.value = { ...c };
});

const nextReservationDetail = computed(() => {
  return customer.value?.next_reservation_detail;
});

// info

const emailChange = ref(false);

async function updateInfo() {
  emailChange.value = await updateWhenAnyChange(
    createCustomerObjForShow(customer.value),
    editCustomerForm.value,
    updateCurrentCustomer
  );
  mutate();
}

// reservation

const { toReservationsEditPage, goto } = useRouterUtil();
const cancelModal = ref();

function openCancelModal() {
  cancelModal.value?.openModal();
}

function closeCancelModal() {
  cancelModal.value?.closeModal();
}

const { removeReservationDetail } = useReservationDetails();

function removeRD() {
  removeReservationDetail(nextReservationDetail.value.id);
  mutate();
  closeCancelModal();
}
</script>

<style lang="scss" scoped>
.backbtn-container {
  width: 100%;
  max-width: 1200px;
}

.contents-wrapper {
  @include mq(pc) {
    padding-top: 40px;
  }

  .contents-area {
    @include mq(pc) {
      background-color: white;
      padding: 45px;
      border-radius: 15px;
      box-shadow: 0 3px 6px #00000029;
    }
  }
}

.list-group {
  @include mq(pc) {
    width: 40%;
  }
}
</style>
