<template>
  <div class="d-flex flex-column align-items-center">
    <div class="contents-wrapper">
      <div class="contents-area index-card">
        <VerticalTitle title="access" direction="left" />
      </div>
      <div class="d-flex flex-column align-items-center">
        <div
          :class="`access-area-contents d-flex ${
            isMobile ? 'flex-column align-items-center' : 'flex-row'
          }`"
        >
          <AccessImage class="shop-image" loading="lazy" />

          <div class="info">
            <div class="tables">
              <TableArea></TableArea>
            </div>
            <div v-if="isMobile" class="map flex-column-center">
              <iframe
                width="100%"
                height="100%"
                :style="mapStyle"
                loading="lazy"
                allowfullscreen
                referrerpolicy="no-referrer-when-downgrade"
                :src="mapSrc"
              ></iframe>
            </div>
          </div>
        </div>
        <div v-if="!isMobile" class="map flex-column-center">
          <iframe
            width="100%"
            height="100%"
            :style="mapStyle"
            loading="lazy"
            allowfullscreen
            referrerpolicy="no-referrer-when-downgrade"
            :src="mapSrc"
          >
          </iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useMqUtils } from "/@/vue/Composables/mq";
import { VerticalTitle } from "/@/vue/Components/Common";
import { AccessImage } from "/@/vue/Components/Images";
import TableArea from "./TableArea.vue";

const { isMobile } = useMqUtils();

const mapStyle = {
  border: 0,
  width: "100%",
};

const mapSrc =
  "https://www.google.com/maps/embed/v1/place?q=place_id:ChIJawdx66cz-F8Rg2rBJA4Z_gA&key=AIzaSyCH6vluAJpY8PrSNHyMuqnA2TsPLEfGUos";
</script>

<style lang="scss" scoped>
.index-card {
  height: 140px;

  @include mq(pc) {
    height: 167px;
  }
}

.access-area-contents {
  @include mq(pc) {
    width: 100%;
    max-width: 880px;
  }
}

.shop-image {
  margin-top: -80px;
  width: 280px;
  height: 400px;
  z-index: 10;

  @include mq(pc) {
    margin-top: -94px;
    order: 2;
    width: 50%;
    height: 587px;
  }
}

.info {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @include mq(pc) {
    width: 50%;
    padding: 0 30px;
  }
}

.tables {
  max-width: 300px;
  padding-top: 3rem;

  @include mq(pc) {
    max-width: 660px;
    padding-top: 2rem;
  }
}

.map {
  height: 200px;
  width: 250px;
  padding-top: 3rem;

  @include mq(pc) {
    height: 300px;
    width: 400px;
  }
}
</style>
