<template>
  <div
    :id="modalId"
    ref="modalEl"
    :class="`modal simple-modal fade`"
    tabindex="-1"
    role="dialog"
    aria-hidden="false"
    @hidePrevented.bs.modal="$emit('hidePrevented.bs.modal')"
    @hidden.bs.modal="$emit('hidden-bs-modal')"
  >
    <div
      class="modal-dialog modal-dialog-centered"
      role="document"
      :class="modalClass"
    >
      <div :class="`modal-content ${slim ? 'slim' : ''}`">
        <div
          v-if="!noHeader"
          class="modal-header d-flex justify-content-center"
          data-bs-dismiss="modal"
          @click="$emit('cancel')"
        >
          <p class="modal-title" :class="titleClass">{{ title }}</p>
          <button
            class="btn btn-close"
            data-dismiss="modal"
            aria-label="Close"
            @click="$emit('cancel')"
          ></button>
        </div>
        <div class="modal-body flex-column-center">
          <slot></slot>
        </div>
        <div v-if="!noActions" class="modal-footer">
          <button
            v-if="!onlyOk"
            class="btn btn-cancel fw-bold"
            name="cancel-btn"
            @click="$emit('cancel')"
          >
            {{ cancelText }}
          </button>
          <button
            v-if="!onlyCancel"
            :class="submitTextClass"
            name="submit-btn"
            type="submit"
            :disabled="submitDisabled"
            @click="$emit('submit')"
          >
            <div v-if="!loading" class="btn-text fw-bold">{{ submitText }}</div>
            <Loading v-else />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { v4 as uuidv4 } from "uuid";
import { Modal } from "bootstrap";
import { onMounted, ref } from "vue";
import { computed } from "@vue/reactivity";
import { Loading } from "/@/vue/Components/Icons";

const props = defineProps({
  title: {
    type: String,
    default: null,
  },
  cancelText: {
    type: String,
    default: "キャンセル",
    required: false,
  },
  submitText: {
    type: String,
    default: "OK",
    required: false,
  },
  submitDisabled: {
    type: Boolean,
    default: false,
    required: false,
  },
  submitTextVariant: {
    type: String,
    default: null,
  },
  hint: {
    type: String,
    default: null,
  },
  onlyOk: {
    type: Boolean,
    required: false,
    default: false,
  },
  onlyCancel: {
    type: Boolean,
    required: false,
    default: false,
  },
  titleClass: {
    type: Object,
    required: false,
    default: () => ({
      "fw-bold": true,
    }),
  },
  modalClass: {
    type: Object,
    required: false,
    default: null,
  },

  loading: {
    type: Boolean,
    required: false,
    default: false,
  },
  noActions: {
    type: Boolean,
    defalut: false,
  },
  noHeader: {
    type: Boolean,
  },
  slim: {
    type: Boolean,
  },
});

const emit = defineEmits(["close", "hidden-bs-modal", "cancel", "submit"]);

const submitTextClass = computed(() => ({
  btn: true,
  "btn-submit": true,
  "text-danger": props.submitTextVariant == "danger",
  "text-primary": props.submitTextVariant == "primary",
}));

// modal

const modalEl = ref();
const modal = ref();
const modalId = "a" + uuidv4();

onMounted(() => {
  if (modalEl.value) {
    modal.value = new Modal(modalEl.value);
  }

  modalEl.value.addEventListener("hidden.bs.modal", hiddenModal);
});

function openModal() {
  if (modal.value) {
    modal.value.show();
  }
}

function closeModal() {
  if (modal.value) {
    emit("close");
    modal.value.hide();
  }
}

function hiddenModal() {
  emit("hidden-bs-modal");
}

function submit() {
  if (modal.value) {
    emit("submit");
    modal.value.hide();
  }
}

defineExpose({
  openModal,
  closeModal,
  submit,
});
</script>

<style lang="scss" scoped>
.modal-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-content {
  position: relative;
  border-radius: 15px;
  width: 320px;

  @include mq(pc) {
    width: 500px;
    min-height: 200px;
  }

  &.slim {
    width: 250px;

    @include mq(pc) {
      width: 500px;
    }
  }

  .modal-header {
    border-bottom: none;
    .modal-title {
      font-size: 24px;
    }
  }

  .btn.close {
    position: absolute;
    right: -2.3rem;
    top: 0.8rem;
    background-color: #4285f4;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
    border: 1px solid #707070;
    padding: 0.25rem 0.75rem 0.25rem 0.5rem;
    z-index: -10;
  }

  .modal-body {
    padding: 16px 26px;
    min-height: 100px;
  }

  .modal-footer {
    display: flex;
    justify-content: center;
    padding: 0;

    .btn {
      border: none;
      border-radius: 0;
      width: 50%;
      height: 50px;
      margin: 0;
      font-size: 13px;

      &:nth-child(2) {
        border-left: 1px solid #dee2e6;
      }
    }
  }
}
</style>
