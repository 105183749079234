<template>
  <div
    class="selector-container d-flex w-100 align-items-center justify-content-end"
  >
    <button
      class="btn flex-column-center left"
      :disabled="disabled"
      @click="updateByArrow({ day: -1 })"
    >
      <ArrowBlackLeftIcon class="icon" />
    </button>
    <span class="selected-date">{{ displayDate }}</span>
    <button
      class="btn right flex-column-center right"
      :disabled="disabled"
      @click="updateByArrow({ day: 1 })"
    >
      <ArrowBlackLeftIcon class="icon mirror" />
    </button>
    <div class="dp__input_wrapper icon-mode pe-2">
      <BasicDatepicker
        :input="jsDate"
        input-class-name="day-selector form-control"
        :disabled="disabled"
        icon-mode
        @update:input="update"
      />
    </div>
    <button
      class="btn plus-btn dropdown-toggle"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      <PlusIcon class="icon" />
    </button>
    <ul class="dropdown-menu">
      <li>
        <button class="btn" @click="$emit('addReservation')">来店予約</button>
      </li>
      <li>
        <button class="btn" @click="$emit('addEvent')">
          店内の予定/休業の作成
        </button>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { computed } from "@vue/reactivity";
import { BasicDatepicker } from "/@/vue/Components/Common";
import { fromDate, basic } from "/@/modules/luxon";
import { ArrowBlackLeftIcon, PlusIcon } from "/@/vue/Components/Icons";

const props = defineProps({
  date: {
    type: Object,
    default: () => {},
  },
  count: {
    type: Number,
    default: null,
  },
  showAddButton: {
    type: Boolean,
  },
  disabled: {
    type: Boolean,
  },
});

const emit = defineEmits(["update:date", "addReservation", "addEvent"]);

const selectedDateTime = computed(() => {
  if (!props.date) {
    return {};
  }

  return props.date.at(0);
});

const jsDate = computed(() => {
  return selectedDateTime.value?.toJSDate();
});

const displayDate = computed(() => {
  return basic(jsDate.value);
});

function update(date) {
  emit("update:date", [fromDate(date)]);
}

function updateByArrow(option) {
  emit(
    "update:date",
    props.date?.map((d) => d.plus(option))
  );
}
</script>

<style lang="scss" scoped>
@import "../Form/form.scss";

.selector-container {
  font-size: 20px;

  .plus-btn {
    background-color: $base-black;
    border-radius: 10px;
    padding: 8px;
  }

  .left,
  .right {
    padding: 8px;
  }

  .right {
    padding-left: 0;
  }
}

.dropdown-toggle::after {
  display: none;
}
</style>
