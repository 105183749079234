<template>
  <div class="form-check">
    <input
      :id="formId"
      :checked="checked"
      type="checkbox"
      :value="value"
      class="form-check-input"
      :name="name"
      @change="onChange"
    />
    <label class="form-check-label" :for="formId">{{ label }}</label>
  </div>
</template>

<script setup>
import { computed } from "@vue/reactivity";

const props = defineProps({
  modelValue: {
    type: [String, Array],
    default: null,
  },
  value: {
    type: [Object, String, Number],
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  formId: {
    type: String,
    required: true,
  },
  name: {
    type: String,
    required: true,
  },
  compareFn: {
    type: Function,
    default: (x) => x,
  },
});

const emit = defineEmits(["update:modelValue"]);

const checked = computed(() => {
  const val = props.value;
  const current = props.modelValue;

  if (typeof current == "object") {
    return current
      .map((c) => props.compareFn(c))
      .includes(props.compareFn(val));
  } else {
    return props.compareFn(current) == props.compareFn(val);
  }
});

function onChange() {
  const val = props.value;
  const current = props.modelValue;

  if (typeof current == "object") {
    if (current.map((c) => props.compareFn(c)).includes(props.compareFn(val))) {
      emit(
        "update:modelValue",
        current.filter((v) => props.compareFn(v) != props.compareFn(val))
      );
    } else {
      emit("update:modelValue", [...current, val]);
    }
  } else {
    emit("update:modelValue", val);
  }
}
</script>

<style lang="scss" scoped></style>
