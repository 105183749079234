<template>
  <textarea
    :class="formClass"
    :value="value"
    :placeholder="placeholder"
    rows="4"
    @input="$emit('update:value', $event.target.value)"
  ></textarea>
</template>

<script setup>
import { computed } from "@vue/reactivity";

const props = defineProps({
  value: {
    type: String,
    default: null,
  },
  placeholder: {
    type: String,
    default: null,
  },
  isValid: {
    type: Boolean,
  },
});

defineEmits(["update:value"]);

const formClass = computed(() => {
  return {
    "form-control": true,
    "is-valid": props.isValid,
    "is-invalid": !props.isValid,
  };
});
</script>

<style lang="scss" scoped>
@import "./form.scss";

textarea {
  height: 100px;
}
</style>
