<template>
  <div class="contents-wrapper">
    <div class="contents-area index-card">
      <VerticalTitle title="menu" direction="left" />

      <div class="menu-text d-flex flex-column align-items-center w-100">
        <div class="image-and-text">
          <MenuImage class="menu-image" loading="lazy" />
          <div class="d-flex flex-column justify-content-center explain-price">
            <span :class="`${isMobile ? 'mt-3' : ''}`">
              Salon de cocoは普段「
              <a class="company-link mt-2" href="https://cmstation.or.jp">
                ケアマネステーション金沢
              </a>
              」でケアマネジャーの仕事をしています。
            </span>

            <span>
              だからこそ、福祉に携わる女性が美しくあれるよう、安心して通っていただける価格設定を実現しました。
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="contents-wrapper">
    <div class="contents-area index-card menu-text">
      <span class="menu-special-text text-center marker-animation">
        他にはない、あなたに寄り添ったプラン
      </span>

      <div class="menu-card-items">
        <div class="mb-3">
          <MenuCardItem
            badge-text="おすすめ"
            sub-text="まとめておとくな"
            title-text="全身コース"
            :price="60000"
          />
        </div>
        <div class="">
          <MenuCardItem
            badge-text="さらにお手頃"
            sub-text="気になる部位だけ"
            title-text="パーツコース"
            :price="20000"
          />
        </div>
      </div>
      <span class="mt-3 pb-0">
        ※上記は福祉職・学生向け料金です。
        その他のご職業の場合は、全身コース120,000円(税込)、各パーツコース40,000円(税込)でのご提供となります。
      </span>
    </div>
  </div>
  <div class="contents-wrapper">
    <div class="contents-area index-card menu-text">
      <span class="menu-special-text text-center marker-animation"
        >大手脱毛サロンとの比較</span
      >

      <CompareTable class="compare-image" loading="lazy" />

      <div class="flavor-text">
        <div class="marker-animation">
          Salon de cocoは「必要最低限」を重視。
        </div>
        <div>
          施術前のシェービング料金やオーバーなアフターケアオプション、
          分割支払いによる金利などで予算がどんどん上がってしまう大手サロンに対し、
          シンプルで手に届く脱毛を提供します。
        </div>
        <div>
          また、福祉職・学生の方にターゲットを絞り、営業時間も平日は夜8時までと、
          不規則な勤務時間のなかでも予約を取りやすくしました。
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import MenuCardItem from "./MenuCardItem.vue";
import { VerticalTitle } from "/@/vue/Components/Common";
import { MenuImage, CompareTable } from "/@/vue/Components/Images";
import { useMqUtils } from "/@/vue/Composables";

const { isMobile } = useMqUtils();
</script>

<style lang="scss" scoped>
.contents-wrapper:not(:last-child) {
  margin-bottom: 20px;

  @include mq(pc) {
    margin-bottom: 40px;
  }
}

.image-and-text {
  display: flex;
  flex-direction: column;

  @include mq(pc) {
    padding-top: 60px;
    flex-direction: row;
  }

  .menu-image {
    height: 242px;
    width: 100%;
    object-fit: contain;
    margin-top: -10px;

    @include mq(pc) {
      order: 2;
      height: 320px;
      width: 400px;
      object-fit: contain;
    }
  }

  .explain-price {
    max-width: 432px;
  }
}

.menu-title {
  min-width: 36px;
}

.menu-text {
  padding: 0 30px 30px 30px;

  @include mq(pc) {
    padding: 0 50px 50px 50px;
  }
}

.menu-special-text {
  margin: 30px 0 15px 0;

  @include mq(pc) {
    margin-top: 90px;
    margin-bottom: 60px;
    font-size: 24px;
  }
}

.menu-card-items {
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include mq(pc) {
    flex-direction: row;
    justify-content: space-around;
  }
}

.flavor-text {
  max-width: 880px;
}

.compare-image {
  width: 100%;
  min-width: 320px;
  padding-bottom: 30px;

  @include mq(pc) {
    max-width: 600px;
    object-fit: cover;
    padding-bottom: 60px;
  }
}
</style>
