<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="9"
    viewBox="0 0 19 9"
  >
    <g id="pullup_button_black" fill="#201d16">
      <path
        d="M 17.74521064758301 8.499998092651367 L 1.254790902137756 8.499998092651367 L 9.500000953674316 0.6887579560279846 L 17.74521064758301 8.500007629394531 L 17.74521064758301 8.499998092651367 Z"
        stroke="none"
      />
      <path
        d="M 9.499996185302734 1.377504348754883 L 2.509584426879883 7.999998092651367 L 16.49042510986328 7.999998092651367 L 9.499996185302734 1.377504348754883 M 9.500000953674316 0.1887531280517578 C 9.623723030090332 0.1887531280517578 9.747446060180664 0.2344284057617188 9.843871116638184 0.3257780075073242 L 18.08908081054688 8.137018203735352 C 18.41721153259277 8.447877883911133 18.19721031188965 8.999998092651367 17.74521064758301 8.999998092651367 L 1.254791259765625 8.999998092651367 C 0.8027915954589844 8.999998092651367 0.5827903747558594 8.447877883911133 0.9109210968017578 8.137027740478516 L 9.156130790710449 0.3257780075073242 C 9.252555847167969 0.2344284057617188 9.376278877258301 0.1887531280517578 9.500000953674316 0.1887531280517578 Z"
        stroke="none"
        fill="#201d16"
      />
    </g>
  </svg>
</template>
