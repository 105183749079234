<template>
  <div class="admin-card">
    <AdminCardHeader title="customer" />
    <CustomerFilter :customers="customers" class="mt-4" />
  </div>
</template>

<script setup>
import useSWRV from "swrv";
import { watch } from "vue";
import { errorHandle } from "/@/modules/error";
import { CustomerFilter } from "/@/vue/Components/Customer";
import { AdminCardHeader } from "/@/vue/Components/Admin";

const { data: customers, error } = useSWRV("/api/v1/customers");
watch(error, (e) => {
  errorHandle(e);
});
</script>

<style lang="scss" scoped></style>
