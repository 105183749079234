<template>
  <div class="admin-card">
    <AdminCardHeader
      :title="`${customerId ? '来店予約の空き状況' : '顧客選択'}`"
      jp
    />
    <div class="mb-3"></div>
    <div v-if="!customerId">
      <SearchForm v-model:search-word="searchWord" />
      <TypeSelector v-model:selected-customer-type="selectedCustomerType" />
      <List
        :customers="filteredCustomers || []"
        role-button
        @select-customer="selectCustomer"
      />
    </div>
    <NewProcess
      v-else
      :all-reservation-details="reservationDetails"
      :all-shop-events="shopEvents"
      :holidays="holidays"
      :customer="customer"
      :after-submit="afterSubmit"
      is-admin
      @submit="submit"
      @reset-error="resetError"
      @to-top="toCustomerPage(customerId)"
    />
    <div v-if="errors.length" class="text-danger flex-column-center">
      <span v-for="(e, i) in errors" :key="`error-${i}`"> {{ e }} </span>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, computed } from "vue";
import useSWRV from "swrv";
import { NewProcess } from "/@/vue/Components/Reservation";
import {
  useCustomer,
  useReservationDetails,
  useRouterUtil,
} from "/@/vue/Composables";
import { SearchForm } from "/@/vue/Components/Common";
import { List, TypeSelector } from "/@/vue/Components/Customer";
import { errorHandle } from "/@/modules/error";
import { useRoute, useRouter } from "vue-router";
import { fetcher } from "/@/modules/axios";
import { AdminCardHeader } from "/@/vue/Components/Admin";

const { toCustomerPage } = useRouterUtil();

const { newReservationDetail } = useReservationDetails();

const { data: reservationDetails, mutate: getReservationDetailsMutate } =
  useSWRV("/api/v1/reservation_details?for_reserve=true", fetcher);

const {
  data: customers,
  error: getCustomersError,
  mutate: getCustomersMutate,
} = useSWRV("/api/v1/customers");
watch(getCustomersError, (e) => {
  errorHandle(e);
});

const { data: shopEvents, mutate: getShopEventsMutate } = useSWRV(
  "/api/v1/shop_events?for_reserve=true",
  fetcher
);

const { data: holidays } = useSWRV("/api/v1/calendar/get_holidays", fetcher);

const route = useRoute();
const customerId = computed(() => {
  return route.query.customerId;
});
const { data: customer, error: getCustomerError } = useSWRV(
  () => customerId.value && "/api/v1/customers/" + customerId.value
);
watch(getCustomerError, (e) => {
  errorHandle(e);
});

// select customer

const { customerTypes, getFilteredCustomers } = useCustomer();

const searchWord = ref(null);
const selectedCustomerType = ref(customerTypes.ALL);

const filteredCustomers = computed(() => {
  return getFilteredCustomers(
    customers.value || [],
    selectedCustomerType.value,
    searchWord.value
  );
});

const router = useRouter();

function selectCustomer(customerId) {
  router.push({
    query: {
      customerId: customerId,
    },
  });
}

const errors = ref([]);
const afterSubmit = ref(false);

async function submit({ reservationDetail, customer, contractCources }) {
  const success = await newReservationDetail(
    reservationDetail,
    customer,
    contractCources
  );

  if (!success) {
    errors.value = [
      "予約の作成に失敗しました。",
      "もう一度お試し頂くかお電話での御予約をお願いします。",
    ];
  } else {
    afterSubmit.value = true;
    errors.value = [];
  }
}

function resetError() {
  errors.value = [];
  getReservationDetailsMutate();
  getShopEventsMutate();
}
</script>

<style lang="scss" scoped></style>
