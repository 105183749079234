<template>
  <div class="list-group list-group-flush">
    <template v-if="beforeReservationAt">
      <TableRow title="変更前の予約日時">
        <span>{{ beforeReservationAt }}</span>
      </TableRow>
      <TableRow title="変更後の予約日時">
        <span>{{ reservationAt }}</span>
      </TableRow>
    </template>
    <TableRow v-else title="予約日時">
      <span>{{ reservationAt }}</span>
    </TableRow>
    <TableRow title="受付内容">
      <span>{{ reservationType }}</span>
    </TableRow>
    <CustomerShow v-if="!customer?.id" v-bind="customerObj"></CustomerShow>
    <slot></slot>
  </div>
</template>

<script setup>
import { computed } from "@vue/reactivity";
import { basic } from "../../../modules/luxon";
import { useCustomer } from "../../Composables";
import { TableRow } from "../Common";
import { Show as CustomerShow } from "../Customer";

const props = defineProps({
  reservation_at: {
    type: String,
    default: null,
  },
  before_reservation_at: {
    type: String,
    default: null,
  },
  customer: {
    type: Object,
    default: () => {},
  },
  reservation_type: {
    type: String,
    default: "care",
  },
});

const reservationType = computed(() => {
  switch (props.reservation_type) {
    case "care":
      return "お手入れのご予約";
    case "explain":
      return "説明・相談のご予約";
    default:
      console.error("no match reservation type");
  }
});

const reservationAt = computed(() => {
  return basic(props.reservation_at, { withTime: true });
});

const beforeReservationAt = computed(() => {
  if (!props.before_reservation_at) {
    return null;
  }
  return basic(props.before_reservation_at, { withTime: true });
});

const { createCustomerObjForShow } = useCustomer();

const customerObj = computed(() => {
  return createCustomerObjForShow(props.customer);
});
</script>

<style lang="scss" scoped></style>
