<template>
  <div
    ref="alert"
    class="alert alert-success alert-dismissible fade show"
    role="alert"
  >
    <h4 class="alert-heading">{{ title }}</h4>
    <slot name="body"></slot>
    <hr />
    <slot name="footer"></slot>
    <button
      type="button"
      class="btn-close"
      data-bs-dismiss="alert"
      aria-label="Close"
    ></button>
  </div>
</template>

<script setup>
import { ref } from "vue";

defineProps({
  title: {
    type: String,
    default: null,
  },
});

defineEmits(["closeAlert"]);

const alert = ref();
</script>

<style lang="scss" scoped></style>
