<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36.6 36.8">
    <defs>
      <filter
        id="plus_button_white"
        x="0"
        y="0"
        width="36.6"
        height="36.8"
        filterUnits="userSpaceOnUse"
      >
        <feOffset dy="0.5" />
        <feGaussianBlur stdDeviation="0.1" result="blur" />
        <feFlood flood-opacity="0.161" />
        <feComposite operator="in" in2="blur" />
        <feComposite in="SourceGraphic" />
      </filter>
    </defs>
    <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#plus_button_white)">
      <path
        id="plus_button_white-2"
        d="M27.5,14.5v0m-16,16h0m16,0h0m0,0v0m0-18a2,2,0,0,1,2,2v14h14a2,2,0,1,1,0,4h-14v14a2,2,0,1,1-4,0v-14h-14a2,2,0,1,1,0-4h14v-14A2,2,0,0,1,27.5,12.5Z"
        transform="translate(-9.2 -12.5)"
        fill="#fff"
      />
    </g>
  </svg>
</template>
