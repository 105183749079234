import { fromISO } from "/@/modules/luxon";
import * as DB from "./db";
import { errorHandle } from "/@/modules/error";

function getStatus(reservations) {
  const count = reservations.length;

  if (count == 2) {
    return "afford";
  } else if (count > 5) {
    return "full";
  } else if (count > 2) {
    return "slightly";
  } else {
    return "very_afford";
  }
}

export default ($axios) => ({
  namespaced: true,
  state: {
    nextId: DB.reservation_details_next_id,
    reservationDetails: DB.reservation_details,
  },

  getters: {
    reservationDetailsArray(state) {
      const ids = Object.keys(state.reservationDetails);
      return ids.map((key) => {
        const reservationDetail = state.reservationDetails[key];
        return {
          ...reservationDetail,
          customer: DB.customers[reservationDetail.customer_id],
        };
      });
    },
    reservations(state, getters) {
      return (dates) => {
        let m = {};

        dates.forEach((d) => {
          const targetDateReservationDetails =
            getters.reservationDetailsArray.filter((rt) => {
              return d == fromISO(rt.reservation_at).toFormat("yyyy-MM-dd");
            });
          const status = getStatus(targetDateReservationDetails);

          m[d] = {
            target_date: d,
            status,
            reservation_details: targetDateReservationDetails,
          };
        });

        return m;
      };
    },
  },

  mutations: {
    ADD(state, reservation) {
      reservation.id = state.nextId;
      state.reservationDetails[state.nextId] = reservation;
      state.nextId += 1;
    },

    UPDATE(state, reservationDetail) {
      const id = reservationDetail.id;
      const current = state.reservationDetails[id];
      state.reservationDetails[id] = {
        ...current,
        ...reservationDetail,
      };
    },

    DELETE(state, { id }) {
      delete state.reservationDetails[id];
    },
  },

  actions: {
    GET_RESERVATION_DETAIL({ state, rootState }, { id }) {
      const reservationDetail = state.reservationDetails[id];

      const customer =
        rootState.customers.customers[reservationDetail.customer_id];

      return {
        ...reservationDetail,
        customer: { ...customer },
      };
    },

    SUBMIT({ commit }, reservation) {
      commit("ADD", reservation);

      /*
      try {
        $axios.post("/api/v1/reservation_details", {
          reservation_details: { ...reservation, id: null },
        });
      } catch (e) {
        errorHandle(e);
        return false;
      }

      return true;
      */
    },
    UPDATE({ commit }, reservationDetail) {
      commit("UPDATE", reservationDetail);
    },
    DELETE({ commit }, { id, customerId }) {
      commit("DELETE", { id });
      commit(
        "customers/DELETE_RESERVATION_DETAIL",
        { id, customerId },
        { root: true }
      );
    },
  },
});
