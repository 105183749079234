<template>
  <form>
    <CustomerForm
      v-show="!customer"
      ref="form"
      v-model:name_first="reservation.customer.name_first"
      v-model:name_last="reservation.customer.name_last"
      v-model:name_first_kana="reservation.customer.name_first_kana"
      v-model:name_last_kana="reservation.customer.name_last_kana"
      v-model:phone_number="reservation.customer.phone_number"
      v-model:email="reservation.customer.email"
    />
    <CustomerCourcesForm
      v-if="reservation_type != 'explain'"
      v-model:selected-contract-cources="reservation.contract_cources"
      :contract-cources="validContractCources"
    />
    <div class="d-flex justify-content-center btns-container">
      <div class="m-2 btn-container">
        <BasicButton @click="$emit('cancel')">キャンセル</BasicButton>
      </div>
      <div class="m-2 btn-container">
        <BasicButton
          button-type="submit"
          :disabled="!isValid"
          @click="$emit('confirm', reservation)"
        >
          次へ
        </BasicButton>
      </div>
    </div>
  </form>
</template>

<script setup>
import { reactive, computed, ref } from "@vue/reactivity";
import { onMounted } from "vue";
import { BasicButton } from "/@/vue/Components/Common";
import { CustomerForm, CustomerCourcesForm } from "/@/vue/Components/Customer";
import { useCources, useContractCources } from "../../Composables";

const props = defineProps({
  customer: {
    type: Object,
    default: () => {},
  },
  reservation_type: {
    type: String,
    default: "care",
  },
  contractCources: {
    type: Array,
    default: () => [],
  },
});

defineEmits(["confirm", "cancel"]);

const reservation = reactive({
  customer: {
    id: null,
    name_first: null,
    name_last: null,
    name_first_kana: null,
    name_last_kana: null,
    email: null,
    phone_number: null,
  },
  contract_cources: [],
  done: false,
  reservation_type: props.reservation_type,
});

const { isValidContractCource } = useContractCources();

onMounted(() => {
  if (props.customer) {
    reservation.customer.id = props.customer.id;
    reservation.customer.name_first = props.customer.name_first;
    reservation.customer.name_last = props.customer.name_last;
    reservation.customer.name_first_kana = props.customer.name_first_kana;
    reservation.customer.name_last_kana = props.customer.name_last_kana;
    reservation.customer.phone_number = props.customer.phone_number;
    reservation.customer.email = props.customer.email;
  }

  reservation.contract_cources = props.contractCources.filter((cc) =>
    isValidContractCource(cc)
  );
});

const validContractCources = computed(() => {
  return props.contractCources.filter((cc) => {
    return isValidContractCource(cc);
  });
});

/*
reservation.customer.name_first = "裕司";
reservation.customer.name_last = "佐藤";
reservation.customer.name_first_kana = "ヒロシ";
reservation.customer.name_last_kana = "サトウ";
reservation.customer.email = "s1200191@gmail.com";
reservation.customer.phone_number = "09011112222";
*/

const form = ref();

const isValid = computed(
  () =>
    form.value?.isValid &&
    (props.reservation_type != "explain" ||
      !reservation.contract_cources.length) &&
    (props.reservation_type != "care" || reservation.contract_cources.length)
);
</script>

<style lang="scss" scoped>
.form-line {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 0.5rem;
  padding-bottom: 1rem;

  label {
    min-width: 124px;
  }

  .input-wrapper {
    width: 100%;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  &.multi-form {
    label:nth-of-type(2) {
      width: 50px;
      min-width: 50px;
    }
  }
}

.btns-container {
  padding-top: 2rem;

  @include mq(pc) {
    padding-top: 5rem;
  }
}

.btn-container {
  width: 100px;

  @include mq(pc) {
    width: 200px;
  }
}
</style>
