<template>
  <div>
    <SearchForm v-if="!withoutSearch" v-model:search-word="searchWord" />

    <div class="py-2">
      <TypeSelector v-model:selected-customer-type="selectedCustomerType" />
      <List :customers="filteredCustomers || []" />
    </div>
  </div>
</template>

<script setup>
import { computed } from "@vue/reactivity";
import { ref } from "vue";
import { List, TypeSelector } from "./";
import { SearchForm } from "/@/vue/Components/Common";
import { useCustomer } from "/@/vue/Composables";

const props = defineProps({
  customers: {
    type: Array,
    default: () => [],
  },
  withoutSearch: {
    type: Boolean,
  },
});

const { customerTypes, getFilteredCustomers } = useCustomer();

const searchWord = ref(null);
const selectedCustomerType = ref(customerTypes.ALL);

const filteredCustomers = computed(() => {
  return getFilteredCustomers(
    props.customers || [],
    selectedCustomerType.value,
    searchWord.value
  );
});
</script>

<style lang="scss" scoped>
.search-form {
  position: fixed;
  top: calc(#{$header-height + $reservation-band-height});
  right: 0;
  left: 0;
  width: 100%;
  padding: 10px 15px;
  background-color: rgba(white, 0.5);

  @include mq(pc) {
    top: 85px;
  }
}
</style>
