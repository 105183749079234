<template>
  <div class="admin-index-menu">
    <div
      v-for="(m, i) in indexMenus"
      :key="m.title"
      :class="`flex-column-center ${i != 0 ? 'mt-5' : ''}`"
    >
      <IndexItem :title="m.title" :to="m.to">
        <component :is="m.image" class="menu-image" />
      </IndexItem>
    </div>
  </div>
</template>

<script setup>
import IndexItem from "./IndexItem.vue";
import {
  ToReservation,
  ToCustomer,
  SorryImage,
} from "/@/vue/Components/Images";

const indexMenus = [
  {
    title: "顧客管理",
    to: { name: "AdminCustomers" },
    image: ToCustomer,
  },
  {
    title: "予約管理",
    to: {
      name: "AdminReservations",
    },
    image: ToReservation,
  },
  {
    title: "QR読み込みテスト",
    to: {
      name: "AdminQr",
    },
    image: SorryImage,
  },
];
</script>

<style lang="scss" scoped>
.admin-index-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 15px;
}

.menu-image {
  width: 140px;
  height: 120px;
  object-fit: contain;

  @include mq(pc) {
    height: 230px;
    width: auto;
  }
}
</style>
