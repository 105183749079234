<template>
  <div class="admin-card">
    <div class="pb-3">
      <AdminCardHeader title="customer" />
    </div>

    <div class="d-flex customer-header">
      <span>{{ customer?.name_last }} {{ customer?.name_first }}様</span>
      <BasicButton
        v-if="customer?.is_confirmed"
        @click="toAdminReservationsNew({ customerId: customer.id })"
      >
        来店予約
      </BasicButton>
      <BasicButton v-else :disabled="loading" @click="reconfirmation">
        認証メールの再送
      </BasicButton>
    </div>

    <ShowAndEditInfo
      v-model:customer="editCustomerForm"
      @update-info="updateInfo"
    />
    <ShowNextReservationDetail
      :reservation_detail="customer?.next_reservation_detail"
      @edit="toAdminReservationsEditPage(customer.next_reservation_detail.id)"
      @delete="openCancelModal"
    />
    <ShowAndEditContractCources
      :customer="customer"
      @to-contract-cources-new="toAdminContractCourcesNew(customer.id)"
      @update-contract-cource="updatePaymentAt"
      @delete-r-d="openCancelRDModal"
    />
    <ShowAndEditMemo
      v-model:customer="editCustomerForm"
      @update-memo="updateMemo"
    />
  </div>

  <teleport to="body">
    <CancelModal
      ref="cancelModal"
      @close="closeCancelModal"
      @delete="removeNextRD"
    />
    <CancelModal
      ref="cancelRDModal"
      @close="closeCancelRDModal"
      @delete="deleteRD"
    />
  </teleport>
</template>

<script setup>
import { ref } from "@vue/reactivity";
import { watch } from "vue";
import { useRoute } from "vue-router";
import { updateWhenAnyChange } from "/@/modules/api";
import { errorHandle } from "/@/modules/error";
import {
  ShowAndEditInfo,
  ShowAndEditMemo,
  ShowAndEditContractCources,
  ShowNextReservationDetail,
} from "/@/vue/Components/Customer";
import { CancelModal } from "/@/vue/Components/UI";
import {
  useContractCources,
  useCustomer,
  useReservationDetails,
  useRouterUtil,
} from "/@/vue/Composables";
import { AdminCardHeader } from "/@/vue/Components/Admin";
import { BasicButton } from "/@/vue/Components/Common";

const {
  toAdminReservationsEditPage,
  toAdminContractCourcesNew,
  toAdminReservationsNew,
} = useRouterUtil();

const route = useRoute();
const {
  getCustomer,
  createCustomerObjForShow,
  updateCustomer,
  submitReconfirmation,
} = useCustomer();
const { data: customer, error, mutate } = getCustomer(route.params.id);

watch(error, (e) => {
  errorHandle(e);
});

const editCustomerForm = ref({});

watch(customer, (c) => {
  if (!c) {
    return;
  }

  const {
    name_first,
    name_last,
    name_first_kana,
    name_last_kana,
    phone_number,
    email,
    memo,
    id,
  } = c;

  editCustomerForm.value.id = id;
  editCustomerForm.value.name_first = name_first;
  editCustomerForm.value.name_last = name_last;
  editCustomerForm.value.name_first_kana = name_first_kana;
  editCustomerForm.value.name_last_kana = name_last_kana;
  editCustomerForm.value.phone_number = phone_number;
  editCustomerForm.value.email = email;
  editCustomerForm.value.memo = memo;
});

function updateInfo() {
  updateWhenAnyChange(
    createCustomerObjForShow(customer.value),
    editCustomerForm.value,
    updateCustomer
  );
}

function updateMemo() {
  updateWhenAnyChange(
    { memo: customer.value.memo },
    editCustomerForm.value,
    updateCustomer
  );
}

const { updateContractCource } = useContractCources();
const { removeReservationDetail } = useReservationDetails();
const cancelModal = ref();

function openCancelModal() {
  cancelModal.value?.openModal();
}

function closeCancelModal() {
  cancelModal.value?.closeModal();
}

async function removeNextRD() {
  await removeReservationDetail(customer.value.next_reservation_detail.id);
  mutate();
  closeCancelModal();
}

// delete RD
const cancelRDModal = ref();
const deleteTargetRDId = ref();

function openCancelRDModal(id) {
  deleteTargetRDId.value = id;
  cancelRDModal.value?.openModal();
}

function closeCancelRDModal() {
  cancelRDModal.value?.closeModal();
  deleteTargetRDId.value = null;
}

async function deleteRD() {
  await removeReservationDetail(deleteTargetRDId.value);
  mutate();

  closeCancelRDModal();
}

async function updatePaymentAt(contractCource) {
  if (await updateContractCource(contractCource)) {
    mutate();
  } else {
    alert("更新に失敗しました。ページをリロードしてからもう一度お試し下さい。");
  }
}

const loading = ref(false);

async function reconfirmation() {
  loading.value = true;

  if (await submitReconfirmation({ email: customer.value.email })) {
    alert("メールの送信を行いました");
  } else {
    alert("何らかのエラーが発生しました。運営へお問い合わせお願いします。");
  }

  loading.value = false;
}
</script>

<style lang="scss" scoped>
.customer-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-height: 40px;
  padding: 0 18px;
  background-color: #e8e8e8;
  width: calc(100% + 36px);
  margin: 0 -18px;
}
</style>
