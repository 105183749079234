<template>
  <nav class="navbar bg-white navbar-light vw-100 vh-100">
    <div class="d-flex flex-column align-items-start w-100 h-100">
      <div
        v-scroll="handleScroll"
        class="navbar w-100 d-flex justify-content-between"
      >
        <router-link
          class="title btn segoe"
          :to="{ name: isAdmin ? 'AdminIndex' : 'Index' }"
        >
          Salon de coco {{ isAdmin ? "-owner-" : "" }}
        </router-link>

        <div class="navbar-toggler">
          <button
            class="btn-close"
            type="button"
            aria-label="Close"
            @click="$emit('close')"
          ></button>
        </div>
      </div>
      <div class="d-flex flex-column-center h-100 w-100">
        <template v-if="!isAdmin">
          <div
            v-for="(mi, i) in indexMenuItemsWithAdminIndex"
            :key="mi.anchor || mi.to"
            :class="i != 0 ? 'mt-3' : ''"
            @click="$emit('close')"
          >
            <NavMenuItem v-bind="mi" />
          </div>
          <button
            class="btn nav-menu-item d-flex flex-column-center mt-3"
            @click="$emit('openFlyer')"
          >
            <span class="segoe nav-title">visit</span>
            <span class="jptitle">ご来店</span>
          </button>
          <div v-if="!currentCustomer" class="mt-3" @click="$emit('close')">
            <NavMenuItem
              title="login"
              jptitle="ログイン"
              to="CustomersSignIn"
            />
          </div>
          <button
            v-else
            class="btn nav-menu-item d-flex flex-column-center mt-3"
            @click="$emit('logout')"
          >
            <span class="segoe nav-title">logout</span>
            <span class="jptitle">ログアウト</span>
          </button>

          <a :href="facebookUrl" target="_blank" class="mt-3">
            <FaceBookIcon class="icon facebook" />
          </a>
        </template>
        <template v-else>
          <div
            v-for="(mi, i) in adminMenuItems"
            :key="mi.to"
            :class="i != 0 ? 'mt-3' : ''"
            @click="$emit('close')"
          >
            <NavMenuItem v-bind="mi" />
          </div>
          <button
            class="btn nav-menu-item mt-3 d-flex flex-column-center"
            @click="$emit('logout')"
          >
            <span class="segoe nav-title">logout</span>
            <span class="jptitle">ログアウト</span>
          </button>
        </template>
      </div>
    </div>
  </nav>
</template>

<script setup>
import { computed } from "@vue/reactivity";
import { useIndexPage, useUi } from "/@/vue/Composables";
import { FaceBookIcon } from "/@/vue/Components/Icons";
import { facebookUrl } from "/@/modules/constants";
import NavMenuItem from "./NavMenuItem.vue";

defineProps({
  isAdmin: {
    type: Boolean,
  },
  currentCustomer: {
    type: Object,
    default: null,
  },
});

defineEmits(["close", "logout", "openFlyer"]);

const { indexMenuItems, adminMenuItems } = useIndexPage();

const indexMenuItemsWithAdminIndex = computed(() => {
  return [...indexMenuItems];
});

const { handleScroll } = useUi();
</script>

<style lang="scss" scoped>
nav.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1500;
  padding: 0;

  .navbar {
    height: $header-height;
    min-height: $header-height;
    transition: height 0.25s ease-in;

    --bs-navbar-padding-y: 0;

    .title {
      transition: font-size 0.25s ease-in;
      font-size: $logo-font-size;
    }

    &.scrolled {
      min-height: $scrolled-header-height;
      height: $scrolled-header-height;
      .title {
        font-size: $logo-font-small-size;
      }
    }
  }

  .navbar-toggler {
    border: none;
  }

  .btn-close {
    height: 30px;
    width: 30px;
    padding: 0;
  }

  .btn-logout {
    padding: 0;
  }
}
</style>
