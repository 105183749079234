import { useReservationDetails } from "./reservationDetails";
import { useShopEvents } from "./shopEvents";
import { fromISO } from "/@/modules/luxon";

export function useEvents() {
  const eventTypes = {
    RESERVATION_DETAIL: "reservationDetail",
    SHOP_EVENT: "shopEvent",
  };

  function createSelectedDateContents({ reservationDetails, shopEvents }) {
    const reservationDetailContents = reservationDetails.map((rd) => ({
      ...rd,
      contentType: eventTypes.RESERVATION_DETAIL,
    }));
    const shopEventContents = shopEvents.map((se) => ({
      ...se,
      contentType: eventTypes.SHOP_EVENT,
    }));

    const contents = [...reservationDetailContents, ...shopEventContents];
    contents.sort((a, b) => {
      const aAt = fromISO(a.reservation_at || a.start_at);
      const bAt = fromISO(b.reservation_at || b.start_at);

      if (aAt < bAt) {
        return -1;
      }
      if (aAt > bAt) {
        return 1;
      }
      return 0;
    });

    return contents;
  }

  const { removeReservationDetail, updateReservationDetail } =
    useReservationDetails();
  const { cancelShopEvent } = useShopEvents();

  async function cancelEvent(event) {
    switch (event.contentType) {
      case eventTypes.RESERVATION_DETAIL:
        await removeReservationDetail(event.id);
        break;
      case eventTypes.SHOP_EVENT:
        await cancelShopEvent(event);
        break;
      default:
        console.error("no match cancel method");
    }
  }

  async function finishEvent(event) {
    switch (event.contentType) {
      case eventTypes.RESERVATION_DETAIL:
        await updateReservationDetail({
          id: event.id,
          done: true,
        });
        break;
      default:
        console.error("no match finish method");
    }
  }

  return { eventTypes, cancelEvent, finishEvent, createSelectedDateContents };
}
