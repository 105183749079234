<template>
  <div class="d-flex align-items-center">
    <span class="me-1">☎</span>
    <a :href="`tel:${tel}`">{{ tel }}</a>
  </div>
</template>

<script setup>
const tel = "076-223-8033";
</script>

<style lang="scss" scoped></style>
