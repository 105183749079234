<template>
  <div v-if="reservation_detail">
    <button v-if="roleButton" class="card card-body content-card w-100">
      <div :class="`d-flex justify-content-start ${done ? 'done' : ''}`">
        <label v-if="at" class="me-3">{{ at.from }}~{{ at.to }}</label>
        <span> {{ cardTitle }}</span>
      </div>
    </button>
    <div v-else class="card card-body content-card">
      <Collapse :contents-id="`reservation-detail-${reservation_detail.id}`">
        <template #header>
          <div
            :class="`d-flex justify-content-start ${
              done ? 'done' : ''
            } flex-wrap`"
          >
            <label v-if="at" class="me-3">{{ at.from }}~{{ at.to }}</label>
            <div class="d-flex flex-wrap">
              <span class="text-nowrap">
                {{ customer?.name_last_kana }}
                {{ customer?.name_first_kana }} 様　
              </span>
              <span class="text-nowraps">{{ courceTitle }}</span>
            </div>
          </div>
        </template>
        <template #contents>
          <CardContents
            :reservation_detail_id="reservation_detail.id"
            :reservation_type="reservation_detail.reservation_type"
            :reservation_at="reservation_detail.reservation_at"
            :done="reservation_detail.done"
            :customer="customer"
            :uuid-access="uuidAccess"
            @finish="$emit('finish')"
            @to-customer-page="
              $emit('toCustomerPage', reservation_detail.customer_id)
            "
            @edit="$emit('edit', reservation_detail.id)"
            @cancel="$emit('cancel')"
          />
        </template>
      </Collapse>
    </div>
  </div>
</template>

<script setup>
import { computed } from "@vue/reactivity";
import { fromISO, getTime } from "/@/modules/luxon";
import { useCustomer, useContractCources } from "/@/vue/Composables";
import { Collapse } from "/@/vue/Components/UI";
import CardContents from "./CardContents.vue";

const props = defineProps({
  reservation_detail: {
    type: Object,
    default: () => {},
  },
  uuidAccess: {
    type: Boolean,
  },
  roleButton: {
    type: Boolean,
  },
});

defineEmits(["finish", "toCustomerPage", "edit", "cancel"]);

const at = computed(() => {
  const ra = props.reservation_detail.reservation_at;
  return {
    from: getTime(ra),
    to: getTime(fromISO(ra).plus({ minutes: 45 })),
  };
});

const { createCustomerObjForShow, getCustomer } = useCustomer();

const customer = computed(() => {
  if (props.reservation_detail.customer) {
    return createCustomerObjForShow(props.reservation_detail.customer);
  } else {
    const customer = getCustomer(props.reservation_detail.customer_id);
    return createCustomerObjForShow(customer);
  }
});

const { contractCourcesToStr } = useContractCources();

const courceTitle = computed(() => {
  return contractCourcesToStr(props.reservation_detail?.contract_cources);
});

const cardTitle = computed(() => {
  if (!customer.value) {
    return "";
  }
  return `${customer.value?.name_last_kana} ${customer.value?.name_first_kana} 様 ${courceTitle.value}`;
});

const done = computed(() => {
  return props.reservation_detail.done;
});
</script>

<style lang="scss" scoped>
.card {
  font-size: 13px;

  .done {
    color: #cccccc;
  }
}
</style>
