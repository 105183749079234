<template>
  <div class="d-flex flex-column">
    <div class="segoe admin-card-title">reserve</div>
    <div class="admin-card with-outer-title">
      <div class="admin-card-inner-title">予定</div>
      <EventCard
        v-if="targetContents.length"
        :events="targetContents"
        @to-admin-reservations-edit-page="toAdminReservationsEditPage"
        @to-customer-page="toCustomerPage"
        @to-admin-shop-events-new="toAdminShopEventsNew"
        @cancel="openCancelModal"
        @finish="openFinishModal"
      />
      <div v-else class="mt-3">予定がありません</div>
    </div>
  </div>

  <teleport to="body">
    <CancelModal
      ref="cancelModal"
      @close="closeCancelModal"
      @delete="runCancelEvent"
    />
    <Modal
      ref="finishModal"
      slim
      no-header
      cancel-text="戻る"
      submit-text-variant="success"
      @submit="runFinish"
      @cancel="closeFinishModal"
      @hidden-bs-modal="closeFinishModal"
    >
      <div class="flex-column-center h-100">
        <span> この予約を来店済にします </span>
      </div>
    </Modal>
  </teleport>
</template>

<script setup>
import { computed } from "@vue/reactivity";
import { ref } from "vue";
import { EventCard } from "/@/vue/Components/Events";
import { Modal, CancelModal } from "/@/vue/Components/UI";
import { useEvents } from "/@/vue/Composables";

const props = defineProps({
  reservationDetails: {
    type: Array,
    default: () => [],
  },
  shopEvents: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits([
  "selectCustomer",
  "mutateReservationDetails",
  "mutateShopEvents",
  "editShopEvent",
  "openCustomerPage",
  "editReservationDetail",
]);

function toAdminShopEventsNew(id) {
  emit("editShopEvent", id);
}
function toCustomerPage(customerId) {
  emit("openCustomerPage", customerId);
}
function toAdminReservationsEditPage(reservationDetailId) {
  emit("editReservationDetail", reservationDetailId);
}

const { createSelectedDateContents, finishEvent } = useEvents();

const selectedDateContents = computed(() => {
  return createSelectedDateContents({
    reservationDetails: props.reservationDetails,
    shopEvents: props.shopEvents,
  });
});

const targetContents = computed(() => {
  return selectedDateContents.value;
});

const selectedContent = ref(null);
const cancelModal = ref();

function openCancelModal(content) {
  selectedContent.value = content;
  cancelModal.value.openModal();
}

function closeCancelModal() {
  selectedContent.value = null;
  cancelModal.value.closeModal();
}

const { cancelEvent } = useEvents();

async function runCancelEvent() {
  await cancelEvent(selectedContent.value);
  emit("mutateReservationDetails");
  emit("mutateShopEvents");
  closeCancelModal();
}

// finish modal

const finishModal = ref();

function openFinishModal(content) {
  selectedContent.value = content;
  finishModal.value.openModal();
}

function closeFinishModal() {
  selectedContent.value = null;
  finishModal.value.closeModal();
}

async function runFinish() {
  await finishEvent(selectedContent.value);
  emit("mutateReservationDetails");
  closeFinishModal();
}
</script>

<style lang="scss" scoped>
.admin-card {
  min-width: 369px;
}
</style>
