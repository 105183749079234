<template>
  <form class="d-flex flex-column align-items-center">
    <div :class="`d-flex flex-column align-items-center title`">
      <template v-if="!afterSubmit">
        <template v-if="before_reservation_at">
          <span class="text-danger">まだ変更は完了しておりません</span>
          <span class="mt-3">変更内容の確認</span>
        </template>
        <template v-else>
          <span class="text-danger">まだ予約は完了しておりません</span>
          <span class="mt-3">予約内容の確認</span>
        </template>
      </template>
      <template v-else>
        <template v-if="before_reservation_at">
          <span>予定日時を変更しました。</span>
          <span>お客様のメールアドレス宛に、</span>
          <span class="pb-3">ご予約完了のメールを送付しました。</span>
        </template>
        <template v-else>
          <span>ご予約ありがとうございます。</span>
          <span>ご登録いただいたメールアドレス宛に、</span>
          <span class="pb-3">ご予約完了のメールを送付しました。</span>
          <template v-if="reservation.reservation_type == 'explain'">
            <span>
              また説明の予約を行なった方は、本人確認のためのメール認証をお願いします。
            </span>
            <span class="caution mb-3">
              メール内リンクから認証を行って下さい。
            </span>
          </template>
        </template>
      </template>
    </div>

    <Show v-bind="reservation" :before_reservation_at="before_reservation_at">
      <ShowCources
        :cources="reservation.contract_cources?.map((cc) => cc.cource)"
      />
    </Show>

    <div v-if="!afterSubmit" class="d-flex btns-container">
      <div class="m-2 btn-container">
        <BasicButton :disabled="disabled" @click="$emit('back')"
          >戻る</BasicButton
        >
      </div>
      <div class="m-2 btn-container">
        <BasicButton
          button-type="submit"
          :disabled="disabled"
          @click="$emit('submit')"
        >
          {{ before_reservation_at ? "変更" : "予約" }}
        </BasicButton>
      </div>
    </div>
    <div v-else class="flex-column-center btns-container">
      <div class="m-2 btn-container">
        <template v-if="before_reservation_at">
          <BasicButton
            v-if="isAdmin"
            @click="callToEmit('toAdminReservations')"
          >
            {{ isPcAdmin ? "最初の画面に戻る" : "予約管理トップへ戻る" }}
          </BasicButton>

          <BasicButton v-else @click="callToEmit('toCustomersIndex')">
            マイページへ戻る
          </BasicButton>
        </template>

        <BasicButton v-else @click="callToEmit('toTop')">
          {{ isPcAdmin ? "最初の画面に戻る" : "トップへ戻る" }}
        </BasicButton>
      </div>
      <div
        v-if="!before_reservation_at && !isAdmin"
        class="mt-4 d-flex flex-column-center"
      >
        <span>ご予約の変更・キャンセルはマイページまたはお電話で行えます</span>
        <TelButton />
      </div>
    </div>
  </form>
</template>

<script setup>
import { computed } from "vue";
import { BasicButton, TelButton } from "/@/vue/Components/Common";
import { Show } from "./";
import { Show as ShowCources } from "/@/vue/Components/Cources";

const props = defineProps({
  reservation: {
    type: Object,
    default: () => {},
  },
  afterSubmit: {
    type: Boolean,
  },
  before_reservation_at: {
    type: String,
    default: null,
  },
  isAdmin: {
    type: Boolean,
  },
  isPcAdmin: {
    type: Boolean,
  },
  loading: {
    type: Boolean,
  },
});

const emit = defineEmits([
  "back",
  "submit",
  "toAdminReservations",
  "toCustomersIndex",
  "toTop",
]);

const disabled = computed(() => {
  return props.loading;
});

function callToEmit(to) {
  emit(to);
}
</script>

<style lang="scss" scoped>
.title {
  padding: 1rem 0;
  margin-bottom: 1rem;

  @include mq(pc) {
    padding: 30px 0;
  }
}

.caution {
  background-color: rgba(#ffff00, 0.8);
}

.btns-container {
  margin-top: 3rem;

  @include mq(pc) {
    margin-top: 5rem;
  }
}

.btn-container {
  width: auto;
  min-width: 100px;
  height: 25px;

  @include mq(pc) {
    min-width: 200px;
    height: 50px;
  }
}
</style>
