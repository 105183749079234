<template>
  <div class="admin-card">
    <BackButton />
    <New
      v-model:selected-cources="selectedCources"
      :cources="cources"
      :after-submit="afterSubmit"
      :customer="customer"
      is-admin
      @submit="submit"
    />
    <template v-if="errorMessage.length">
      <span v-for="e in errorMessage" :key="e" class="text-danger">
        {{ e }}
      </span>
    </template>
  </div>
</template>

<script setup>
import useSWRV from "swrv";
import { watch, ref, computed } from "vue";
import { useRoute } from "vue-router";
import { fetcher } from "/@/modules/axios";
import { errorHandle } from "/@/modules/error";
import { New } from "/@/vue/Components/ContractCources";
import { BackButton } from "/@/vue/Components/Common";
import { useContractCources } from "/@/vue/Composables";

const route = useRoute();
const customerId = computed(() => {
  return route.query.customerId;
});

const { data: customer, error: getCustomerError } = useSWRV(
  () => customerId.value && "/api/v1/customers/" + customerId.value,
  fetcher
);
watch(getCustomerError, (e) => {
  errorHandle(e);
});

const { data: cources, error: getCoucresError } = useSWRV(
  "/api/v1/cources",
  fetcher
);
watch(getCoucresError, (e) => {
  errorHandle(e);
});

const selectedCources = ref([]);
const afterSubmit = ref(false);
const errorMessage = ref([]);
const { createContractCources } = useContractCources();

async function submit() {
  if (
    await createContractCources({
      cources: selectedCources.value,
      customer: customer.value,
    })
  ) {
    afterSubmit.value = true;
  } else {
    errorMessage.value = ["エラーが発生しました"];
  }
}
</script>

<style lang="scss" scoped></style>
