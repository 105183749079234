<template>
  <Modal
    ref="modal"
    slim
    no-header
    cancel-text="戻る"
    submit-text-variant="danger"
    @submit="$emit('delete')"
    @cancel="$emit('close')"
    @hidden-bs-modal="$emit('close')"
  >
    <div class="flex-column-center h-100">
      <span> 予約をキャンセルします </span>
      <span> この操作は取り消せません </span>
    </div>
  </Modal>
</template>

<script setup>
import { Modal } from "/@/vue/Components/UI";
import { useModal } from "/@/vue/Composables";

defineEmits(["delete", "close"]);

const { modal, openModal, closeModal } = useModal();

defineExpose({
  openModal,
  closeModal,
});
</script>

<style lang="scss" scoped></style>
