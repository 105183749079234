import { computed, reactive, ref } from "vue";
import { useRoute } from "vue-router";
import {
  getJpDay,
  format,
  fromISO,
  getWeekday,
  createBetween,
} from "../../modules/luxon";

export function useReservations() {
  const route = useRoute();

  const { createTimes, createExistsMap } = useReservationTimes();

  const selectedDates = ref([]);
  const selectedDatesRange = computed(() => {
    return createBetween(selectedDates.value).map((d) =>
      d.toFormat("yyyy-MM-dd")
    );
  });

  const holidays = ref({});

  function setHolidays(hs) {
    holidays.value = hs;
  }

  const reservationType = computed(() => {
    switch (route.query.type) {
      case "care":
      case "explain":
        return route.query.type;
      default:
        return null;
    }
  });

  const statusTypes = {
    AFFORD: "afford",
    FULL: "full",
    SLIGHTLY: "slightly",
    VERY_AFFORD: "very_afford",
  };

  function countEmptyTimes(times, existsMap) {
    return times.filter((t) => !existsMap[t]).length;
  }

  function getStatus(reservationDetails, shopEvents, isHoliday) {
    const times = createTimes(isHoliday);

    const existsMap = createExistsMap(reservationDetails, shopEvents, {
      shopEvents: [],
      reservationDetails: [],
    });

    const count = countEmptyTimes(times, existsMap);

    console.log("isHoliday", isHoliday);
    console.log("getStatus", shopEvents, existsMap, count);

    if (isHoliday ? count > 15 : count == 7) {
      return statusTypes.VERY_AFFORD;
    } else if (isHoliday ? count > 10 : count > 5) {
      return statusTypes.AFFORD;
    } else if (count > 0) {
      return statusTypes.SLIGHTLY;
    } else {
      return statusTypes.FULL;
    }
  }

  const reservationDetails = ref([]);

  function setReservationDetails(rds) {
    reservationDetails.value = rds;
  }

  const shopEvents = ref([]);

  function setShopEvents(ses) {
    shopEvents.value = ses;
  }

  function isHoliday(d, hs) {
    return getWeekday(d) == 7 || getWeekday(d) == 6 || hs[d];
  }

  function createReservationsFrom(rds, ses, dates, hs) {
    // reservationDetails, shopEvents
    // dates = ["yyyy-MM-DD", "yyyy-MM-DD", ...]
    // return { "yyyy-MM-DD": { ... }, "yyyy-MM-DD": { ... } }

    let m = {};

    dates.forEach((d) => {
      const targetDateReservationDetails = rds.filter((rt) => {
        return d == fromISO(rt.reservation_at).toFormat("yyyy-MM-dd");
      });

      const targetDateShopEvents = ses.filter((se) => {
        return d == fromISO(se.start_at).toFormat("yyyy-MM-dd");
      });

      let status = statusTypes.VERY_AFFORD;

      if (hs) {
        console.log("hs", d, hs);
        status = getStatus(
          targetDateReservationDetails,
          targetDateShopEvents,
          isHoliday(d, hs)
        );
      }

      m[d] = {
        target_date: d,
        status,
        reservation_details: targetDateReservationDetails,
        shop_events: targetDateShopEvents,
      };
    });

    return m;
  }

  const reservations = computed(() => {
    console.log("holidays", holidays.value);
    return createReservationsFrom(
      reservationDetails.value ?? [],
      shopEvents.value ?? [],
      selectedDatesRange.value,
      holidays.value
    );
  });

  // one day

  const targetDateReservations = computed(() => {
    if (!selectedDatesRange.value || !reservations.value) {
      return [];
    }

    return selectedDatesRange.value.map((d) => {
      return reservations.value[d];
    });
  });

  const targetDateReservationDetails = computed(() => {
    const forOneday = targetDateReservations.value?.at(0);
    return forOneday?.reservation_details || [];
  });

  return {
    reservations,
    reservationType,
    setReservationDetails,
    setShopEvents,

    setHolidays,

    selectedDates,
    targetDateReservations,
    targetDateReservationDetails,
  };
}

export function useShowReservation() {
  const reservation = ref();

  function setReservation(r) {
    reservation.value = r;
  }

  const jpDay = computed(() => {
    return getJpDay(reservation.value?.target_date);
  });

  const date = computed(() => {
    return format(reservation.value?.target_date, "M.d");
  });

  const statusSymbol = computed(() => {
    switch (reservation.value?.status) {
      case "slightly":
        return "△";
      case "very_afford":
        return "◎";
      case "afford":
        return "○";
      case "full":
        return "×";
      default:
        return null;
    }
  });

  return {
    setReservation,
    jpDay,
    date,
    statusSymbol,
  };
}

export function useReservationTimes() {
  const selectedDate = ref();
  const selectedTime = ref();

  const jpHolidays = ref([]);

  function setHolidays(holidays) {
    if (!holidays || !Object.keys(holidays)?.length) {
      return;
    }

    jpHolidays.value = holidays;
  }

  const selectedDateWeekday = computed(() => {
    return getWeekday(selectedDate.value);
  });

  function getSelectedDateTime(date, time) {
    if (!date || !time) {
      return null;
    }

    return fromISO(date + "T" + time).toISO();
  }

  const reservations = ref({});

  function setReservations(rs) {
    reservations.value = rs;
  }

  const selectedReservation = computed(() => {
    return reservations.value.find((r) => r.target_date == selectedDate.value);
  });

  const selectedReservationDetails = computed(() => {
    return selectedReservation.value?.reservation_details;
  });

  function isHoliday() {
    return (
      selectedDateWeekday.value == 7 ||
      selectedDateWeekday.value == 6 ||
      jpHolidays.value[selectedDate.value]
    );
  }

  function createTimes(isHoliday) {
    if (isHoliday) {
      return [
        "09:00",
        "09:15",
        "09:30",
        "09:45",
        "10:00",
        "10:15",
        "10:30",
        "10:45",
        "11:00",
        "11:15",
        "11:30",
        "11:45",
        "12:00",
        "12:15",
        "12:30",
        "12:45",
        "13:00",
        "13:15",
        "13:30",
        "13:45",
        "14:00",
        "14:15",
        "14:30",
        "14:45",
        "15:00",
        "15:15",
        "15:30",
      ];
    } else {
      return ["18:00", "18:15", "18:30", "18:45", "19:00", "19:15", "19:30"];
    }
  }

  const times = computed(() => {
    if (!selectedDate.value) {
      return [];
    }

    return createTimes(isHoliday());
  });

  const selectedShopEvents = computed(() => {
    return selectedReservation.value?.shop_events;
  });

  const ignoreExistsMapIds = reactive({
    reservationDetails: [],
    shopEvents: [],
  });

  function createExistsMap(
    selectedReservationDetails,
    selectedShopEvents,
    ignoreExistsMapIds
  ) {
    let m = {};

    const targetReservationDetails = selectedReservationDetails.filter((rd) => {
      return !ignoreExistsMapIds.reservationDetails.includes(rd.id);
    });

    if (targetReservationDetails && targetReservationDetails.length) {
      targetReservationDetails.forEach((rt) => {
        const start = fromISO(rt.reservation_at);

        for (
          let iterator = start;
          iterator <= start.plus({ minute: 30 });
          iterator = iterator.plus({ minute: 15 })
        ) {
          m[iterator.toFormat("HH:mm")] = true;
          m[iterator.minus({ minute: 30 }).toFormat("HH:mm")] = true;
        }
      });
    }

    const targetShopEvents = selectedShopEvents.filter((rd) => {
      return !ignoreExistsMapIds.shopEvents.includes(rd.id);
    });

    if (targetShopEvents && targetShopEvents.length) {
      targetShopEvents.forEach((se) => {
        const start = fromISO(se.start_at);
        const end = fromISO(se.finish_at);

        for (
          let iterator = start;
          iterator <= end;
          iterator = iterator.plus({ minute: 15 })
        ) {
          m[iterator.toFormat("HH:mm")] = true;
        }
      });
    }

    return m;
  }

  const existsMap = computed(() => {
    return createExistsMap(
      selectedReservationDetails.value ?? [],
      selectedShopEvents.value ?? [],
      ignoreExistsMapIds
    );
  });

  return {
    selectedDate,
    selectedTime,
    getSelectedDateTime,
    reservations,
    setReservations,
    selectedReservation,
    selectedReservationDetails,
    times,
    createTimes,

    ignoreExistsMapIds,
    existsMap,
    createExistsMap,

    // holiday
    setHolidays,
  };
}
