import Index from "./Index.vue";
import Show from "./Show.vue";
import New from "./New.vue";
import Edit from "./Edit.vue";

export default [
  {
    path: "",
    name: "ReservationsIndex",
    component: Index,
  },
  {
    path: ":id",
    name: "ReservationsShow",
    component: Show,
  },
  {
    path: "new",
    name: "ReservationsNew",
    component: New,
  },
  {
    path: ":id/edit",
    name: "ReservationsEdit",
    component: Edit,
  },
];
