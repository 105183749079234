<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12.139"
    height="20.996"
    viewBox="0 0 12.139 20.996"
  >
    <g
      id="key_button_black"
      transform="translate(-230.363 -223.667) rotate(25)"
    >
      <g
        id="楕円形_37"
        transform="translate(310.5 102)"
        fill="none"
        stroke="#201d16"
        stroke-width="1"
      >
        <ellipse cx="2" cy="2.5" rx="2" ry="2.5" stroke="none" />
        <ellipse cx="2" cy="2.5" rx="1.5" ry="2" fill="none" />
      </g>
      <g
        id="楕円形_38"
        transform="translate(308 106)"
        fill="none"
        stroke="#201d16"
        stroke-width="1"
      >
        <ellipse cx="2.5" cy="2" rx="2.5" ry="2" stroke="none" />
        <ellipse cx="2.5" cy="2" rx="2" ry="1.5" fill="none" />
      </g>
      <g
        id="楕円形_39"
        transform="translate(312 106)"
        fill="none"
        stroke="#201d16"
        stroke-width="1"
      >
        <ellipse cx="2.5" cy="2" rx="2.5" ry="2" stroke="none" />
        <ellipse cx="2.5" cy="2" rx="2" ry="1.5" fill="none" />
      </g>
      <line
        id="線_145"
        y2="15.5"
        transform="translate(312.5 108.5)"
        fill="none"
        stroke="#201d16"
        stroke-width="1"
      />
      <line
        id="線_146"
        x2="2"
        transform="translate(311.5 110)"
        fill="none"
        stroke="#201d16"
        stroke-linecap="round"
        stroke-width="1"
      />
      <line
        id="線_150"
        x2="1"
        transform="translate(312 111)"
        fill="none"
        stroke="#201d16"
        stroke-linecap="round"
        stroke-width="1"
      />
      <line
        id="線_147"
        x2="1"
        transform="translate(313 122)"
        fill="none"
        stroke="#201d16"
        stroke-width="1"
      />
      <line
        id="線_148"
        x2="2"
        transform="translate(312.5 120.5)"
        fill="none"
        stroke="#201d16"
        stroke-width="1"
      />
      <line
        id="線_149"
        y1="2.5"
        transform="translate(315 120)"
        fill="none"
        stroke="#201d16"
        stroke-width="1"
      />
    </g>
  </svg>
</template>
