import Index from "./Index.vue";
import Show from "./Show.vue";

export default [
  {
    path: "",
    name: "AdminCustomers",
    component: Index,
  },
  {
    path: ":id",
    name: "AdminCustomersShow",
    component: Show,
  },
];
