<template>
  <div v-if="currentRoute.name == 'Index'" class="d-flex bottom-header">
    <router-link
      v-if="!currentCustomer"
      class="w-50 btn"
      :to="{ name: 'CustomersSignUp' }"
    >
      会員登録
    </router-link>
    <router-link v-else class="w-50 btn" :to="{ name: 'CustomersIndex' }">
      マイページ
    </router-link>

    <router-link
      v-if="!currentCustomer"
      class="w-50 btn"
      :to="{ name: 'ReservationsNew', query: { type: 'explain' } }"
    >
      説明(無料)のご予約
    </router-link>
    <router-link
      v-else
      class="w-50 btn"
      :to="{ name: 'ReservationsNew', query: { type: 'care' } }"
    >
      お手入れのご予約
    </router-link>
  </div>
  <div
    v-else-if="title"
    class="reservation-band small-font d-flex justify-content-center align-items-center w-100"
  >
    <span>{{ title }}</span>
  </div>
</template>

<script setup>
import { computed } from "@vue/reactivity";
import { useReservations } from "/@/vue/Composables";

const props = defineProps({
  currentRoute: {
    type: Object,
    default: () => {},
  },
  currentCustomer: {
    type: Object,
    default: () => {},
  },
});

const { reservationType } = useReservations();

const title = computed(() => {
  switch (props.currentRoute.name) {
    case "ReservationsNew":
      return reservationType.value == "care"
        ? "お手入れのご予約"
        : "相談・説明のご予約";
    case "AdminReservationsNew":
      return "予約の追加";
    case "AdminReservations":
    case "AdminShopEventsNew":
      return "予約管理";
    case "AdminCustomers":
      return "顧客一覧";
    case "AdminReservationsEdit":
    case "ReservationsEdit":
      return "予約の変更";
    case "CustomersSignUp":
      return "会員登録";
    case "CustomersIndex":
      return "マイページ";
    default:
      return;
  }
});
</script>

<style lang="scss" scoped>
.bottom-header {
  width: 100%;
  max-width: $contents-area-max-width;
  min-height: $header-bottom-height;

  @include mq(pc) {
    min-height: 40px;
  }

  .btn {
    font-size: $base-font-size;
    display: flex;
    justify-content: center;
    align-items: center;

    @include mq(pc) {
      font-size: $pc-base-font-size;
    }
  }
}

.reservation-band {
  height: $reservation-band-height;
  background-color: black;
  color: white;

  @include mq(pc) {
    height: $pc-reservation-band-height;
    width: 100%;
    font-size: $pc-base-font-size;
  }
}
</style>
