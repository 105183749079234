<template>
  <div class="marker-animation-wrapper w-100 align-items-center">
    <div class="marker-animation">最新脱毛器「REVI DUAL」採用</div>
    <div class="marker-animation">2種類の脱毛方法で自分に合った脱毛を</div>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
span {
  @include mq(pc) {
    font-size: $pc-middle-font-size;
  }
}
</style>
