<template>
  <form v-if="!confirmation" class="flex-column-center">
    <label class="content-label">お客様情報の入力</label>

    <div class="form-wrapper">
      <CustomerForm
        ref="customerForm"
        v-model:name_first="customer.name_first"
        v-model:name_last="customer.name_last"
        v-model:name_first_kana="customer.name_first_kana"
        v-model:name_last_kana="customer.name_last_kana"
        v-model:phone_number="customer.phone_number"
        v-model:email="customer.email"
      />
    </div>

    <label class="content-label">コース選択</label>
    <div v-if="cources" class="form-wrapper cource-select">
      <CourcesSelector
        v-model:selected-cources="selectedCources"
        :cources="cources"
      />
    </div>

    <div class="mt-3">
      <BasicButton button-type="submit" :disabled="disabled" @click="toConfirm">
        次へ
      </BasicButton>
    </div>
  </form>
  <form v-else class="flex-column-center">
    <div>
      <label class="content-label text-danger p-2 pt-3">
        まだ会員登録は完了しておりません
      </label>
      <div v-if="afterSubmit" class="flex-column-center content-label">
        <span> ご登録いただいたメールアドレス宛に </span>
        <span> 認証用メールを送付しました。 </span>
        <span class="caution">メール内リンクから認証を行って下さい。</span>
      </div>
    </div>

    <label class="content-label"> 登録内容の確認 </label>
    <div class="list-group list-group-flush">
      <CustomerShow v-bind="customer" />
      <CourcesShow :cources="selectedCources" />
    </div>
    <Errors :errors="signUpErrors" />
    <div v-if="!hideButtons" class="mt-3">
      <div class="d-flex">
        <div class="p-2">
          <BasicButton
            v-if="!afterSubmit"
            :disabled="loading"
            @click="cancelConfirm"
          >
            修正する
          </BasicButton>
        </div>
        <div class="p-2">
          <BasicButton
            v-if="!afterSubmit"
            button-type="submit"
            :disabled="loading"
            @click="toSignUp"
          >
            登録する
          </BasicButton>
          <!--
          <BasicButton v-else @click="goto({ name: 'Index' })">
            トップへ戻る
          </BasicButton>
          -->
        </div>
      </div>
    </div>
  </form>
</template>

<script setup>
import { watch, ref, computed, reactive } from "vue";
import { BasicButton, Errors } from "/@/vue/Components/Common";
import { CustomerForm, Show as CustomerShow } from "/@/vue/Components/Customer";
import {
  CourcesSelector,
  Show as CourcesShow,
} from "/@/vue/Components/Cources";
import {
  useAuthorize,
  useCources,
  useContractCources,
  useRouterUtil,
} from "/@/vue/Composables";
import { errorHandle } from "/@/modules/error";

const customer = reactive({});

/*
customer.name_first = "テスト";
customer.name_last = "太郎";
customer.name_first_kana = "テスト";
customer.name_last_kana = "タロウ";
customer.email = "t.furukawa@fryth.co.jp";
customer.phone_number = "09011112222";
*/

const customerForm = ref();

const { getCources } = useCources();
const { data: cources, error: getCourcesError } = getCources();

watch(getCourcesError, (e) => {
  if (e) {
    errorHandle(e);
  }
});

const selectedCources = ref([]);

const validater = computed(() => {
  return customerForm.value?.isValid && selectedCources.value.length >= 1;
});

const disabled = computed(() => {
  return !validater.value;
});

const confirmation = ref(false);

function toConfirm() {
  confirmation.value = true;
}

function cancelConfirm() {
  confirmation.value = false;
  signUpErrors.value = [];
}

const afterSubmit = ref(false);
const loading = ref(false);
const signUpErrors = ref([]);
const { constructContractCources } = useContractCources();
const { customerSignUp } = useAuthorize();

const hideButtons = ref(false);

async function toSignUp() {
  const contractCources = constructContractCources(selectedCources.value);

  loading.value = true;
  const { data, errors } = await customerSignUp({
    ...customer,
    contract_cources_attributes: contractCources,
  });
  signUpErrors.value = errors || [];

  if (!signUpErrors.value.length) {
    afterSubmit.value = true;
  } else {
    hideButtons.value = true;
  }

  loading.value = false;
}

const { goto } = useRouterUtil();
</script>

<style lang="scss" scoped>
.content-label {
  padding-top: 20px;
  padding-bottom: 20px;
}

.form-wrapper {
  width: 99%;
  max-width: 330px;

  @include mq(pc) {
    max-width: 800px;
  }

  &.cource-select {
    max-width: 330px;
  }
}

.caution {
  background-color: rgba(#ffff00, 0.8);
}
</style>
