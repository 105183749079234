<template>
  <div class="flex-column-center">
    <Sorry sorry-message="ページが見つかりません" />
    <div class="mt-3">
      <BasicButton @click="goto({ name: 'Index' })">トップへ</BasicButton>
    </div>
  </div>
</template>

<script setup>
import { useRouterUtil } from "/@/vue/Composables";
import { Sorry, BasicButton } from "/@/vue/Components/Common";

const { goto } = useRouterUtil();
</script>

<style lang="scss" scoped></style>
