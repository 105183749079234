<template>
  <div
    v-scroll="handleScroll"
    :class="`header contents-wrapper ${show ? 'show' : ''} ${
      noAnimation ? 'no-animation' : ''
    } ${isAdminPage ? 'admin' : ''}`"
  >
    <div class="contents-area main-header">
      <nav class="navbar bg-light navbar-light p-0">
        <router-link
          :class="`btn segoe title ${isAdminPage ? 'admin' : ''}`"
          :to="{ name: isAdminPage ? 'AdminIndex' : 'Index' }"
        >
          Salon de coco {{ isAdminPage ? "-owner-" : "" }}
        </router-link>
        <PageHeaderRight
          v-if="!isUuidAccess"
          :is-admin-page="isAdminPage"
          :current-customer="currentCustomer"
          :logouting="logouting"
          @toggle-nav-menu="toggleNavMenu"
          @logout="logout"
          @open-flyer="$emit('openFlyer')"
          @to-admin-qr="$emit('toAdminQr')"
        />
      </nav>
    </div>
    <HeaderBottom
      v-if="!isAdminPage"
      :current-route="currentRoute"
      :current-customer="currentCustomer"
    />
    <CustomerHeader
      v-if="isCustomerInfo && !isAdminPage"
      :customer="customer || currentCustomer"
      @to-reservations-new="$emit('toReservationsNew')"
    />
  </div>
  <NavMenu
    v-show="showNavMenu"
    :is-admin="isAdminPage"
    :current-customer="currentCustomer"
    @close="toggleNavMenu"
    @logout="logout"
    @open-flyer="$emit('openFlyer')"
  />
</template>

<script setup>
import { ref } from "vue";
import HeaderBottom from "./PageHeaderBottom.vue";
import NavMenu from "./NavMenu.vue";
import PageHeaderRight from "./PageHeaderRight.vue";
import CustomerHeader from "./CustomerHeader.vue";
import { useUi } from "/@/vue/Composables";

const props = defineProps({
  show: {
    type: Boolean,
  },
  currentRoute: {
    type: Object,
    default: () => {},
  },
  isAdminPage: {
    type: Boolean,
  },
  isUuidAccess: {
    type: Boolean,
  },
  isCustomerInfo: {
    type: Boolean,
  },
  customer: {
    type: Object,
    default: () => {},
  },
  currentCustomer: {
    type: Object,
    default: () => {},
  },
  noAnimation: {
    type: Boolean,
  },
  logouting: {
    type: Boolean,
  },
});

const emit = defineEmits([
  "logout",
  "toReservationsNew",
  "openFlyer",
  "toAdminQr",
]);

const { handleScroll } = useUi();

const showNavMenu = ref(false);

function toggleNavMenu() {
  showNavMenu.value = !showNavMenu.value;
}

function logout() {
  if (props.logouting) {
    return;
  }

  emit("logout");

  if (showNavMenu.value) {
    toggleNavMenu();
  }
}
</script>

<style lang="scss" scoped>
.header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  width: 100vw;
  background-color: rgba(white, 0.5);
  --bs-light-rgb: white;
  z-index: 100;
  transition: opacity 1s ease-in;
  opacity: 0;

  &.show {
    opacity: 1;
  }

  &.no-animation {
    transition: none;
  }

  .contents-area {
    padding: 0;
  }

  .main-header {
    height: $header-height;
    transition: height 0.2s ease-in;
  }

  .title {
    font-size: $logo-font-size;
    transition: font-size 0.2s ease-in;
  }

  &.scrolled {
    .main-header {
      height: $scrolled-header-height;

      .title {
        font-size: $logo-font-small-size;
      }
    }
  }

  @include mq(pc) {
    &.admin {
      min-height: 45px;
      background-color: #ffffff;
      box-shadow: 0 3px 6px #00000029;

      nav.navbar {
        min-height: 45px;
      }
    }

    .title {
      font-size: 24px;

      &.admin {
        font-size: $title-font-size;
      }
    }

    &.scrolled {
      .main-header {
        .title {
          font-size: 24px;
        }
      }
    }

    nav.navbar {
      min-height: 60px;
    }
  }
}
</style>
