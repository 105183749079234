export function createCustomerObj(obj, newReservationDetails = []) {
  const {
    name_first,
    name_last,
    name_first_kana,
    name_last_kana,
    email,
    phone_number,
    reservation_details,
  } = obj;

  return {
    name_first,
    name_last,
    name_first_kana,
    name_last_kana,
    email,
    phone_number,
    reservation_details: reservation_details?.length
      ? reservation_details.concat(newReservationDetails)
      : newReservationDetails,
  };
}

export const createCustomerObjForShow = ({
  name_first,
  name_last,
  name_first_kana,
  name_last_kana,
  email,
  phone_number,
}) => ({
  name_first,
  name_last,
  name_first_kana,
  name_last_kana,
  email,
  phone_number,
});
