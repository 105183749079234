<template>
  <div
    class="pendant-wrapper"
    @mouseover="onLight = true"
    @mouseleave="onLight = false"
  >
    <PendantRightOnImage v-if="onLight" class="pendant-image" />
    <PendantRightOffImage v-else class="pendant-image" />

    <span class="pendant-text">はじめての方(説明のご予約)</span>
  </div>
</template>

<script setup>
import { ref } from "vue";
import {
  PendantRightOnImage,
  PendantRightOffImage,
} from "/@/vue/Components/Images";

const onLight = ref(false);
</script>

<style lang="scss" scoped>
@import "./pendant.scss";
</style>
