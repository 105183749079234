<template>
  <form id="session-sign-in">
    <div class="contents-wrapper">
      <div class="contents-area flex-column-center">
        <template v-if="!afterSubmit">
          <div class="form-group w-100 login-form-group">
            <label>メールアドレス</label>
            <input v-model="email" class="form-control" type="email" />
          </div>

          <Errors
            v-if="
              finalMessage.messages.length > 0 && finalMessage.status == 'error'
            "
            :errors="finalMessage.messages"
          />
          <Messages
            v-if="
              finalMessage.messages.length > 0 &&
              finalMessage.status == 'success'
            "
            :messages="finalMessage.messages"
          />

          <div class="mt-3">
            <BasicButton
              :disabled="disabled"
              button-type="submit"
              @click="login"
            >
              ログインメールの発行
            </BasicButton>
          </div>
        </template>
      </div>
    </div>
  </form>
</template>

<script setup>
import { ref, computed, reactive } from "vue";
import { axios } from "/@/modules/axios";
import { errorHandle } from "/@/modules/error";
import { BasicButton, Messages, Errors } from "/@/vue/Components/Common";

const finalMessage = reactive({
  messages: [],
  status: undefined,
});
const email = ref();
const afterSubmit = ref(false);
const loading = ref(false);

const disabled = computed(() => {
  return ![email.value].every((x) => x) || loading.value;
});

async function login() {
  loading.value = true;

  try {
    const { data } = await axios.post("/api/v1/customers/sign_in", {
      customers: {
        email: email.value,
      },
    });

    if (data.status) {
      finalMessage.messages = ["ログイン用メールを送信しました"];
      finalMessage.status = "success";
      afterSubmit.value = true;
    } else {
      finalMessage.status = "error";

      if (data.error == "customer_not_found") {
        finalMessage.messages = [
          "メールアドレスが登録されていません。会員登録を行って下さい",
        ];
        return;
      } else if (data.error == "unconfirmed") {
        finalMessage.messages = [
          "メールアドレスの認証がお済みではありません。",
          "アカウント作成または説明予約時に届いているメールから、メールアドレスの認証をお願いします。",
        ];
        return;
      }
    }
  } catch (e) {
    errorHandle(e);
    finalMessage.status = "error";
    finalMessage.messages = ["エラーが発生しました。"];
  }
}
</script>

<script></script>
