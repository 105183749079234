<template>
  <div class="contents-wrapper">
    <div class="contents-area index-card">
      <div class="card-body flex-column-center">
        <span class="title mb-3">事前にご確認ください</span>
        <div class="d-flex flex-column texts">
          <span> 施術について </span>
          <span>
            腕や足など機械を当てやすい部分はスタッフサポートの元ご自身で施術を行って頂きます。
            当てにくい背中やご自身での施術がご不安な方はスタッフが担当させていただきます。
          </span>
          <span class="mt-4">
            脱毛完了までの期間、熱さ・痛みには個人差があります。
          </span>
          <span class="mt-4">
            軽度の日焼けも施術可能ですが、ひどい肌荒れを起こしている箇所が見受けられる場合、
            当日の施術をお断りさせていただくことがございます。
          </span>
          <span class="mt-4">
            詳しい内容は初回ご来店時、ご案内させていただきます。
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
.card-body {
  padding: 25px 37px;

  @include mq(pc) {
    padding: 50px 0;
  }
}

.title {
  font-size: $title-font-size;

  @include mq(pc) {
    font-size: $pc-middle-font-size;
  }
}

.texts {
  font-size: 11px;

  @include mq(pc) {
    font-size: 13px;
    max-width: 880px;
  }
}
</style>
