import { computed } from "vue";
import { useRoute, useRouter } from "vue-router";

export function useRouterUtil() {
  const router = useRouter();
  const route = useRoute();

  const currentRoute = computed(() => {
    return router.currentRoute?.value || {};
  });

  const routerId = computed(() => {
    return route?.params.id;
  });

  const routerUuid = computed(() => {
    return route?.query?.uuid;
  });

  const isReservation = computed(() => {
    return currentRoute.value?.name == "ReservationsNew";
  });

  const isIndex = computed(() => {
    return currentRoute.value?.name == "Index";
  });

  const isAdminPage = computed(() => {
    return currentRoute.value?.name?.startsWith("Admin");
  });

  const isAdminIndex = computed(() => {
    return currentRoute.value?.name == "AdminIndex";
  });

  const isAdminReservations = computed(() => {
    return currentRoute.value?.name == "AdminReservations";
  });

  const isAdminCustomers = computed(() => {
    return currentRoute.value?.name == "AdminCustomers";
  });

  const isAdminCustomersShow = computed(() => {
    return currentRoute.value?.name == "AdminCustomersShow";
  });

  const isUuidAccess = computed(() => {
    return !!routerUuid.value;
  });

  const isCustomerInfo = computed(() => {
    return ["CustomersIndex", "AdminCustomersShow"].includes(
      currentRoute.value?.name
    );
  });

  // params & query

  const isSuccessConfirmation = computed(() => {
    return route?.query?.success_confirmation;
  });

  // goto

  function goto(to) {
    router.push(to);
  }

  function toCustomerPage(customer_id) {
    goto({ name: "AdminCustomersShow", params: { id: customer_id } });
  }

  function toCustomersIndex() {
    goto({ name: "CustomersIndex" });
  }

  function toReservationsEditPage(reservation_detail_id) {
    goto({
      name: "ReservationsEdit",
      params: { id: reservation_detail_id },
    });
  }

  function toAdminReservationsEditPage(reservation_detail_id) {
    goto({
      name: "AdminReservationsEdit",
      params: { id: reservation_detail_id },
    });
  }

  function toAdminReservations() {
    goto({
      name: "AdminReservations",
    });
  }

  function toAdminReservationsNew({ customerId }) {
    goto({
      name: "AdminReservationsNew",
      query: { customerId },
    });
  }

  function toAdminShopEventsNew(id) {
    goto({
      name: "AdminShopEventsNew",
      query: { id },
    });
  }

  function toAdminQr() {
    goto({
      name: "AdminQr",
    });
  }

  function toReservationsNew(type) {
    goto({ name: "ReservationsNew", query: { type } });
  }

  function toAdminContractCourcesNew(customerId) {
    goto({
      name: "AdminContractCourcesNew",
      query: { customerId },
    });
  }

  // access to url

  function removeParams(params) {
    const query = Object.assign({}, route.query);
    delete query[params];
    if (!Object.keys(query).length) {
      return router.replace({ name: route.name });
    } else {
      return router.replace({ name: route.name, query });
    }
  }

  return {
    currentRoute,
    routerId,
    routerUuid,
    isReservation,
    isIndex,
    isAdminPage,
    isAdminIndex,
    isAdminReservations,
    isAdminCustomers,
    isAdminCustomersShow,
    isUuidAccess,
    isCustomerInfo,

    // query & params
    isSuccessConfirmation,

    goto,
    toCustomersIndex,
    toCustomerPage,
    toReservationsEditPage,
    toAdminReservations,
    toAdminReservationsEditPage,
    toAdminReservationsNew,
    toAdminContractCourcesNew,
    toAdminShopEventsNew,
    toAdminQr,
    toReservationsNew,

    // access to url
    removeParams,
  };
}
