<template>
  <div id="session-sign-up">
    <div v-if="!creating" class="infos">
      <div class="message">{{ message }}</div>
      <div class="form-group">
        <label fro="name">名前</label>
        <InputForm
          v-model:value="userForm.name"
          form-id="name"
          :is-valid="validater.name"
        />
      </div>
      <div class="form-group">
        <label for="email">メール</label>
        <EmailForm
          ref="mailForm"
          v-model:email="userForm.email"
          form-id="email"
        />
      </div>
      <div class="form-group">
        <label for="password">パスワード</label>
        <InputForm
          v-model:value="userForm.password"
          form-id="password"
          :is-valid="validater.password"
        />
      </div>
      <div class="form-group">
        <label for="password-confirmation">パスワード(確認)</label>
        <InputForm
          v-model:value="userForm.passwordConfirmation"
          form-id="password-confirmation"
          :is-valid="validater.passwordConfirmation"
        />
      </div>
      <div class="mt-3">
        <BasicButton
          :disabled="disabled"
          @click="signUp(userForm, { creating, message, confirmCase })"
        >
          アカウントの作成
        </BasicButton>
      </div>
    </div>
    <div v-if="creating" class="now-creating">{{ resultText }}</div>
  </div>
</template>

<script setup>
import { ref, computed, reactive } from "vue";
import { validaterCheck } from "/@/modules/validation";
import { useAuthorize } from "/@/vue/Composables";
import { InputForm, BasicButton, EmailForm } from "/@/vue/Components/Common";

const message = ref("");
const creating = ref(false);
const confirmCase = ref(false);

const userForm = reactive({
  name: null,
  email: null,
  password: null,
  passwordConfirmation: null,
});

const validater = computed(() => ({
  name: !!userForm.name,
  email: !!userForm.email,
  password: !!userForm.password,
  passwordConfirmation:
    userForm.passwordConfirmation &&
    userForm.password == userForm.passwordConfirmation,
}));

const disabled = computed(() => {
  return !validaterCheck(validater.value);
});

const resultText = ref("アカウント作成中……");

const { signUp } = useAuthorize();
</script>
