<template>
  <div
    :class="`d-flex align-items-center w-100 ${
      isAdmin ? 'admin' : 'fixed-top'
    }`"
  >
    <button
      class="btn rect-btn flex-column-center left"
      :disabled="disabled"
      @click="updateByArrow({ day: -7 })"
    >
      <ArrowBlackLeftIcon v-if="isAdmin" class="icon day-selector-icon" />
      <ArrowGrayLeftIcon v-else class="icon day-selector-icon" />
    </button>
    <div class="px-2 dp__input_wrapper">
      <BasicDatepicker
        week-picker
        :input="jsDates"
        input-class-name="day-selector form-control"
        :min-date="
          isAdmin
            ? null
            : now.plus({ day: 2 }).startOf('week').plus({ day: -1 }).toJSDate()
        "
        @update:input="update"
      />
    </div>

    <button class="btn rect-btn right" @click="updateByArrow({ day: 7 })">
      <ArrowBlackLeftIcon
        v-if="isAdmin"
        class="icon mirror day-selector-icon"
      />
      <ArrowGrayLeftIcon v-else class="icon mirror day-selector-icon" />
    </button>
  </div>
</template>

<script setup>
import { computed } from "@vue/reactivity";
import { BasicDatepicker } from "/@/vue/Components/Common";
import { fromDate, luxonNow } from "/@/modules/luxon";
import { ArrowGrayLeftIcon, ArrowBlackLeftIcon } from "/@/vue/Components/Icons";

const props = defineProps({
  dates: {
    type: Array,
    default: () => [],
  },
  now: {
    type: Object,
    default: () => luxonNow,
  },
  isAdmin: {
    type: Boolean,
  },
});

const emit = defineEmits(["update:dates"]);

const jsDates = computed(() => {
  return props.dates?.map((d) => d.toJSDate());
});

function update(dates) {
  emit(
    "update:dates",
    dates.map((d) => fromDate(d))
  );
}

const disabled = computed(() => {
  if (props.isAdmin) {
    return false;
  }

  const result = props.dates.map((d) => d.plus({ day: -7 }));
  const thisWeek = props.now?.startOf("week");
  return result.at(-1) < thisWeek;
});

function updateByArrow(option) {
  const result = props.dates.map((d) => d.plus(option));

  emit("update:dates", result);
}
</script>

<style lang="scss" scoped>
@import "../Form/form.scss";

.admin {
  .rect-btn {
    background-color: inherit;
  }
}
</style>
