export default ($axios) => ({
  namespaced: true,
  state: {
    showUI: false,
  },
  mutations: {
    SET_SHOW_UI(state, showUI) {
      state.showUI = showUI;
    },
  },
});
