import Index from "./Index.vue";
import SignIn from "./SignIn.vue";
import SignUp from "./SignUp.vue";
import Reconfirmation from "./Reconfirmation.vue";

export default [
  {
    path: "",
    name: "CustomersIndex",
    component: Index,
  },
  {
    path: "sign_in",
    name: "CustomersSignIn",
    component: SignIn,
  },
  {
    path: "sign_up",
    name: "CustomersSignUp",
    component: SignUp,
  },
  {
    path: "reconfirmation",
    name: "CustomersReconfirmation",
    component: Reconfirmation,
  },
];
