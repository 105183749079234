import { computed } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { isSameDate } from "../../modules/luxon";
import { axios } from "/@/modules/axios";
import { errorHandle } from "/@/modules/error";

export function useShopEvents() {
  const store = useStore();
  const route = useRoute();

  const shopEvents = computed(() => {
    return store.state.shopEvents.shopEvents;
  });

  const shopEventsArray = computed(() => {
    return store.getters["shopEvents/shopEventsArray"];
  });

  const editTargetShopEvent = computed(() => {
    const id = route.params.id;
    if (!id) {
      return null;
    }

    return shopEvents.value ? shopEvents.value[id] : null;
  });

  async function addShopEvent(shopEvent) {
    try {
      await axios.post("/api/v1/shop_events", {
        shop_events: shopEvent,
      });

      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  async function editShopEvent({ id, ...shopEvent }) {
    try {
      await axios.patch("/api/v1/shop_events/" + id, {
        shop_events: shopEvent,
      });

      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  async function cancelShopEvent({ id }) {
    try {
      await axios.delete("/api/v1/shop_events/" + id);
      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  function getSelectedDateShopEvents(shopEvents, selectedDateISO) {
    const arr = shopEvents;
    if (!arr) {
      return [];
    }

    return arr.filter((se) => {
      return isSameDate(se.start_at, selectedDateISO);
    });
  }

  return {
    shopEvents,
    shopEventsArray,
    getSelectedDateShopEvents,
    editTargetShopEvent,

    addShopEvent,
    editShopEvent,
    cancelShopEvent,
  };
}
