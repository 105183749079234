<template>
  <div class="admin-card">
    <div class="segoe admin-card-title">customer</div>

    <div v-if="!selectedCustomerId">
      <SearchForm v-model:search-word="searchWord" />
      <TypeSelector v-model:selected-customer-type="selectedCustomerType" />
      <List
        :customers="filteredCustomers || []"
        role-button
        @select-customer="selectCustomer"
      />
    </div>

    <div v-else class="admin-index-card-contents">
      <div class="admin-index-card-main-contents">
        <AdminCardHeader role-button @back="selectCustomer(null)" />
        <div class="d-flex customer-header">
          <span>
            {{ editCustomerForm.name_last }}
            {{ editCustomerForm.name_first }}様
          </span>
          <BasicButton
            @click="$emit('addReservationDetail', selectedCustomerId)"
          >
            来店予約
          </BasicButton>
        </div>
        <ShowAndEditInfo
          v-model:customer="editCustomerForm"
          @update-info="$emit('updateInfo', editCustomerForm)"
        />
        <ShowNextReservationDetail
          :reservation_detail="nextReservationDetail"
          @edit="$emit('editReservationDetail', $event)"
          @delete="deleteRD"
        />
        <ShowAndEditContractCources
          :customer="selectedCustomer"
          @update-contract-cource="$emit('updateContractCource', $event)"
          @delete-r-d="$emit('deleteRD', $event)"
          @to-contract-cources-new="openModal"
        />
      </div>
    </div>
  </div>
  <teleport to="body">
    <ContractCourceSelectorModal
      ref="ccModal"
      :cources="cources"
      :customer="selectedCustomer"
      @close="closeModal"
      @submit="submit"
    />
  </teleport>
</template>

<script setup>
import useSWRV from "swrv";
import { watch, ref, computed } from "vue";
import {
  List,
  TypeSelector,
  ShowAndEditInfo,
  ShowAndEditContractCources,
  ShowNextReservationDetail,
} from "/@/vue/Components/Customer";
import { SearchForm, BasicButton } from "/@/vue/Components/Common";
import { useContractCources, useCustomer } from "/@/vue/Composables";
import { AdminCardHeader } from "..";
import { ContractCourceSelectorModal } from "/@/vue/Components/UI";
import { errorHandle } from "/@/modules/error";

const props = defineProps({
  customers: {
    type: Array,
    default: () => [],
  },
  selectedCustomerId: {
    type: Number,
    default: null,
  },
});

const emit = defineEmits([
  "update:selectedCustomerId",
  "updateInfo",
  "updateContractCource",
  "deleteRD",
  "editReservationDetail",
  "addReservationDetail",
  "updateDatum",
]);

// filter

const { customerTypes, getFilteredCustomers } = useCustomer();

const searchWord = ref(null);
const selectedCustomerType = ref(customerTypes.ALL);

const filteredCustomers = computed(() => {
  return getFilteredCustomers(
    props.customers || [],
    selectedCustomerType.value,
    searchWord.value
  );
});

function selectCustomer(customerId) {
  emit("update:selectedCustomerId", customerId);
}

// show and edit
const editCustomerForm = ref({});

const selectedCustomer = computed(() => {
  return props.customers.find((c) => c.id == props.selectedCustomerId);
});

const nextReservationDetail = computed(() => {
  return selectedCustomer.value?.next_reservation_detail;
});

watch(selectedCustomer, (c) => {
  editCustomerForm.value = { ...c };
});

function deleteRD(id) {
  emit("deleteRD", id);
}

// contract cource

const { data: cources, error: getCoucresError } = useSWRV("/api/v1/cources");
watch(getCoucresError, (e) => {
  errorHandle(e);
});

const ccModal = ref();

const { createContractCources } = useContractCources();

async function submit(cources) {
  if (
    await createContractCources({
      cources,
      customer: selectedCustomer.value,
    })
  ) {
    closeModal();
  } else {
    alert("コース追加時にエラーが発生しました。更新後にもう一度お試し下さい");
  }

  emit("updateDatum");
}

function openModal() {
  ccModal.value?.openModal();
}

function closeModal() {
  ccModal.value?.closeModal();
}
</script>

<style lang="scss" scoped>
.admin-card {
  width: 375px;
  min-width: 375px;
}
.customer-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-height: 40px;
  padding: 0 18px 0 36px;
  background-color: #e8e8e8;
  width: calc(100% + 36px);
  margin: 0 -18px;
}

.admin-card-title {
  padding-top: 0;
}
</style>
