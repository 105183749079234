<template>
  <div class="glass-card flex-column-center justify-content-around w-100 py-3">
    <span :class="`${isMobile ? 'small-font' : ''}`">{{ text }}</span>
    <span>{{ dateTime }}~</span>
  </div>
</template>

<script setup>
import { computed } from "@vue/reactivity";
import { useMqUtils } from "/@/vue/Composables/mq";
import { basic } from "/@/modules/luxon";

const props = defineProps({
  selectedDateTime: {
    type: String,
    default: null,
  },
  isAdmin: {
    type: Boolean,
  },
});

const { isMobile } = useMqUtils();

const dateTime = computed(() => {
  return basic(props.selectedDateTime, { withTime: true });
});

const text = computed(() => {
  if (props.isAdmin) {
    return "変更する日時";
  } else {
    return "お選びいただいた日時";
  }
});
</script>

<style lang="scss" scoped>
.glass-card {
  @include mq(pc) {
    min-height: 160px;
  }
}
</style>
