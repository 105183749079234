<template>
  <div class="flex-column-center">
    <TimeList
      :times="times"
      :exists-map="existsMap"
      :selected-times="selectedTimes"
      @select-time="selectTime"
    />
    <ColorNotification />
  </div>
</template>

<script setup>
import { computed } from "@vue/reactivity";
import ColorNotification from "./ColorNotification.vue";
import TimeList from "./TimeList.vue";

const props = defineProps({
  times: {
    type: Array,
    default: () => [],
  },
  existsMap: {
    type: Object,
    default: () => {},
  },
  selectedStartTime: {
    type: String,
    default: null,
  },
  selectedFinishTime: {
    type: String,
    default: null,
  },
});

const emit = defineEmits([
  "update:selectedStartTime",
  "update:selectedFinishTime",
]);

const selectedTimes = computed(() => {
  return [props.selectedStartTime, props.selectedFinishTime].filter((b) => b);
});

function selectTime(time) {
  if (time == props.selectedStartTime) {
    emit("update:selectedStartTime", null);
    return;
  }

  if (time == props.selectedFinishTime) {
    emit("update:selectedFinishTime", null);
    return;
  }

  if (!props.selectedStartTime) {
    if (props.selectedFinishTime && props.selectedFinishTime < time) {
      emit("update:selectedStartTime", props.selectedFinishTime);
      emit("update:selectedFinishTime", time);
      return;
    }

    emit("update:selectedStartTime", time);
    return;
  }

  if (!props.selectedFinishTime) {
    if (props.selectedStartTime && props.selectedStartTime > time) {
      emit("update:selectedFinishTime", props.selectedStartTime);
      emit("update:selectedStartTime", time);
      return;
    }

    emit("update:selectedFinishTime", time);
    return;
  }

  if (props.selectedStartTime > time) {
    emit("update:selectedStartTime", time);
    return;
  }

  if (props.selectedFinishTime < time) {
    emit("update:selectedFinishTime", time);
    return;
  }
}
</script>

<style lang="scss" scoped></style>
