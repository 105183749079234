<template>
  <div class="glass-card flex-column-center justify-content-around w-100 py-3">
    <span :class="isMobile ? 'small-font' : ''">{{ text }}</span>
    <span>{{ startTime }}~{{ finishTime }}</span>
  </div>
</template>

<script setup>
import { computed } from "@vue/reactivity";
import { useMqUtils } from "/@/vue/Composables/mq";
import { basic, getTime } from "/@/modules/luxon";

const props = defineProps({
  selectedStartAt: {
    type: String,
    default: null,
  },
  selectedFinishAt: {
    type: String,
    default: null,
  },
});

const { isMobile } = useMqUtils();

const startTime = computed(() => {
  return basic(props.selectedStartAt, { withTime: true });
});

const finishTime = computed(() => {
  return getTime(props.selectedFinishAt);
});

const text = computed(() => {
  return "選択した日時";
});
</script>

<style lang="scss" scoped></style>
