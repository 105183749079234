<template>
  <div :class="tableClass">
    <div :class="`title ${noFixedWidth ? 'no-fixed-width' : ''}`">
      <span>{{ title }}</span>
    </div>
    <div class="contnet">
      <slot></slot>
    </div>
  </div>
</template>

<script setup>
import { computed } from "@vue/reactivity";

const props = defineProps({
  title: {
    type: String,
    default: null,
  },
  withoutXPadding: {
    type: Boolean,
  },
  slim: {
    type: Boolean,
  },
  widePadding: {
    type: Boolean,
  },
  roleButton: {
    type: Boolean,
  },
  noFixedWidth: {
    type: Boolean,
  },
});

const tableClass = computed(() => {
  return {
    "d-flex": true,
    "flex-row": true,
    "list-group-item": true,
    "px-0": props.withoutXPadding,
    slim: props.slim,
    "wide-padding": props.widePadding,
    pointer: props.roleButton,
  };
});
</script>

<style lang="scss" scoped>
.list-group-item {
  border-bottom: none;
  &.wide-padding {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.title {
  width: 130px;
  min-width: 70px;

  @include mq(pc) {
    min-width: 100px;
  }

  &.no-fixed-width {
    width: 100%;
  }
}

.slim {
  .title {
    width: 70px;
  }
}
</style>
