<template>
  <Collapse :contents-id="contractCource?.id" :to-left="true">
    <template #header>
      <label class="my-1 cource-title">{{ courceTitle }}</label>
    </template>
    <template #contents>
      <div class="d-flex flex-column">
        <TableStripe>
          <tr>
            <th>契約日</th>
            <th>入金日</th>
          </tr>
          <tr>
            <td>{{ contractedAt }}</td>
            <td>
              <span v-if="!editMode">{{ paymentAt }}</span>
              <BasicDatepicker
                v-else
                :input="newPaymentAt"
                @update:input="updatePaymentAt"
              />
            </td>
          </tr>
          <tr>
            <th>お手入れ回数</th>
            <th>コース必要回数</th>
          </tr>
          <tr>
            <td>{{ doneReservationDetails.length }}</td>
            <td>{{ courceNumber }}</td>
          </tr>
          <tr>
            <th>最終施術日</th>
            <th>次回予定日</th>
          </tr>
          <tr>
            <td>{{ newestAt }}</td>
            <td>{{ nextAt }}</td>
          </tr>
        </TableStripe>

        <TableStripe>
          <tr>
            <td colspan="2">施術履歴</td>
          </tr>
          <template v-if="doneReservationDetails.length">
            <tr v-for="(rd, i) in doneReservationDetails" :key="rd.id">
              <td>
                <CrossIcon
                  v-if="editMode"
                  class="icon delete-icon"
                  @click="removeRD(rd.id)"
                />
                <span>{{ doneReservationDetails.length - i }}回目</span>
              </td>
              <td>{{ basic(rd.reservation_at) }}</td>
            </tr>
          </template>
          <tr v-else>
            <td>-</td>
          </tr>
        </TableStripe>
      </div>
    </template>
  </Collapse>
</template>

<script setup>
import { computed } from "@vue/reactivity";
import { BasicDatepicker, TableStripe } from "/@/vue/Components/Common";
import { Collapse } from "/@/vue/Components/UI";
import { CrossIcon } from "../Icons";
import { basic } from "/@/modules/luxon";
import { ref, onMounted } from "vue";

const props = defineProps({
  contractCource: {
    type: Object,
    default: () => {},
  },
  editMode: {
    type: Boolean,
  },
});

const emit = defineEmits(["update", "delete"]);

const cource = computed(() => {
  return props.contractCource?.cource;
});

const contractedAt = computed(() => {
  return props.contractCource ? basic(props.contractCource.created_at) : "-";
});

const paymentAt = computed(() => {
  return props.contractCource?.payment_at
    ? basic(props.contractCource.payment_at)
    : "-";
});

const courceTitle = computed(() => {
  if (!cource.value) {
    return "";
  }

  switch (cource.value.cource_type) {
    case "all":
    case "explain":
      return cource.value.title;
    default:
      return `パーツコース ${cource.value?.title}`;
  }
});

const courceNumber = computed(() => {
  if (!cource.value) {
    return "";
  }

  return cource.value.required_number;
});

const reservationDetails = computed(() => {
  return props.contractCource?.reservation_details || [];
});

const doneReservationDetails = computed(() => {
  return reservationDetails.value.filter((rd) => {
    return rd.done;
  });
});

const newestAt = computed(() => {
  if (!doneReservationDetails.value.length) {
    return "-";
  }

  const newest = doneReservationDetails.value.at(0);

  return newest ? basic(newest.reservation_at) : "-";
});

const nextAt = computed(() => {
  if (!props.contractCource.next_reservation_detail) {
    return "-";
  }

  const nextRD = props.contractCource.next_reservation_detail;

  return nextRD ? basic(nextRD.reservation_at) : "-";
});

// edit

const newPaymentAt = ref();

onMounted(() => {
  newPaymentAt.value = props.contractCource.payment_at;
});

function updatePaymentAt(at) {
  newPaymentAt.value = at;
  emit("update", { id: props.contractCource.id, payment_at: at });
}

function removeRD(id) {
  emit("delete", id);
}
</script>

<style lang="scss" scoped>
tr,
td {
  width: 50%;
}
td {
  position: relative;

  .delete-icon {
    position: absolute;
    left: 0.5rem;
    color: #8d8d8d;
  }
}

.cource-title {
  text-align: left;
  min-width: 150px;
}
</style>
