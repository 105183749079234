<template>
  <div class="flex-column-center">
    <span class="mb-3">{{ pageTitle }}</span>
    <div class="confirm-line">
      <label>日時</label>
      <span> {{ startAt }}~{{ finishAt }} </span>
    </div>
    <div class="confirm-line">
      <label>予定名</label>
      <span>{{ title }}</span>
    </div>
    <div class="confirm-line">
      <label>詳細</label>
      <span>{{ details }}</span>
    </div>
  </div>
</template>

<script setup>
import { computed } from "@vue/reactivity";
import { basic, getTime } from "/@/modules/luxon";

const props = defineProps({
  start_at: {
    type: String,
    default: null,
  },
  finish_at: {
    type: String,
    default: null,
  },
  title: {
    type: String,
    required: true,
  },
  details: {
    type: String,
    default: null,
  },
  afterSubmit: {
    type: Boolean,
  },
});

const pageTitle = computed(() => {
  return props.afterSubmit ? "登録完了" : "入力内容の確認";
});

const startAt = computed(() => {
  return basic(props.start_at, { withTime: true });
});

const finishAt = computed(() => {
  return getTime(props.finish_at);
});
</script>

<style lang="scss" scoped>
.confirm-line {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-top: 1rem;

  label {
    width: 70px;
  }
}
</style>
