<template>
  <div class="mt-3 memo-area edittable list-group list-group-flush">
    <TableTitle title="メモ" />
    <div class="edit-btn-wrapper">
      <EditToggleButton @start="edit = true" @finish="update" />
    </div>

    <div v-if="!edit" class="form-wrapper">
      <span>{{ customer.memo }}</span>
    </div>
    <div v-else class="form-wrapper">
      <text-area
        :value="customer.memo"
        is-valid
        @update:value="$emit('update:customer', { ...customer, memo: $event })"
      />
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import {
  EditToggleButton,
  TableTitle,
  TextArea,
} from "/@/vue/Components/Common";

defineProps({
  customer: {
    type: Object,
    default: () => {},
  },
});

const emit = defineEmits(["updateMemo", "update:customer"]);

const edit = ref(false);

function update() {
  edit.value = false;
  emit("updateMemo");
}
</script>

<style lang="scss" scoped>
@import "./editable.scss";
</style>
