<template>
  <div class="d-flex flex-column justify-content-center align-items-center">
    <template v-if="!afterSubmit">
      <Errors :errors="errorMessage" />
      <template v-if="failType == 'expired'">
        <div
          class="mt-3 d-flex flex-column justify-content-center align-items-center"
        >
          <span>認証メールの再送を希望する場合は </span>
          <span>以下にメールアドレスを入力してください</span>
        </div>

        <form
          class="py-2 w-100 d-flex flex-column align-items-center"
          @submit.prevent="submit"
        >
          <label for="reconfirmation-email" class="second text-end"
            >メール</label
          >
          <div class="input-wrapper">
            <InputForm
              v-model:value="form.email"
              form-id="reconfirmation-email"
              :is-valid="isValid"
              autocomplete="off"
            />
          </div>
          <div class="mt-3 d-flex flex-column align-items-center">
            <Errors :errors="errors" />
            <BasicButton button-type="submit" :disabled="!isValid">
              再送
            </BasicButton>
          </div>
        </form>
      </template>
    </template>
    <div v-else>
      <Messages :messages="messages" />
    </div>
  </div>
</template>

<script setup>
import { reactive, ref } from "@vue/reactivity";
import { computed } from "vue";
import { useRoute } from "vue-router";
import { axios } from "/@/modules/axios";
import { errorHandle } from "/@/modules/error";
import { isValidEmail } from "/@/modules/validation";
import {
  BasicButton,
  Errors,
  InputForm,
  Messages,
} from "/@/vue/Components/Common";

const route = useRoute();

const failType = computed(() => {
  return route.query.type;
});

const errorMessage = computed(() => {
  if (failType.value == "exists") {
    return [
      "メール認証時にエラーが発生しました。",
      "すでにアカウントが存在します。",
    ];
  } else if (failType.value == "expired") {
    return ["メール認証時にエラーが発生しました。", "認証期限が切れています。"];
  } else {
    return [
      "メール認証時にエラーが発生しました。",
      "認証期限が切れているか,",
      "すでに認証済みのメールアドレスです。",
    ];
  }
});

const isValid = computed(() => {
  return !!form.email && isValidEmail(form.email);
});

const messages = ref([]);
const errors = ref([]);
const afterSubmit = ref(false);
const form = reactive({ email: undefined });

async function submit() {
  messages.value = [];
  errors.value = [];

  try {
    await axios.post("/api/v1/customers/reconfirmation", {
      customers: {
        email: form.email,
      },
    });

    messages.value = [
      "認証用メールの再送を行っています。",
      "お時間を頂く場合がございますので、",
      "古い認証用メールとのお間違いにご注意下さい。",
    ];
    afterSubmit.value = true;
  } catch (e) {
    errors.value = errorHandle(e);
    errors.value = [
      ...errors.value,
      "右上の [login] ボタンよりログインして下さい",
    ];
  }
}
</script>

<style scoped>
.input-wrapper {
  width: 100%;
  max-width: 300px;
}
</style>
