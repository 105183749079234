<template>
  <Datepicker
    :model-value="input"
    locale="ja"
    :format="format"
    :allowed-dates="allowedDates"
    :disabled-dates="disabledDates"
    :disabled="disabled"
    close-on-scroll
    :preview-format="() => ''"
    week-start="0"
    :week-picker="weekPicker"
    :auto-apply="autoApply"
    select-text="決定"
    cancel-text="キャンセル"
    :max-date="maxDate"
    :min-date="minDate"
    :class="`${pickerClass} ${iconMode ? ' icon-mode' : ''}`"
    :month-change-on-scroll="false"
    :clearable="false"
    :input-class-name="inputClassName + (iconMode ? ' icon-mode' : '')"
    :teleport-center="isMobile"
    dark
    @update:model-value="update"
  >
    <template #calendar-header="{ index, day }">
      <div :class="indexClass(index)">{{ day }}</div>
    </template>
    <template #day="{ index, day }">
      <div :class="indexClass(index)">{{ index }} {{ day }}</div>
    </template>
  </Datepicker>
</template>

<script setup>
import Datepicker from "@vuepic/vue-datepicker";
import { useMqUtils } from "/@/vue/Composables";
import { basic } from "/@/modules/luxon";

const props = defineProps({
  input: {
    type: [Object, String, Array],
    default: null,
  },
  allowedDates: {
    type: Array,
    default: () => [],
  },
  disabledDates: {
    type: Array,
    default: () => [],
  },
  maxDate: {
    type: Object,
    default: null,
  },
  minDate: {
    type: Object,
    default: null,
  },
  disabled: Boolean,
  autoApply: {
    type: Boolean,
    default: true,
  },
  pickerClass: {
    type: String,
    default: null,
  },
  inputClassName: {
    type: String,
    default: "form-control",
  },
  weekPicker: {
    type: Boolean,
  },
  iconMode: {
    type: Boolean,
  },
});

const emit = defineEmits(["update:input"]);

const { isMobile } = useMqUtils();

function format(day) {
  if (props.weekPicker) {
    return `${basic(day.at(0))}~${basic(day.at(-1), { noYear: true })}`;
  } else {
    return basic(day);
  }
}

function indexClass(index) {
  if (index === 0) {
    return {
      "text-danger": true,
    };
  } else if (index === 6) {
    return {
      "text-primary": true,
    };
  } else {
    return {};
  }
}

function update(date) {
  emit("update:input", date);
}
</script>

<style scoped lang="scss">
.dp__time-picker {
  max-width: 120px;
}
</style>
