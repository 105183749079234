export function useIndexPage() {
  const indexMenuItems = [
    {
      anchor: "#news",
      title: "news",
      jptitle: "お知らせ",
      to: "Index",
    },
    {
      anchor: "#about",
      title: "about",
      jptitle: "サロンについて",
      to: "Index",
    },
    {
      anchor: "#menu",
      title: "menu",
      jptitle: "料金",
      to: "Index",
    },
    {
      anchor: "#service",
      title: "service",
      jptitle: "サービス",
      to: "Index",
    },
    {
      anchor: "#access",
      title: "access",
      jptitle: "アクセス",
      to: "Index",
    },
  ];

  const adminMenuItems = [
    {
      to: "AdminCustomers",
      title: "customer",
      jptitle: "顧客管理",
    },
    {
      to: "AdminReservations",
      title: "reserve",
      jptitle: "予約管理",
    },
  ];

  return {
    indexMenuItems,
    adminMenuItems,
  };
}
