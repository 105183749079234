<template>
  <div
    class="container d-flex flex-column align-items-center justify-content-center"
  >
    <!-- BlacketLightImage class="image" /-->

    <lottie-animation
      ref="lightAnime"
      class="image"
      :animation-data="lightAnimation"
      :auto-play="false"
    />

    <lottie-animation
      ref="textAnime"
      class="text-anime"
      :animation-data="textAnimation"
      :auto-play="false"
    />
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
//import { BlacketLightImage } from "/@/vue/Components/Images";
import textAnimation from "../../../../../../assets/animation/salondecoco_title_text.json";
import lightAnimation from "../../../../../../assets/animation/top_animation_light_flower.json";

const textAnime = ref();
const lightAnime = ref();

onMounted(() => {
  if (sessionStorage.getItem("access")) {
    lightAnime.value.goToAndStop(lightAnimation.op - 1, true);
    textAnime.value.goToAndStop(textAnimation.op, true);
    return;
  }

  setTimeout(() => {
    lightAnime.value.play();
  }, 2000);

  setTimeout(() => {
    textAnime.value.play();
    sessionStorage.setItem("access", true);
  }, 5000);
});
</script>

<style lang="scss" scoped>
.container {
  height: calc(100% - 100px);
  display: flex;
  justify-content: center;
  align-items: center;

  @include mq(pc) {
    width: 900px;
  }
}

.image {
  height: 220px;

  @include mq(pc) {
    height: 600px;
    width: 900px;
  }
}

.text-anime {
  height: 32px;

  @include mq(pc) {
    height: 70px;
    width: 700px;
  }
}
</style>
