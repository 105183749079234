<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g id="back_button_black" transform="translate(-15 -120)">
      <g
        id="楕円形_50"
        transform="translate(15 120)"
        fill="none"
        stroke="#201d16"
        stroke-width="1"
      >
        <circle cx="12" cy="12" r="12" stroke="none" />
        <circle cx="12" cy="12" r="11.5" fill="none" />
      </g>
      <g id="グループ_555" transform="translate(28.309 128.464) rotate(45)">
        <line
          id="線_170"
          y2="5"
          fill="none"
          stroke="#201d16"
          stroke-linecap="round"
          stroke-width="1"
        />
        <line
          id="線_171"
          x1="5"
          transform="translate(0 5)"
          fill="none"
          stroke="#201d16"
          stroke-linecap="round"
          stroke-width="1"
        />
      </g>
    </g>
  </svg>
</template>
