<template>
  <table class="table table-striped table-light table-mini center">
    <tbody>
      <slot></slot>
    </tbody>
  </table>
</template>

<script setup></script>

<style lang="scss"></style>
