<template>
  <div class="memo-area edittable list-group list-group-flush">
    <TableTitle title="次回のご予約" />
    <div class="form-wrapper">
      <div class="d-flex flex-column">
        <span>{{ reservationAt ?? "ご予約はありません" }}</span>
        <div v-if="reservationAt" class="d-flex align-items-center mt-3">
          <div class="me-3">
            <BasicButton @click="$emit('edit', id)">日時変更</BasicButton>
          </div>
          <div>
            <BasicButton @click="$emit('delete', id)">キャンセル</BasicButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from "@vue/reactivity";
import { basic } from "/@/modules/luxon";
import { BasicButton, TableTitle } from "/@/vue/Components/Common";

const props = defineProps({
  reservation_detail: {
    type: Object,
    default: () => {},
  },
});

defineEmits(["edit", "delete"]);

const reservationAt = computed(() => {
  const at = props.reservation_detail?.reservation_at;

  if (!at) {
    return null;
  }

  return basic(at, { withTime: true });
});

const id = computed(() => {
  return props.reservation_detail.id;
});
</script>

<style lang="scss" scoped>
@import "./editable.scss";
</style>
