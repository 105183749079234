import * as DB from "./db";

export default ($axios) => ({
  namespaced: true,
  state: {
    nextId: DB.shop_events_next_id,
    shopEvents: DB.shop_events,
  },
  getters: {
    shopEventsArray(state) {
      return Object.keys(state.shopEvents).map((key) => state.shopEvents[key]);
    },
  },

  mutations: {
    ADD(state, shopEvents) {
      const id = state.nextId;

      state.shopEvents[id] = {
        ...shopEvents,
        id: id,
      };
      state.nextId++;
    },
    UPDATE(state, shopEvent) {
      const id = shopEvent.id;
      const current = state.shopEvents[id];
      state.shopEvents[id] = {
        ...current,
        ...shopEvent,
      };
    },
    DELETE(state, { id }) {
      delete state.shopEvents[id];
    },
  },

  actions: {
    ADD({ commit }, shopEvent) {
      commit("ADD", shopEvent);
    },
    UPDATE({ commit }, shopEvent) {
      commit("UPDATE", shopEvent);
    },
    DELETE({ commit }, { id }) {
      commit("DELETE", { id });
    },
  },
});
