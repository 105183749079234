import New from "./New.vue";
import Edit from "./Edit.vue";
import Index from "./Index.vue";

export default [
  {
    path: "",
    name: "AdminReservations",
    component: Index,
  },
  {
    path: "new",
    name: "AdminReservationsNew",
    component: New,
  },
  {
    path: ":id/edit",
    name: "AdminReservationsEdit",
    component: Edit,
  },
];
