<template>
  <div class="d-flex flex-column align-items-center pt-1">
    <div v-if="!isAdmin" class="title">
      <h1>来店予約の空き状況</h1>
    </div>
    <div class="d-flex mt-3">
      <ReservationItem
        v-for="(r, i) in reservations"
        v-bind="r"
        :key="r.target_date"
        :selected-date="selectedDate"
        :now="now"
        :is-admin="isAdmin"
        @update:selected-date="updateSelectedDate"
      />
    </div>
    <div class="mt-3">
      <span class="symbol-explanation">
        (×:空きなし △:残りわずか 〇:残りあり ◎:余裕あり)
      </span>
    </div>
    <div class="spacer"></div>
    <template v-if="selectedDate">
      <TimeSelector
        v-model:selected-time="selectedTime"
        :times="times"
        :exists-map="existsMap"
        :selected-times="selectedTimes"
        @update:selected-time="selectTime"
      />
    </template>

    <div v-if="selectedDateTime" class="show-selected w-100 mt-5">
      <ShowSelectedDateTime
        :selected-date-time="selectedDateTime"
        :is-admin="isAdmin"
      />
    </div>
  </div>
</template>

<script setup>
import { computed } from "@vue/reactivity";
import { watchEffect, watch } from "vue";
import { useReservationTimes } from "/@/vue/Composables/reservations";
import { TimeSelector, ShowSelectedDateTime } from "/@/vue/Components/Common";
import ReservationItem from "./ReservationItem.vue";
import { luxonNow } from "/@/modules/luxon";

const props = defineProps({
  reservations: {
    type: Object,
    default: () => {},
  },
  holidays: {
    type: Object,
    default: () => {},
  },
  selectedDateTime: {
    type: String,
    default: null,
  },
  isAdmin: {
    type: Boolean,
  },
  now: {
    type: Object,
    default: () => luxonNow,
  },
});

const emit = defineEmits(["update:selectedDateTime"]);

const {
  selectedDate,
  selectedTime,
  getSelectedDateTime,
  setReservations,
  setHolidays,
  times,
  existsMap,
} = useReservationTimes();

watch(
  () => props.holidays,
  () => {
    setHolidays(props.holidays);
  }
);

const selectedTimes = computed(() => {
  return selectedTime.value ? [selectedTime.value] : [];
});

function selectTime(time) {
  selectedTime.value = time;
  emit(
    "update:selectedDateTime",
    getSelectedDateTime(selectedDate.value, time)
  );
}

watchEffect(() => {
  setReservations(props.reservations);
});

function updateSelectedDate(date) {
  selectedDate.value = date;
  selectedTime.value = null;
  emit("update:selectedDateTime", null);
}
</script>

<style lang="scss" scoped>
h1 {
  font-size: $base-font-size;

  @include mq(pc) {
    font-size: $pc-base-font-size;
  }
}

.spacer {
  @include mq(pc) {
    padding-top: 85px;
  }
}
</style>
