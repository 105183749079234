import { createRouter, createWebHashHistory } from "vue-router";

import Index from "./Index.vue";
import SignIn from "./SignIn.vue";
import SignUp from "./SignUp.vue";
import Policy from "./Policy.vue";
import QrCodeError from "./QrCodeError.vue";
import NotFound from "./NotFound.vue";

import {
  Router as ReservationRouter,
  Root as ReservationRoot,
} from "./Reservations";
import { Router as AdminRouter, Root as AdminRoot } from "./Admin";
import { Router as CustomerRouter, Root as CustomerRoot } from "./Customers";
import {
  Router as ContractCourcesRouter,
  Root as ContractCourcesRoot,
} from "./ContractCources";

export function creatingRouter({ store, axios, version, errorHandle, mock }) {
  const router = createRouter({
    history: createWebHashHistory(),
    routes: [
      {
        path: "/",
        name: "Index",
        component: Index,
      },
      {
        path: "/sign_up",
        name: "SignUp",
        component: SignUp,
      },
      {
        path: "/sign_in",
        name: "SignIn",
        component: SignIn,
      },
      {
        path: "/policy",
        name: "Policy",
        component: Policy,
      },

      {
        path: "/qr_code_error",
        name: "QrCodeError",
        component: QrCodeError,
      },
      {
        path: "/not_found",
        name: "NotFound",
        component: NotFound,
      },
      // children
      {
        path: "/reservations",
        component: ReservationRoot,
        children: [...ReservationRouter],
      },
      {
        path: "/admin",
        component: AdminRoot,
        children: [...AdminRouter],
      },
      {
        path: "/customers",
        component: CustomerRoot,
        children: [...CustomerRouter],
      },
      {
        path: "/contract_cources",
        component: ContractCourcesRoot,
        children: [...ContractCourcesRouter],
      },
    ],
    scrollBehavior(to, from, savedPosition) {
      if (to.name == "Index" && to.hash) {
        return { ...savedPosition, behavior: "auto", el: to.hash, top: 100 };
      } else if (to.name == "Index") {
        return { ...savedPosition, behavior: "auto" };
      } else if (to.hash) {
        return { behavior: "smooth", el: to.hash, top: 100 };
      } else {
        return { top: 0 };
      }
    },
  });

  router.beforeEach(async (to, from, next) => {
    if (mock) {
      next();
    }

    // version

    const currentVersion = version;

    const { data: serverVersion } = await axios
      .get("/api/v1/version")
      .catch((e) => errorHandle(e));

    if (currentVersion < serverVersion) {
      window.alert("アップデートがあるためページを更新します");
      window.location.reload();
      next({ name: from });
      return;
    }

    // show reservtaion

    if (to.name == "ReservationsShow") {
      const uuid = to.query.uuid;
      const id = to.params.id;
      const { data } = await axios
        .get(`/api/v1/reservation_details/${id}/check_uuid?uuid=${uuid}`)
        .catch(() => {});

      if (!data) {
        next({ name: "Index" });
        return;
      }
    }

    // New Reservation

    if (to.name == "ReservationsNew") {
      const allowReservationTypes = ["care", "explain"];

      if (!allowReservationTypes.includes(to.query.type)) {
        // new reservation
        next({ name: "Index" });
        return;
      }
    }

    // admin

    let user = store.state.user.user;

    if (!user) {
      await store.dispatch("user/GET_USER");
      user = store.state.user.user;
    }

    // customer

    const customer = await store.dispatch(
      "customers/GET_CURRENT_CUSTOMER_AXIOS"
    );

    const dontNeedLoginList = [
      "SignUp",
      "SignIn",
      "Index",
      "Policy",
      "PasswordReset",
      "ReservationsIndex",
      "ReservationsNew",
      "ReservationsShow",
      "CustomersSignIn",
      "CustomersSignUp",
      "CustomersReconfirmation",
      "QrCodeError",
      "NotFound",
    ];

    const needCustomerLoginList = ["ReservationsEdit", "ContractCourcesNew"];
    const needCustomerLogin =
      to.name?.startsWith("Customer") ||
      needCustomerLoginList.includes(to.name);

    if (dontNeedLoginList.includes(to.name)) {
      next();
    } else if (to.name?.startsWith("Admin") && !user) {
      next({ name: "SignIn" });
    } else if (to.name?.startsWith("Admin")) {
      next();
    } else if (needCustomerLogin && !customer) {
      next({ name: "CustomersSignIn" });
    } else if (needCustomerLogin) {
      next();
    } else {
      next({ name: "NotFound" });
    }
  });

  return router;
}
