<template>
  <div>
    <div
      :class="`btn p-0 collapse-header ${isOpen ? 'open' : ''} ${
        disabled ? 'disabled' : ''
      } ${toLeft ? 'to-left' : ''}`"
      @click="toggle"
    >
      <slot name="header"></slot>
    </div>

    <div :id="contentsId" ref="el" class="collapse">
      <slot name="contents"></slot>
    </div>
  </div>
</template>

<script setup>
import { Collapse } from "bootstrap";
import { onMounted, ref } from "vue";
import { useCollapse } from "/@/vue/Composables";

const props = defineProps({
  contentsId: {
    type: [String, Number],
    required: true,
  },
  disabled: {
    type: Boolean,
  },
  toLeft: {
    type: Boolean,
  },
});

// collapse

const { isOpen, prepareCollapse } = useCollapse();

const el = ref();
const col = ref();

onMounted(() => {
  col.value = new Collapse(el.value, {
    toggle: false,
  });

  prepareCollapse(el.value);
});

function toggle() {
  if (props.disabled) {
    return;
  }
  col.value.toggle();
}
</script>

<style scoped lang="scss">
.collapse-header {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &::after {
    content: "";
    flex-shrink: 0;
    width: 0.75rem;
    height: 1rem;
    margin-left: auto;
    padding-left: 1rem;
    background-repeat: no-repeat;
    background-size: 0.75rem;
    background-position: center;
    transition: transform 0.2s ease-in-out;
  }

  &.to-left {
    justify-content: flex-start;

    &::after {
      margin-left: 0.5rem;
    }
  }

  &:not(.disabled)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  }

  &.open {
    &::after {
      transform: rotate(180deg);
    }
  }
}
</style>
