<template>
  <div class="admin-index">
    <Modal ref="modal" no-actions @hidden-bs-modal="$emit('close')">
      <New
        ref="newContractCources"
        v-model:selected-cources="selectedCources"
        :cources="cources"
        :customer="customer"
        :after-submit="afterSubmit"
        is-admin
        @submit="submit"
      />
    </Modal>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { Modal } from "/@/vue/Components/UI";
import { useModal } from "/@/vue/Composables";
import { New } from "/@/vue/Components/ContractCources";

defineProps({
  cources: {
    type: Array,
    default: () => [],
  },
  customer: {
    type: Object,
    default: null,
  },
});

const emit = defineEmits(["submit", "close"]);

const afterSubmit = ref(false);
const selectedCources = ref([]);

const { modal, openModal, closeModal: pCloseModal } = useModal();

function submit() {
  emit("submit", selectedCources.value);
  afterSubmit.value = true;
}

const newContractCources = ref();

function closeModal() {
  newContractCources.value.reset();
  afterSubmit.value = false;
  selectedCources.value = [];
  pCloseModal();
}

defineExpose({
  openModal,
  closeModal,
});
</script>

<style lang="scss" scoped></style>
