<template>
  <a
    :href="permalink_url"
    target="_blank"
    class="d-flex flex-column align-items-start news-item"
  >
    <img v-if="full_picture" :src="full_picture" class="news-item-image" />
    <PlaceHolder v-else class="news-item-image" />
    <span class="date small-font">{{ format(created_time, "M.d") }}</span>
    <span class="title text-nowrap">{{ title }}</span>
  </a>
</template>

<script setup>
import { computed } from "@vue/reactivity";
import { format } from "/@/modules/luxon";
import { PlaceHolder } from "/@/vue/Components/Images";
import { useMqUtils } from "/@/vue/Composables";

const props = defineProps({
  message: {
    type: String,
    required: true,
  },
  created_time: {
    type: String,
    required: true,
  },
  full_picture: {
    type: String,
    default: null,
  },
  permalink_url: {
    type: String,
    default: null,
  },
});

const { isMobile } = useMqUtils();

const title = computed(() => {
  if (props.message?.length > 10) {
    return props.message.slice(0, isMobile.value ? 9 : 10) + "…";
  }
  return props.message;
});
</script>

<style lang="scss" scoped>
.news-item-image {
  height: 110px;
  width: 110px;
  border: 1px solid #707070;
  object-fit: cover;

  @include mq(pc) {
    height: 180px;
    width: 180px;
  }
}

a {
  text-decoration: none;
}

.date {
  margin-top: 0.5rem;
  color: #707070;
}
</style>
