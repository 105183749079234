<template>
  <div class="footer w-100 flex-column-center">
    <div class="segoe title">Salon de coco</div>
    <div class="mt-4 d-flex flex-column align-items-center">
      <router-link
        v-for="(mi, i) in indexMenuItems"
        :key="mi.anchor"
        :to="{ name: mi.to, hash: mi.anchor }"
        :class="`d-flex flex-column-center ${i != 0 ? 'mt-2' : ''}`"
      >
        <span class="jptitle">{{ mi.jptitle }}</span>
      </router-link>
      <a class="company-link mt-2" href="https://cmstation.or.jp"
        >ケアマネステーション金沢</a
      >

      <a :href="facebookUrl" target="_blank" class="mt-2">
        <FaceBookIcon class="icon facebook" />
      </a>
    </div>
  </div>
</template>

<script setup>
import { useIndexPage } from "/@/vue/Composables/Pages";
import { FaceBookIcon } from "/@/vue/Components/Icons";
import { facebookUrl } from "/@/modules/constants";

const { indexMenuItems } = useIndexPage();
</script>

<style lang="scss" scoped>
.footer {
  bottom: 0;
  right: 0;
  left: 0;
  min-height: 240px;
  background-color: #201d16;
  color: #ffffff;
  padding: 1rem;
  padding-bottom: 4rem;

  @include mq(pc) {
    min-height: 325px;
  }

  a {
    color: white;
    text-decoration: none;
  }

  .title {
    font-size: 17px;

    @include mq(pc) {
      font-size: $pc-middle-font-size;
    }
  }
}
</style>
