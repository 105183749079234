<template>
  <div>
    <InputForm
      :value="email"
      :is-valid="isValid"
      form-id="email"
      autuocomplete="email"
      @update:value="(value) => $emit('update:email', value)"
    />
    <div v-if="withErrorMessage && !validEmail" class="text-danger">
      正しいメールアドレスを入力してください
    </div>
  </div>
</template>

<script setup>
import { computed } from "@vue/reactivity";
import { isValidEmail } from "/@/modules/validation";
import { InputForm } from "./index";

const props = defineProps({
  email: {
    type: String,
    default: null,
  },
  withErrorMessage: {
    type: Boolean,
  },
  additionalValidator: {
    type: Boolean,
    default: true,
  },
});

defineEmits(["update:email"]);

const validEmail = computed(() => {
  return isValidEmail(props.email);
});

const isValid = computed(() => {
  return !!props.email && validEmail.value && props.additionalValidator;
});

defineExpose({
  isValid,
});
</script>

<style lang="scss" scoped></style>
