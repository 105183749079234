<template>
  <div class="admin-card d-flex flex-column align-items-center">
    <h3 class="mb-3">QRチェック</h3>
    <img v-if="tester" :src="qrcode" class="qrcode mb-3" />
    <Loading v-else class="icon" />
    <div class="mb-3">
      <span :class="read ? 'text-primary' : 'text-danger'">
        QRコード読み込み状況:
        {{ read ? "読み込み済み" : "読み込み前" }}
      </span>
    </div>

    <BasicButton @click="resetReservationDetail">
      テストデータの初期化
    </BasicButton>
  </div>
</template>

<script setup>
import { computed } from "@vue/reactivity";
import useSWRV from "swrv";
import { BasicButton } from "/@/vue/Components/Common";
import { Loading } from "/@/vue/Components/Icons";
import { useReservationDetails } from "/@/vue/Composables";

const { data: tester } = useSWRV("/api/v1/customers/tester");

const qrcode = computed(() => {
  return tester.value?.test_qrcode_generate_url;
});

const testReservationDetail = computed(() => {
  return tester.value?.reservation_details.at(0);
});

const read = computed(() => {
  return testReservationDetail.value?.done;
});

const { updateReservationDetail } = useReservationDetails();

async function resetReservationDetail() {
  const { id } = testReservationDetail.value;

  if (await updateReservationDetail({ id, done: false })) {
    alert("リセットしました");
  } else {
    alert("問題が発生しリセットに失敗しました。開発へご連絡お願いします");
  }
}
</script>

<style lang="scss" scoped></style>
