<template>
  <div class="my-3">
    <DaySelector
      v-if="!inputShopEventInfo"
      :date="selectedDates"
      :disabled="!!inputShopEventInfo"
      is-admin
      @update:date="changeSelectedDates"
    />
  </div>
  <template v-if="!confirmInputs">
    <div class="flex-column-center pb-3">
      <template v-if="!inputShopEventInfo">
        <div class="times d-flex flex-column align-items-center mt-4">
          <span class="mb-2">開始時間と終了時間を選択してください</span>
          <span class="mb-2 text-danger"> 選択した時間はお客様が予約を </span>
          <span class="mb-2 text-danger">作成することができなくなります</span>
          <div class="mt-3 mb-4">
            <BasicButton @click="selectAllDay">終日選択</BasicButton>
          </div>

          <MultiTimeSelector
            v-model:selected-start-time="selectedStartTime"
            v-model:selected-finish-time="selectedFinishTime"
            :times="times"
            :exists-map="existsMap"
          />
        </div>
      </template>

      <div
        v-if="selectedStartAt"
        :class="`show-selected w-100 ${inputShopEventInfo ? '' : 'mt-5'}`"
      >
        <ShowSelectedDateRange
          :selected-start-at="selectedStartAt"
          :selected-finish-at="selectedFinishAt"
          :is-admin="true"
        />
      </div>

      <div v-if="!inputShopEventInfo" class="pt-5 btn-container">
        <BasicButton
          :disabled="!isValidForNext"
          @click="inputShopEventInfo = true"
        >
          次へ
        </BasicButton>
      </div>

      <div v-if="inputShopEventInfo" class="form-wrapper w-100">
        <div class="flex-column-center w-100">
          <ShopEventForm
            ref="eventForm"
            v-model:title="newShopEvent.title"
            v-model:details="newShopEvent.details"
          />

          <div class="w-100 flex-row-center mt-4">
            <div class="btn-container">
              <div class="p-2">
                <BasicButton @click="inputShopEventInfo = false">
                  キャンセル
                </BasicButton>
              </div>
            </div>
            <div class="btn-container">
              <div class="p-2">
                <BasicButton
                  :disabled="!isValidForConfirm"
                  @click="confirmInputs = true"
                >
                  確認へ
                </BasicButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  <template v-else>
    <div class="flex-column-center w-100">
      <div class="form-wrapper confirm">
        <Confirm
          :title="newShopEvent.title"
          :details="newShopEvent.details"
          :start_at="selectedStartAt"
          :finish_at="selectedFinishAt"
          :after-submit="afterSubmit"
        />
      </div>
      <div class="mt-4 w-100 flex-row-center">
        <template v-if="!afterSubmit">
          <div class="p-2">
            <BasicButton @click="confirmInputs = false">修正する</BasicButton>
          </div>
          <div class="p-2">
            <BasicButton @click="submit">登録する</BasicButton>
          </div>
        </template>
        <template v-else>
          <BasicButton @click="emit('toTop')"> 予約管理トップへ </BasicButton>
        </template>
      </div>
      <Errors :errors="errors" />
    </div>
  </template>
</template>

<script setup>
import { useReservations, useReservationTimes } from "/@/vue/Composables";
import {
  DaySelector,
  Errors,
  BasicButton,
  ShowSelectedDateRange,
  MultiTimeSelector,
} from "/@/vue/Components/Common";
import { Confirm, ShopEventForm } from "/@/vue/Components/ShopEvents";
import { ref, watch, watchEffect, onMounted, computed, reactive } from "vue";
import { fromISO, getTime, luxonNow } from "/@/modules/luxon";

const props = defineProps({
  allReservationDetails: {
    type: Array,
    default: () => [],
  },
  allShopEvents: {
    type: Array,
    default: () => [],
  },
  holidays: {
    type: Object,
    default: () => {},
  },
  shopEvent: {
    type: Object,
    default: null,
  },
  now: {
    type: Object,
    default: () => luxonNow,
  },
  afterSubmit: {
    type: Boolean,
  },
});

const emit = defineEmits(["submit", "toTop"]);

const {
  selectedDates,
  setHolidays: setHolidaysForStatus,
  targetDateReservations,
  setReservationDetails,
  setShopEvents,
} = useReservations();

const {
  selectedDate,
  setReservations,
  setHolidays,
  times,
  existsMap,
  getSelectedDateTime,
  ignoreExistsMapIds,
} = useReservationTimes();

onMounted(() => {
  selectedDates.value = [props.now];
  selectedDate.value = props.now.toFormat("yyyy-MM-dd");

  setHolidays(props.holidays);
  setHolidaysForStatus(props.holidays);
  setReservationDetails(props.allReservationDetails);
  setShopEvents(props.allShopEvents);
});

watch(
  () => props.allReservationDetails,
  (ards) => {
    setReservationDetails(ards);
  }
);

watch(
  () => props.allShopEvents,
  (ases) => {
    setShopEvents(ases);
  }
);

watch(
  () => props.holidays,
  (hs) => {
    setHolidays(hs);
    setHolidaysForStatus(hs);
  }
);

watch(
  () => props.now,
  (now) => {
    selectedDates.value = [now];
    selectedDate.value = now.toFormat("yyyy-MM-dd");
  }
);

watchEffect(() => {
  setReservations(targetDateReservations.value);
});

watch(
  () => props.shopEvent,
  (se) => {
    if (se) {
      newShopEvent.title = se.title;
      newShopEvent.details = se.details;

      ignoreExistsMapIds.shopEvents.push(se.id);
      const startAt = fromISO(se.start_at).startOf("day");
      selectedDates.value = [startAt];
      selectedDate.value = startAt.toFormat("yyyy-MM-dd");
      selectedStartTime.value = getTime(se.start_at);
      selectedFinishTime.value = getTime(se.finish_at);
    }
  }
);

const selectedStartTime = ref();
const selectedFinishTime = ref();
// change date

function selectAllDay() {
  selectedStartTime.value = times.value.at(0);
  selectedFinishTime.value = times.value.at(-1);
}

function changeSelectedDates(date) {
  selectedDates.value = date;
  selectedDate.value = date.at(0).toFormat("yyyy-MM-dd");
  selectedStartTime.value = null;
  selectedFinishTime.value = null;
}

const selectedStartAt = computed(() => {
  return getSelectedDateTime(selectedDate.value, selectedStartTime.value);
});

const selectedFinishAt = computed(() => {
  return getSelectedDateTime(selectedDate.value, selectedFinishTime.value);
});
const isValidForNext = computed(() => {
  return !!selectedStartTime.value && !!selectedFinishTime.value;
});

// input info

const inputShopEventInfo = ref(false);

const newShopEvent = reactive({});
const eventForm = ref();

// confirm

const confirmInputs = ref(false);

const isValidForConfirm = computed(() => {
  return eventForm.value?.isValid;
});

// add

const errors = ref([]);

function submit() {
  const shopEvent = {
    id: props.shopEvent?.id,
    start_at: selectedStartAt.value,
    finish_at: selectedFinishAt.value,
    ...newShopEvent,
  };

  emit("submit", shopEvent);
}
</script>

<style lang="scss" scoped></style>
