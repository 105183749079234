<template>
  <div class="contract-cources-root">
    <router-view></router-view>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
.contract-cources-root {
  width: 100%;

  @include mq(pc) {
    padding-top: 120px;
  }
}
</style>
