<template>
  <div class="info-area edittable list-group list-group-flush">
    <TableTitle title="お客様情報" />
    <div class="edit-btn-wrapper">
      <EditToggleButton @start="edit = true" @finish="update" />
    </div>

    <Show v-if="!edit" v-bind="createCustomerObjForShow(customer)"></Show>

    <div v-else class="form-wrapper">
      <CustomerForm
        :name_first="customer.name_first"
        :name_last="customer.name_last"
        :name_first_kana="customer.name_first_kana"
        :name_last_kana="customer.name_last_kana"
        :phone_number="customer.phone_number"
        :email="customer.email"
        @update:name_first="
          $emit('update:customer', { ...customer, name_first: $event })
        "
        @update:name_last="
          $emit('update:customer', { ...customer, name_last: $event })
        "
        @update:name_first_kana="
          $emit('update:customer', { ...customer, name_first_kana: $event })
        "
        @update:name_last_kana="
          $emit('update:customer', { ...customer, name_last_kana: $event })
        "
        @update:phone_number="
          $emit('update:customer', { ...customer, phone_number: $event })
        "
        @update:email="$emit('update:customer', { ...customer, email: $event })"
      />
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useCustomer } from "/@/vue/Composables";
import { CustomerForm, Show } from ".";
import { EditToggleButton, TableTitle } from "/@/vue/Components/Common";

defineProps({
  customer: {
    type: Object,
    default: () => {},
  },
});

const emit = defineEmits(["updateInfo", "update:customer"]);

const { createCustomerObjForShow } = useCustomer();

const edit = ref(false);

function update() {
  edit.value = false;
  emit("updateInfo");
}
</script>

<style lang="scss" scoped>
@import "./editable.scss";
</style>
