<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.466 25.887">
    <g id="pen__button_gray" transform="translate(0)">
      <g
        id="グループ_212"
        transform="matrix(0.819, 0.574, -0.574, 0.819, 180.281, -340.777)"
      >
        <g
          id="長方形_115"
          transform="translate(61 378)"
          fill="#8d8d8d"
          stroke="#8d8d8d"
          stroke-width="1"
        >
          <rect width="6" height="18" stroke="none" />
          <rect x="0.5" y="0.5" width="5" height="17" fill="none" />
        </g>
        <g
          id="多角形_5"
          transform="translate(68 400) rotate(180)"
          fill="#8d8d8d"
        >
          <path
            d="M 5.919379234313965 4.499999523162842 L 2.080629110336304 4.499999523162842 C 1.883738994598389 4.499999523162842 1.715269088745117 4.394049644470215 1.629979014396667 4.21658992767334 C 1.544689059257507 4.039139747619629 1.567198991775513 3.841399669647217 1.690189003944397 3.687659740447998 L 3.609559059143066 1.288439750671387 C 3.704968929290771 1.169179677963257 3.847279071807861 1.10077965259552 3.999999046325684 1.10077965259552 C 4.152719020843506 1.10077965259552 4.295029163360596 1.169179677963257 4.390439033508301 1.288439750671387 L 6.30980920791626 3.687659740447998 C 6.432798862457275 3.841399669647217 6.45530891418457 4.039139747619629 6.37001895904541 4.216599464416504 C 6.28472900390625 4.394059658050537 6.116269111633301 4.499999523162842 5.919379234313965 4.499999523162842 Z"
            stroke="none"
          />
          <path
            d="M 3.999999284744263 1.60077977180481 L 2.080629348754883 3.999999523162842 L 5.919366836547852 3.999999523162842 C 5.919366836547852 3.999999046325684 5.919367790222168 3.999998331069946 5.919369220733643 3.999999523162842 L 4.001859188079834 1.600959777832031 C 4.001679420471191 1.60090970993042 4.000979423522949 1.60077977180481 3.999999284744263 1.60077977180481 M 3.999999284744263 0.6007823944091797 C 4.290351867675781 0.6007823944091797 4.580704212188721 0.7258849143981934 4.780869483947754 0.9760894775390625 L 6.700239181518555 3.375309705734253 C 7.224049091339111 4.030069828033447 6.757879257202148 4.999999523162842 5.919369220733643 4.999999523162842 L 2.080629348754883 4.999999523162842 C 1.242119312286377 4.999999523162842 0.7759494781494141 4.030069828033447 1.299759387969971 3.375309705734253 L 3.219129085540771 0.9760894775390625 C 3.419294357299805 0.7258849143981934 3.709646701812744 0.6007823944091797 3.999999284744263 0.6007823944091797 Z"
            stroke="none"
            fill="#8d8d8d"
          />
        </g>
        <g
          id="長方形_117"
          transform="translate(61 374)"
          fill="#8d8d8d"
          stroke="#8d8d8d"
          stroke-width="1"
        >
          <path
            d="M1,0H5A1,1,0,0,1,6,1V3A0,0,0,0,1,6,3H0A0,0,0,0,1,0,3V1A1,1,0,0,1,1,0Z"
            stroke="none"
          />
          <path
            d="M1,.5H5a.5.5,0,0,1,.5.5V2.5a0,0,0,0,1,0,0H.5a0,0,0,0,1,0,0V1A.5.5,0,0,1,1,.5Z"
            fill="none"
          />
        </g>
      </g>
    </g>
  </svg>
</template>
