<template>
  <div class="d-flex align-items-center w-100 mt-3">
    <ul class="nav nav-tabs w-100 d-flex justify-content-between">
      <li v-for="t in Object.values(customerTypes)" :key="t" class="nav-item">
        <button
          :class="`type-title p-1 btn nav-link ${
            selectedCustomerType == t ? 'active' : ''
          }`"
          aria-current="page"
          href="#"
          @click="selectType(t)"
        >
          {{ customerTypesMap[t] }}
        </button>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { useCustomer } from "../../Composables";

defineProps({
  selectedCustomerType: {
    type: String,
    default: "explain",
  },
});

const emit = defineEmits(["update:selectedCustomerType"]);

const { customerTypes } = useCustomer();

function selectType(t) {
  emit("update:selectedCustomerType", t);
}

const customerTypesMap = {
  [customerTypes.ALL]: "すべて",
  [customerTypes.EXPLAIN]: "説明予約",
  [customerTypes.BEFORE_CONFIRMATION]: "認証前",
  [customerTypes.NOT_PAYMENT]: "未入金",
  [customerTypes.PAID]: "入金済",
};
</script>

<style lang="scss" scoped>
.type-title {
  font-size: 16px;
}

.nav-tabs {
  border-bottom: none;
  font-size: 13px;

  .nav-link {
    border: none;
  }
}

.nav-link {
  color: black;

  &.active {
    background-color: #201d16;
    color: white;
  }
}
</style>
