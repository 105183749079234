import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import createUser from "./user";
import createNews from "./news";
import createReservations from "./reservations";
import createCustomers from "./customers";
import createShopEvents from "./shopEvents";
import createCources from "./cources";
import createUI from "./ui";

export function createStoreWith($axios) {
  return createStore({
    modules: {
      cources: createCources($axios),
      customers: createCustomers($axios),
      news: createNews($axios),
      reservations: createReservations($axios),
      shopEvents: createShopEvents($axios),
      user: createUser($axios),
      ui: createUI($axios),
    },
    plugins: [
      window.navigator.cookieEnabled &&
        createPersistedState({
          key: "cm_salon",
          storage: window.sessionStorage,
        }),
    ].filter((b) => b),
  });
}
