<template>
  <div :class="`admin-card with-outer-title ${isActive ? 'active' : ''}`">
    <template v-if="mode == modeTypes.VISITED">
      <div class="admin-card-inner-title">来店済み</div>
      <EventCard
        v-if="selectedDateFinishedContents.length"
        :events="selectedDateFinishedContents"
        @to-customer-page="toCustomerPage"
      />
    </template>
    <template v-else-if="mode == modeTypes.ADD_EVENT">
      <AdminCardHeader
        :title="shopEventId ? '店内予定の編集' : '店内予定'"
        role-button
        jp
        @back="toTop"
      />
      <ShopEventNewProcess
        :all-reservation-details="reservationDetails"
        :all-shop-events="shopEvents"
        :holidays="holidays"
        :shop-event="shopEvent"
        :now="selectedDate"
        :after-submit="afterSubmitShopEventNewProcess"
        @submit="submitShopEventNewProcess"
        @to-top="toTop"
      />
    </template>
    <template v-else-if="mode == modeTypes.EDIT_RESERVATION">
      <AdminCardHeader title="来店予約の編集" role-button jp @back="toTop" />
      <ReservationEditProcess
        :reservation-detail="reservationDetail"
        :all-reservation-details="reservationDetails"
        :all-shop-events="shopEvents"
        :holidays="holidays"
        :now="selectedDate"
        :after-submit="afterSubmitReservationEditProcess"
        :loading="loading"
        is-admin
        is-pc-admin
        @submit="submitReservationEditProcess"
        @to-admin-reservations="toTop"
      />
    </template>
    <template v-else-if="mode == modeTypes.ADD_RESERVATION">
      <AdminCardHeader title="来店予約" role-button jp @back="toTop" />
      <div class="padding-for-header"></div>
      <ReservationNewProcess
        :customer="customer"
        :all-reservation-details="reservationDetails"
        :all-shop-events="shopEvents"
        :holidays="holidays"
        :now="selectedDate"
        :after-submit="afterSubmitReservationNewProcess"
        :loading="loading"
        is-admin
        is-pc-admin
        @submit="submitReservationNewProcess"
        @to-admin-reservations="toTop"
        @to-top="toTop"
      />
    </template>
    <template v-else-if="mode == modeTypes.SELECT_CUSTOMER">
      <AdminCardHeader title="来店予約" role-button jp @back="toTop" />
      <div class="padding-for-header"></div>
      <SearchForm v-model:search-word="searchWord" />
      <TypeSelector v-model:selected-customer-type="selectedCustomerType" />
      <List
        :customers="filteredCustomers || []"
        role-button
        @select-customer="selectCustomer"
      />
    </template>
  </div>
</template>

<script setup>
import { computed } from "@vue/reactivity";
import {
  useCustomer,
  useEvents,
  useReservationDetails,
  useShopEvents,
} from "/@/vue/Composables";
import { SearchForm } from "/@/vue/Components/Common";
import { NewProcess as ShopEventNewProcess } from "/@/vue/Components/ShopEvents";
import {
  Edit as ReservationEditProcess,
  NewProcess as ReservationNewProcess,
} from "../../Reservation";
import { EventCard } from "/@/vue/Components/Events";
import { TypeSelector, List } from "/@/vue/Components/Customer";
import { AdminCardHeader } from "/@/vue/Components/Admin";
import { ref, watch } from "vue";
import useSWRV from "swrv";
import { fetcher } from "/@/modules/axios";
import { errorHandle } from "/@/modules/error";

const modeTypes = {
  VISITED: "visited",
  ADD_EVENT: "add-event",
  ADD_RESERVATION: "add-reservation",
  EDIT_RESERVATION: "edit-reservation",
  SELECT_CUSTOMER: "select-customer",
};

const props = defineProps({
  reservationDetailId: {
    type: Number,
    default: null,
  },
  shopEventId: {
    type: Number,
    default: null,
  },
  customerId: {
    type: Number,
    default: null,
  },
  customers: {
    type: Array,
    default: () => [],
  },
  mode: {
    type: String,
    default: "visited",
  },
  finishedReservationDetails: {
    type: Array,
    default: () => [],
  },
  selectedDate: {
    type: Object,
    default: null,
  },
  isActive: {
    type: Boolean,
  },
});

const emit = defineEmits([
  "update:mode",
  "update:customerId",
  "toCustomerPage",
  "updateDatum",
]);

const { createSelectedDateContents } = useEvents();

const selectedDateFinishedContents = computed(() => {
  return createSelectedDateContents({
    reservationDetails: props.finishedReservationDetails,
    shopEvents: [],
  });
});

// datum

const {
  data: reservationDetails,
  error: getReservationDetailsError,
  mutate: getReservationDetailsMutate,
} = useSWRV("/api/v1/reservation_details", fetcher);

watch(getReservationDetailsError, (e) => {
  errorHandle(e);
});

const {
  data: shopEvents,
  error: getShopEventsError,
  mutate: getShopEventsMutate,
} = useSWRV("/api/v1/shop_events?for_reserve=true", fetcher);

watch(getShopEventsError, (e) => {
  errorHandle(e);
});

const {
  data: shopEvent,
  error: getShopEventError,
  mutate: getShopEventMutate,
} = useSWRV(
  () => props.shopEventId && "/api/v1/shop_events/" + props.shopEventId,
  fetcher
);

watch(getShopEventError, (e) => {
  errorHandle(e);
});

const {
  data: reservationDetail,
  error: getReservationDetailError,
  mutate: getReservatinDetailMutate,
} = useSWRV(
  () =>
    props.reservationDetailId &&
    "/api/v1/reservation_details/" + props.reservationDetailId,
  fetcher
);

watch(getReservationDetailError, (e) => {
  errorHandle(e);
});

const { data: holidays, error: getHolidaysError } = useSWRV(
  "/api/v1/calendar/get_holidays",
  fetcher
);
watch(getHolidaysError, (e) => {
  errorHandle(e);
});

const {
  data: customer,
  error: getCustomerError,
  mutate: getCustomerMutate,
} = useSWRV(
  () => props.customerId && "/api/v1/customers/" + props.customerId,
  fetcher
);

watch(getCustomerError, (e) => {
  errorHandle(e);
});

// shop event

const afterSubmitShopEventNewProcess = ref(false);
const errors = ref([]);

const { editShopEvent, addShopEvent } = useShopEvents();

async function submitShopEventNewProcess(se) {
  loading.value = true;
  let result;

  if (shopEvent.value) {
    result = await editShopEvent(se);
  } else {
    result = await addShopEvent(se);
  }

  if (result) {
    afterSubmitShopEventNewProcess.value = true;
  } else {
    errors.value = [
      "イベントの作成に失敗しました。",
      "すでに指定した時間に店舗イベントが存在します。",
    ];
  }

  loading.value = false;

  getShopEventMutate();
  getReservationDetailsMutate();
  getShopEventsMutate();
  emit("updateDatum");
}

// reservation

const afterSubmitReservationNewProcess = ref(false);
const afterSubmitReservationEditProcess = ref(false);
const loading = ref(false);

const { updateReservationDetail, newReservationDetail } =
  useReservationDetails();

async function submitReservationEditProcess(reservationDetail) {
  loading.value = true;
  const { id, reservation_at } = reservationDetail;
  if (!(await updateReservationDetail({ id, reservation_at }))) {
    errors.value = ["予約の変更に失敗しました。", ""];
  } else {
    afterSubmitReservationEditProcess.value = true;
    errors.value = [];
  }
  loading.value = false;

  getShopEventMutate();
  getReservationDetailsMutate();
  getShopEventsMutate();
  emit("updateDatum");
}

async function submitReservationNewProcess({
  reservationDetail,
  customer,
  contractCources,
}) {
  loading.value = true;

  const success = await newReservationDetail(
    reservationDetail,
    customer,
    contractCources
  );

  if (!success) {
    errors.value = [
      "予約の作成に失敗しました。",
      "もう一度お試し頂くかお電話での御予約をお願いします。",
    ];
  } else {
    afterSubmitReservationNewProcess.value = true;
    errors.value = [];
    emit("updateDatum");
  }

  loading.value = false;
}

// customer

const { customerTypes, getFilteredCustomers } = useCustomer();

const searchWord = ref(null);
const selectedCustomerType = ref(customerTypes.ALL);

const filteredCustomers = computed(() => {
  return getFilteredCustomers(
    props.customers || [],
    selectedCustomerType.value,
    searchWord.value
  );
});

function selectCustomer(customerId) {
  emit("update:customerId", customerId);
  emit("update:mode", modeTypes.ADD_RESERVATION);
}

// route

function toCustomerPage(customerId) {
  emit("toCustomerPage", customerId);
}

function toTop() {
  emit("update:mode", modeTypes.VISITED);
  afterSubmitShopEventNewProcess.value = false;
  afterSubmitReservationNewProcess.value = false;
  afterSubmitReservationEditProcess.value = false;
}
</script>

<style lang="scss" scoped>
.admin-card {
  max-width: 400px;
  min-width: 376px;
}
</style>
