<template>
  <div class="reservations-page">
    <AdminDaySelector
      v-if="!isUuidAccess"
      v-model:date="selectedDates"
      :count="targetContentsCount"
      @add-event="toAdminShopEventsNew"
      @add-reservation="toAdminReservationsNew({ customerId: null })"
    />
    <div class="d-flex flex-column admin-card mt-2">
      <AdminCardHeader title="Reserve" />

      <div v-if="targetContents?.length" class="list-group list-group-flush">
        <TableTitle title="お名前/店内の予定 受付内容" />
      </div>

      <EventCard
        v-if="targetContents?.length"
        :events="targetContents"
        @to-admin-reservations-edit-page="toAdminReservationsEditPage"
        @to-customer-page="toCustomerPage"
        @to-admin-shop-events-new="toAdminShopEventsNew"
        @cancel="openCancelModal"
        @finish="openFinishModal"
      />
      <span v-else class="w-100 flex-row-center"> 予定はありません </span>
    </div>
  </div>

  <teleport to="body">
    <CancelModal
      ref="cancelModal"
      @close="closeCancelModal"
      @delete="runCancelEvent"
    />
    <Modal
      ref="finishModal"
      slim
      no-header
      cancel-text="戻る"
      submit-text-variant="success"
      @submit="runFinish"
      @cancel="closeFinishModal"
      @hidden-bs-modal="closeFinishModal"
    >
      <div class="flex-column-center h-100">
        <span> この予約を来店済にします </span>
      </div>
    </Modal>
  </teleport>
</template>

<script setup>
import { ref, watch, onMounted } from "vue";
import { computed } from "@vue/reactivity";
import { luxonNow } from "/@/modules/luxon";
import {
  useReservations,
  useRouterUtil,
  useShopEvents,
  useEvents,
} from "/@/vue/Composables";
import {
  AdminDaySelector,
  BackButton,
  TableTitle,
} from "/@/vue/Components/Common";
import { Modal, CancelModal } from "/@/vue/Components/UI";
import { EventCard } from "/@/vue/Components/Events";
import { errorHandle } from "/@/modules/error";
import useSWRV from "swrv";
import { fetcher } from "/@/modules/axios";
import { AdminCardHeader } from "/@/vue/Components/Admin";

defineProps({
  isUuidAccess: {
    type: Boolean,
  },
});

const {
  toCustomerPage,
  toAdminReservationsEditPage,
  toAdminReservationsNew,
  toAdminShopEventsNew,
} = useRouterUtil();

// reservation detail

const {
  data: reservationDetails,
  error: getReservationDetailsError,
  mutate: getReservationDetailsMutate,
} = useSWRV("/api/v1/reservation_details", fetcher);

const { setReservationDetails, selectedDates, targetDateReservationDetails } =
  useReservations();
watch(reservationDetails, (rds) => {
  setReservationDetails(rds);
});
watch(getReservationDetailsError, (e) => {
  errorHandle(e);
});

selectedDates.value = [luxonNow];

// shopEvent

const { getSelectedDateShopEvents } = useShopEvents();

const {
  data: shopEvents,
  error: getShopEventsError,
  mutate: getShopEventsMutate,
} = useSWRV("/api/v1/shop_events", fetcher);
watch(getShopEventsError, (e) => {
  errorHandle(e);
});

const selectedDateShopEvents = computed(() => {
  return getSelectedDateShopEvents(
    shopEvents.value,
    selectedDates.value?.at(0)?.toISO()
  );
});

onMounted(() => {
  setReservationDetails(reservationDetails.value);
});

// event

const { createSelectedDateContents, cancelEvent, finishEvent } = useEvents();

const selectedDateContents = computed(() => {
  return createSelectedDateContents({
    reservationDetails: targetDateReservationDetails.value,
    shopEvents: selectedDateShopEvents.value,
  });
});

const targetContents = computed(() => {
  return selectedDateContents.value;
});

const targetContentsCount = computed(() => {
  return targetContents.value.length;
});

const selectedContent = ref();

// cancel modal

const cancelModal = ref();

function openCancelModal(content) {
  selectedContent.value = content;
  cancelModal.value.openModal();
}

function closeCancelModal() {
  selectedContent.value = null;
  cancelModal.value.closeModal();
}

async function runCancelEvent() {
  await cancelEvent(selectedContent.value);
  getReservationDetailsMutate();
  getShopEventsMutate();
  closeCancelModal();
}

// finish modal

const finishModal = ref();

function openFinishModal(content) {
  selectedContent.value = content;
  finishModal.value.openModal();
}

function closeFinishModal() {
  selectedContent.value = null;
  finishModal.value.closeModal();
}

async function runFinish() {
  await finishEvent(selectedContent.value);
  getReservationDetailsMutate();
  closeFinishModal();
}
</script>

<style lang="scss" scoped>
.reservations-page {
  display: flex;
  flex-direction: column;
  height: 100%;

  @include mq(pc) {
    display: flex;
    justify-content: flex-start;
  }
}
</style>
