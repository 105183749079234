<template>
  <div class="d-flex flex-column">
    <CheckForm
      v-for="cc in incompleteContractCources"
      :key="cc.id"
      :model-value="selectedCources"
      :value="cc"
      name="select-contract-cource"
      :form-id="`select-contract-cource-${cc.id}`"
      :label="contractCourceToStr(cc)"
      :compare-fn="(cc) => cc.id"
      @update:model-value="$emit('update:selectedCources', $event)"
    />
  </div>
</template>

<script setup>
import { computed } from "@vue/reactivity";
import { useContractCources } from "/@/vue/Composables";
import { CheckForm } from "/@/vue/Components/Common";

const props = defineProps({
  selectedCources: {
    type: Array,
    default: () => [],
  },
  contractCources: {
    type: Array,
    default: () => [],
  },
});

defineEmits(["update:selectedCources"]);

const { contractCourceToStr } = useContractCources();

const incompleteContractCources = computed(() => {
  return props.contractCources.filter((cc) => !cc.is_done);
});
</script>

<style lang="scss" scoped></style>
