<template>
  <div class="flex-column-center">
    <div class="back-btn-wrapper d-flex align-items-start">
      <BackButton />
    </div>

    <New
      v-model:selected-cources="selectedCources"
      :cources="cources"
      :after-submit="afterSubmit"
      :customer="currentCustomer"
      @submit="submit"
    />
    <template v-if="errorMessage.length">
      <span v-for="e in errorMessage" :key="e" class="text-danger">
        {{ e }}
      </span>
    </template>
  </div>
</template>

<script setup>
import useSWRV from "swrv";
import { watch, ref } from "vue";
import { fetcher } from "/@/modules/axios";
import { errorHandle } from "/@/modules/error";
import { New } from "/@/vue/Components/ContractCources";
import { useContractCources } from "/@/vue/Composables";
import { BackButton } from "/@/vue/Components/Common";

const { data: currentCustomer, error: getCurrentCustomerError } = useSWRV(
  "/api/v1/customers/current",
  fetcher
);
watch(getCurrentCustomerError, (e) => {
  errorHandle(e);
});

const { data: cources, error: getCoucresError } = useSWRV(
  "/api/v1/cources",
  fetcher
);
watch(getCoucresError, (e) => {
  errorHandle(e);
});

const selectedCources = ref([]);
const afterSubmit = ref(false);
const errorMessage = ref([]);
const { createContractCources } = useContractCources();

async function submit() {
  if (
    await createContractCources({
      cources: selectedCources.value,
      customer: currentCustomer.value,
    })
  ) {
    afterSubmit.value = true;
  } else {
    errorMessage.value = ["エラーが発生しました"];
  }
}
</script>

<style lang="scss" scoped>
.back-btn-wrapper {
  width: 100%;
  max-width: $contents-area-max-width;
}
</style>
