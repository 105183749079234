<template>
  <QrCodeErrorMain @to-index="goto({ name: 'Index' })" />
</template>

<script setup>
import { useRouterUtil } from "../Composables";
import { Main as QrCodeErrorMain } from "/@/vue/Components/Pages/QrCodeError";

const { goto } = useRouterUtil();
</script>

<style lang="scss" scoped></style>
