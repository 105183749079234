import { createApp } from "vue";
import { Vue3Mq } from "vue3-mq";
import { version } from "./version";
import LottieAnimation from "lottie-web-vue";
import "@vuepic/vue-datepicker/dist/main.css";
import "../stylesheets/application.scss";

import Root from "../vue/Pages/Root.vue";

const app = createApp(Root);

import { axios } from "./axios";
import { errorHandle } from "./error";

app.provide("$axios", axios);
app.provide("$errorHandle", errorHandle);

app.use(Vue3Mq, {
  preset: "bootstrap5",
  defaultBreakpoint: "lg", // customize this for SSR
});

app.use(LottieAnimation);

import { creatingRouter } from "../vue/Pages/router";
import { createStoreWith } from "../vue/store/index";

const store = createStoreWith(axios);

app.use(creatingRouter({ store, axios, version, errorHandle }));
app.use(store);

// directive
app.directive("scroll", {
  mounted(el, binding) {
    let f = function (evt) {
      if (binding.value(evt, el)) {
        window.removeEventListener("scroll", f);
      }
    };
    window.addEventListener("scroll", f);
  },
});

app.mount("#root-vue");
