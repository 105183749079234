<template>
  <div class="placeholder-area"></div>
</template>

<script setup></script>

<style lang="scss" scoped>
.placeholder-area {
  height: 100%;
  width: 100%;
  background-color: white;
}
</style>
