<template>
  <div :class="`d-flex align-items-center ${isAdmin ? 'admin' : ''}`">
    <ReservationCounter
      v-if="!isMobile && (count == 0 || count)"
      :date="selectedDateTime"
      :count="count"
    />
    <button
      class="btn rect-btn flex-column-center left"
      :disabled="disabled"
      @click="updateByArrow({ day: -1 })"
    >
      <ArrowBlackLeftIcon v-if="isAdmin" class="icon day-selector-icon" />
      <ArrowGrayLeftIcon v-else class="icon day-selector-icon" />
    </button>
    <div class="dp__input_wrapper">
      <BasicDatepicker
        :input="jsDate"
        input-class-name="day-selector form-control"
        :disabled="disabled"
        @update:input="update"
      />
    </div>

    <button
      class="btn rect-btn right flex-column-center right day-selector-icon"
      :disabled="disabled"
      @click="updateByArrow({ day: 1 })"
    >
      <ArrowBlackLeftIcon
        v-if="isAdmin"
        class="icon mirror day-selector-icon"
      />
      <ArrowGrayLeftIcon v-else class="icon mirror day-selector-icon" />
    </button>
    <button
      v-if="!isMobile && (count == 0 || count)"
      class="btn add-reservation"
      @click="$emit('addReservation')"
    >
      予定の追加
    </button>
  </div>
</template>

<script setup>
import { computed } from "@vue/reactivity";
import { BasicDatepicker } from "/@/vue/Components/Common";
import { fromDate } from "/@/modules/luxon";
import { ArrowBlackLeftIcon, ArrowGrayLeftIcon } from "/@/vue/Components/Icons";
import { ReservationCounter } from "/@/vue/Components/Reservation";
import { useMqUtils } from "/@/vue/Composables/mq";

const props = defineProps({
  date: {
    type: Object,
    default: () => {},
  },
  count: {
    type: Number,
    default: null,
  },
  showAddButton: {
    type: Boolean,
  },
  disabled: {
    type: Boolean,
  },
  isAdmin: {
    type: Boolean,
  },
});

const emit = defineEmits(["update:date", "addReservation"]);

const { isMobile } = useMqUtils();

const selectedDateTime = computed(() => {
  if (!props.date) {
    return {};
  }

  return props.date.at(0);
});

const jsDate = computed(() => {
  return selectedDateTime.value?.toJSDate();
});

function update(date) {
  emit("update:date", [fromDate(date)]);
}

function updateByArrow(option) {
  emit(
    "update:date",
    props.date?.map((d) => d.plus(option))
  );
}
</script>

<style lang="scss" scoped>
@import "../Form/form.scss";

.add-reservation {
  background-color: $base-black;
  height: 60px;
  width: 250px;
  color: white;
  border-radius: 2px;
}

.rect-btn.in-admin-index {
  background-color: inherit;
  color: #201d16;
}

.admin {
  .rect-btn {
    background-color: inherit;
  }
}
</style>
