<template>
  <Edit
    :reservation-detail="reservationDetail"
    :all-reservation-details="reservationDetails"
    :all-shop-events="shopEvents"
    :holidays="holidays"
    :after-submit="afterSubmit"
    :loading="loading"
    @submit="submit"
    @reset-error="resetError"
    @to-customers-index="toCustomersIndex"
  ></Edit>
  <Errors :errors="errors" />
</template>

<script setup>
import { watch } from "vue";
import { ref } from "@vue/reactivity";
import { useRoute } from "vue-router";
import { Edit } from "/@/vue/Components/Reservation";
import { useReservationDetails, useRouterUtil } from "/@/vue/Composables";
import { Errors } from "/@/vue/Components/Common";
import { errorHandle } from "/@/modules/error";
import useSWRV from "swrv";
import { fetcher } from "/@/modules/axios";

const route = useRoute();

const { toCustomersIndex } = useRouterUtil();

const { getReservationDetail, updateReservationDetail } =
  useReservationDetails();

const { data: reservationDetail, error: getReservationDetailError } =
  getReservationDetail(route.params.id);
watch(getReservationDetailError, (e) => {
  errorHandle(e);
});

const {
  data: reservationDetails,
  error: getReservationDetailsError,
  mutate: getReservationDetailsMutate,
} = useSWRV("/api/v1/reservation_details?for_reserve=true", fetcher);
watch(getReservationDetailsError, (e) => {
  errorHandle(e);
});

const {
  data: shopEvents,
  error: getShopEventsError,
  mutate: getShopEventsMutate,
} = useSWRV("/api/v1/shop_events?for_reserve=true", fetcher);
watch(getShopEventsError, (e) => {
  errorHandle(e);
});

const { data: holidays } = useSWRV("/api/v1/calendar/get_holidays", fetcher);

const errors = ref([]);
const loading = ref(false);
const afterSubmit = ref(false);

async function submit(reservationDetail) {
  loading.value = true;
  const { id, reservation_at } = reservationDetail;
  if (!(await updateReservationDetail({ id, reservation_at }))) {
    errors.value = ["予約の変更に失敗しました。", ""];
  } else {
    afterSubmit.value = true;
    errors.value = [];
  }
  loading.value = false;
}

function resetError() {
  errors.value = [];
  getReservationDetailsMutate();
  getShopEventsMutate();
}
</script>

<style lang="scss" scoped>
.next {
  margin-top: 46px;
  margin-bottom: 20px;
}

.next-btn {
  width: 100px;
}
</style>
