<template>
  <div :class="`admin-root ${isPCAdminIndex ? 'admin-index' : ''}`">
    <router-view></router-view>
  </div>
</template>

<script setup>
import { computed } from "@vue/reactivity";
import { useMqUtils, useRouterUtil } from "/@/vue/Composables";
const { isMobile } = useMqUtils();
const { isAdminIndex } = useRouterUtil();

const isPCAdminIndex = computed(() => {
  return isAdminIndex.value && !isMobile.value;
});
</script>

<style lang="scss" scoped>
.admin-root {
  width: 100%;
  //font-size: $admin-base-font-size;
  flex-grow: 1;
  padding-bottom: 2rem;

  &.admin-index {
    padding: 1rem;
  }

  @include mq(pc) {
    padding-top: 100px;
  }
}
</style>
