<template>
  <input
    :id="formId"
    :value="value"
    :class="formClass"
    :placeholder="placeholder"
    :autocomplete="autocomplete"
    @input="(e) => $emit('update:value', e.target.value)"
  />
</template>

<script setup>
import { computed } from "@vue/reactivity";

const props = defineProps({
  value: {
    type: String,
    default: null,
  },
  isValid: {
    type: Boolean,
  },
  formId: {
    type: String,
    default: null,
  },
  placeholder: {
    type: String,
    default: null,
  },
  autocomplete: {
    type: String,
    default: null,
  },
});

defineEmits(["update:value"]);

const formClass = computed(() => {
  return {
    "form-control": true,
    "is-valid": props.isValid,
    "is-invalid": !props.isValid,
  };
});
</script>

<style lang="scss" scoped>
@import "./form.scss";
</style>
