export async function updateWhenAnyChange(pre, newer, updater) {
  let updateForm = {
    id: newer.id,
  };

  const keys = Object.keys(pre);

  let hasAnyChange = false;
  let emailChange = false;

  keys.forEach((key) => {
    if (pre[key] != newer[key]) {
      hasAnyChange = true;
      emailChange = key == "email";
      updateForm[key] = newer[key];
    }
  });

  if (hasAnyChange) {
    await updater(updateForm);
    return emailChange;
  }
}
