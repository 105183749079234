import { ref } from "vue";

export function useModal() {
  const modal = ref();

  function openModal() {
    modal.value?.openModal();
  }

  function closeModal() {
    modal.value?.closeModal();
  }

  return {
    modal,
    openModal,
    closeModal,
  };
}
