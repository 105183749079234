import { DateTime, Interval, Settings } from "luxon";

export function setup() {
  Settings.defaultLocale = "ja";
  Settings.defaultZone = "Asia/Tokyo";
}

export function format(iso, f) {
  if (!iso) {
    return null;
  }

  return DateTime.fromISO(iso).toFormat(f);
}

export function getTime(iso) {
  return format(iso, "HH:mm");
}

export function getJpDay(iso) {
  return format(iso, "EEE");
}

export function getWeekday(iso) {
  return DateTime.fromISO(iso).weekday;
}

export const luxonNow = DateTime.now();
export const luxonTomorrow = luxonNow.plus({ day: 1 }).startOf("day");

export function isoNow() {
  return DateTime.now().toISO();
}

export function basic(
  input,
  option = {
    onlyMonth: false,
    onlyDateDay: false,
    noYear: false,
    withTime: false,
  }
) {
  const { onlyMonth, onlyDateDay, noYear, withTime } = option;

  let format = "yyyy年M月d日(EEE)";

  if (onlyMonth) {
    format = "yyyy年M月";
  } else if (onlyDateDay) {
    format = "d日(EEE)";
  } else if (noYear) {
    format = "M月d日(EEE)";
  }

  if (withTime) {
    format += "HH:mm";
  }

  if (typeof input == "object") {
    return DateTime.fromJSDate(input).toFormat(format);
  } else if (typeof input == "string") {
    return DateTime.fromISO(input).toFormat(format);
  }
}

export function isSameDate(a, b) {
  const la = DateTime.fromISO(a).startOf("day");
  const lb = DateTime.fromISO(b).startOf("day");

  return la.hasSame(lb, "day");
}

export function fromISO(iso) {
  if (!iso) {
    return null;
  }
  return DateTime.fromISO(iso);
}

export function fromDate(date) {
  return DateTime.fromJSDate(date);
}

export function toDateFromISO(iso) {
  return DateTime.fromISO(iso).toJSDate();
}

if (import.meta.vitest) {
  const { expect, it } = import.meta.vitest;

  setup();

  const d = "2000-01-01T10:00:00.000+09:00";

  it("toDateFromISO", () => {
    expect(toDateFromISO(d)).toStrictEqual(new Date(Date.parse(d)));
  });

  it("getTime", () => {
    expect(getTime(d)).toBe("10:00");
  });

  it("getJpDay", () => {
    expect(getJpDay(d)).toBe("土");
    expect(getJpDay("2000-01-01")).toBe("土");
  });

  it("getWeekday", () => {
    expect(getWeekday("2000-01-03")).toBe(1);
  });

  it("basic", () => {
    expect(basic(d)).toBe("2000年1月1日(土)");
    expect(basic(d, { onlyMonth: true })).toBe("2000年1月");
    expect(basic(d, { onlyDateDay: true })).toBe("1日(土)");
    expect(basic(d, { noYear: true })).toBe("1月1日(土)");
    expect(basic(d, { withTime: true })).toBe("2000年1月1日(土)10:00");
  });

  it("isSameDate", () => {
    expect(isSameDate("2022-10-31", "2022-10-31")).toBe(true);
    expect(isSameDate("2022-10-31", "2022-11-1")).toBe(false);
  });
}

// interval

function* days(interval) {
  let cursor = interval.start.startOf("day");
  while (cursor < interval.end) {
    yield cursor;
    cursor = cursor.plus({ days: 1 });
  }
}

export function createBetween(dates) {
  if (dates.length < 2) {
    return dates;
  }

  const first = dates.at(0);
  const last = dates.at(-1);
  const interval = Interval.fromDateTimes(first, last);
  return Array.from(days(interval));
}

if (import.meta.vitest) {
  const { expect, it } = import.meta.vitest;

  setup();

  const dates = [fromISO("2022-12-01"), fromISO("2022-12-03")];

  it("createBeetween", () => {
    expect(createBetween(dates)).toStrictEqual([
      fromISO("2022-12-01"),
      fromISO("2022-12-02"),
    ]);
  });
}

// predicate

export function isFuture(at) {
  return DateTime.now() < fromISO(at);
}

if (import.meta.vitest) {
  const { expect, it } = import.meta.vitest;

  setup();

  const d1 = "2000-01-01T10:00:00.000+09:00";
  const d2 = "9999-01-01T10:00:00.000+09:00";

  it("isFuture", () => {
    expect(isFuture(d1)).toBe(false);
    expect(isFuture(d2)).toBe(true);
  });
}
