<template>
  <div class="mt-3 memo-area edittable list-group list-group-flush w-100">
    <TableTitle title="契約内容" />
    <div v-if="!hideEdit" class="edit-btn-wrapper">
      <EditToggleButton @start="edit = true" @finish="update" />
    </div>
    <div class="add-btn-wrapper">
      <button
        class="btn flex-row-center small-font"
        @click="$emit('toContractCourcesNew')"
      >
        <span class="me-2">新しくコースを追加</span>
        <div class="btn-icon">
          <PlusIcon class="icon" />
        </div>
      </button>
    </div>
    <div class="form-wrapper mt-4">
      <div v-for="cc in contractCources" :key="cc.id">
        <Show
          :contract-cource="cc"
          :edit-mode="edit"
          @update="updatePaymentAt"
          @delete="deleteRD"
        ></Show>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from "@vue/reactivity";
import { ref } from "vue";
import { EditToggleButton, TableTitle } from "/@/vue/Components/Common";
import { PlusIcon } from "/@/vue/Components/Icons";
import { Show } from "/@/vue/Components/ContractCources";

const props = defineProps({
  customer: {
    type: Object,
    default: () => {},
  },
  hideEdit: {
    type: Boolean,
  },
});

const emit = defineEmits([
  "updateContractCource",
  "updateRD",
  "deleteRD",
  "toContractCourcesNew",
]);

const contractCources = computed(() => {
  return props.customer?.contract_cources || [];
});

const edit = ref(false);

function update() {
  edit.value = false;
}

function updatePaymentAt(contractCource) {
  emit("updateContractCource", contractCource);
}

function deleteRD(id) {
  emit("deleteRD", id);
}
</script>

<style lang="scss" scoped>
@import "./editable.scss";

.btn-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #8d8d8d;
  height: 22px;
  width: 22px;
  padding: 0;
}

.btn {
  padding: 0;
}
</style>
