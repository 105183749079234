<template>
  <AdminReservations
    is-uuid-access
    :reservation-details="reservationDetails"
  ></AdminReservations>
</template>

<script setup>
import { computed } from "@vue/reactivity";
import AdminReservations from "../Admin/Reservations/Index.vue";
import { useReservationDetails, useRouterUtil } from "/@/vue/Composables";

const { routerId } = useRouterUtil();
const { getReservationDetail } = useReservationDetails();

const reservationDetails = computed(() => {
  const reservationDetail = getReservationDetail(routerId.value);
  return reservationDetail ? [reservationDetail] : [];
});
</script>

<style lang="scss" scoped></style>
