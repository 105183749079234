<template>
  <button
    class="btn reservation-btn"
    :disabled="disabled"
    @click="
      $emit(
        'update:selectedDate',
        selectedDate == target_date ? null : target_date
      )
    "
  >
    <div class="day-card-wrapper d-flex flex-column align-items-center">
      <div class="jp-day w-100">{{ jpDay }}</div>
      <div
        :class="`day-card p-2 flex-column-center ${
          selectedDate == target_date ? 'selected' : ''
        }`"
      >
        <div>{{ date }}</div>
        <div class="pt-2 status-symbol">{{ statusSymbol }}</div>
      </div>
    </div>
  </button>
</template>

<script setup>
import { computed } from "vue";
import { fromISO, luxonNow } from "/@/modules/luxon";
import { useShowReservation } from "/@/vue/Composables";

const props = defineProps({
  target_date: {
    type: String,
    required: true,
  },
  status: {
    type: String,
    required: true,
  },
  selectedDate: {
    type: String,
    default: null,
  },
  now: {
    type: Object,
    default: () => luxonNow,
  },
  isAdmin: {
    type: Boolean,
  },
});

defineEmits(["update:selectedDate"]);

const { setReservation, jpDay, date, statusSymbol } = useShowReservation();

setReservation(props);

const disabled = computed(() => {
  if (props.isAdmin) {
    return false;
  }

  const yesterday = props.now.plus({ day: -1 });
  return fromISO(props.target_date) <= yesterday;
});
</script>

<style lang="scss" scoped>
.btn.reservation-btn {
  border: none;
  padding: 0;
  border-radius: 0%;

  &:not(:first-child) {
    margin-left: 10px;

    @include mq(pc) {
      margin-left: 30px;
    }
  }

  .day-card {
    @extend .with-shadow;
    width: 40px;
    height: 60px;
    background-color: white;
    border: 1px solid rgba(#707070, 0.5);
    border-radius: 2px !important;

    .status-symbol {
      @include mq(pc) {
        font-size: 36px;
      }
    }

    &.selected {
      background-color: black;
      color: #cccccc;
    }

    @include mq(pc) {
      width: 100px;
      height: 100px;
    }
  }

  &:focus {
    box-shadow: none;
  }

  &:disabled {
    .day-card {
      background-color: #707070;
    }
  }
}
</style>
