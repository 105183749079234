<template>
  <div class="py-3 px-2 w-100 flex-column-center">
    <MimozaLeft :class="`fixed-mimoza left ${showMimoza ? 'show' : ''}`" />
    <MimozaRight :class="`fixed-mimoza right ${showMimoza ? 'show' : ''}`" />

    <div class="index-contents d-flex flex-column align-items-center">
      <GeneralAlert
        v-if="isSuccessConfirmation"
        title="メール認証に成功しました"
        @close-alert="removeParams('success_confirmation')"
      >
        <template #footer>
          <p>
            説明のご予約を行った方には予約完了のメールを送付しています。ご確認下さい。
          </p>
        </template>
      </GeneralAlert>

      <FadeComponent fade-type="fade-zoom-in" class="top-image w-100">
        <TopImage />
      </FadeComponent>

      <FadeComponent class="about w-100">
        <div id="about"></div>
        <AboutArea />
      </FadeComponent>

      <FadeComponent class="news w-100">
        <div id="news"></div>
        <NewsList :news="news" />
      </FadeComponent>

      <FadeComponent class="menu w-100" data-aos="fade-up">
        <div id="menu"></div>
        <MenuArea />
      </FadeComponent>

      <FadeComponent class="service w-100">
        <div id="service"></div>
        <ServiceArea />
      </FadeComponent>

      <FadeComponent class="notification-card w-100 flex-column-center">
        <NotificationCard />

        <div class="reservation w-100 d-flex justify-content-center">
          <ReservationButton @click="toReservationsNew('explain')" />
        </div>
      </FadeComponent>

      <FadeComponent class="access w-100">
        <div id="access"></div>
        <AccessArea></AccessArea>
      </FadeComponent>
    </div>
  </div>
</template>

<script setup>
import useSWRV from "swrv";
import { fetcher } from "/@/modules/axios";
import { watch, onMounted } from "vue";
import { computed } from "@vue/reactivity";
import { errorHandle } from "/@/modules/error";
import { FadeComponent, GeneralAlert } from "/@/vue/Components/Common";
import {
  TopImage,
  NewsList,
  AboutArea,
  MenuArea,
  ServiceArea,
  AccessArea,
  NotificationCard,
} from "/@/vue/Components/Pages/Index";
import { ReservationButton } from "/@/vue/Components/Reservation";
import { useMqUtils, useRouterUtil, useAnimation } from "/@/vue/Composables";
import { MimozaLeft, MimozaRight } from "/@/vue/Components/Images";
import { useStore } from "vuex";

const store = useStore();

const showUI = computed(() => {
  return store.state.ui.showUI;
});

const { isMobile } = useMqUtils();

const showMimoza = computed(() => {
  return showUI.value && !isMobile.value;
});

const { toReservationsNew, isSuccessConfirmation, removeParams } =
  useRouterUtil();

const { data: feeds, error: getFeedsError } = useSWRV("/api/v1/feeds", fetcher);
watch(getFeedsError, (e) => {
  errorHandle(e);
});

const news = computed(() => {
  return isMobile.value ? feeds.value?.slice(0, 2) : feeds.value;
});

const { marker } = useAnimation();

onMounted(() => {
  marker();
});
</script>

<style lang="scss" scoped>
.index-contents {
  width: 100%;
  max-width: 100%;
  padding: 0.5rem;

  @include mq(pc) {
    max-width: 1740px;
  }
}

.top-image {
  min-height: calc(100vh - 150px);

  @include mq(pc) {
    padding-top: 0;
  }
}

.news {
  margin-top: calc(64px + 30px);

  @include mq(pc) {
    margin-top: calc(180px + 90px);
  }
}

.about {
  margin-top: 125px;

  @include mq(pc) {
    margin-top: 360px;
  }
}

.menu {
  margin-top: 64px;

  @include mq(pc) {
    margin-top: 180px;
  }
}

.service {
  margin-top: 64px;

  @include mq(pc) {
    margin-top: 180px;
  }
}

.notification-card {
  padding-top: 85px;

  @include mq(pc) {
    margin-top: 180px;
  }
}

.reservation {
  margin-top: 64px;
}

.access {
  margin-top: 64px;

  @include mq(pc) {
    margin-top: 180px;
  }
}

// mimoza

.fixed-mimoza {
  position: fixed;
  object-fit: cover;
  opacity: 0;
  transition: opacity 1s ease-in;
  z-index: -1;

  &.show {
    opacity: 1;
  }

  &.left {
    left: -75px;
    top: -20px;
    height: 300px;
    width: 200px;

    @include mq(pc) {
      height: 600px;
      width: 400px;

      top: -100px;
      left: 0;
    }
  }

  &.right {
    right: -50px;
    top: -10px;
    height: 300px;
    width: 200px;

    @include mq(pc) {
      height: 800px;
      width: 400px;

      top: -140px;
    }
  }
}

// alert

.alert {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 1rem;
  margin: 0 auto;
  width: 100%;
  max-width: $contents-area-max-width;
  z-index: 1000;
}
</style>
