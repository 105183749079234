import Axios from "axios";
export const axios = Axios.create({
  headers: {
    "X-Requested-With": "XMLHttpRequest",
    //"X-CSRF-TOKEN": csrfToken()
  },
  withCredentials: true,
});

export const fetcher = (url) => {
  return axios.get(url).then((res) => res.data);
};
