import Index from "./Index.vue";
import QR from "./QR.vue";

import {
  Root as ShopEventsRoot,
  Router as ShopEventsRouter,
} from "./ShopEvents";
import {
  Root as ReservationsRoot,
  Router as ReservationsRouter,
} from "./Reservations";
import { Root as CustomersRoot, Router as CustomersRouter } from "./Customers";
import {
  Root as ContractCourcesRoot,
  Router as ContractCourcesRouter,
} from "./ContractCources";

export default [
  {
    path: "",
    name: "AdminIndex",
    component: Index,
  },
  {
    path: "qr",
    name: "AdminQr",
    component: QR,
  },
  {
    path: "shop_events",
    component: ShopEventsRoot,
    children: [...ShopEventsRouter],
  },
  {
    path: "reservations",
    component: ReservationsRoot,
    children: [...ReservationsRouter],
  },
  {
    path: "customers",
    component: CustomersRoot,
    children: [...CustomersRouter],
  },
  {
    path: "contract_cources",
    component: ContractCourcesRoot,
    children: [...ContractCourcesRouter],
  },
];
