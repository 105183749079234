<template>
  <div v-if="cources.length" class="list-group list-group-flush">
    <TableRow title="希望コース">
      <div class="d-flex flex-column w-100">
        <span>{{ selectedCourceType }}</span>
        <span v-for="pc in partsCources" :key="pc.cource_part">
          ・{{ pc.title }}
        </span>
      </div>
    </TableRow>
  </div>
</template>

<script setup>
import { computed } from "@vue/reactivity";
import { useCources } from "../../Composables";
import { TableRow } from "/@/vue/Components/Common";

const props = defineProps({
  cources: {
    type: Array,
    default: () => [],
  },
});

const { courceTypes } = useCources();

const selectedCourceType = computed(() => {
  if (!props.cources?.length) {
    return "";
  }
  const selected = props.cources.at(0);

  switch (selected.cource_type) {
    case courceTypes.ALL:
      return "全身コース";
    case courceTypes.PARTS:
      return "パーツコース";
    case courceTypes.EXPLAIN:
      return "説明・相談";
  }
});

const { getPartsCources } = useCources();

const partsCources = computed(() => {
  if (!props.cources) {
    return [];
  }
  return getPartsCources(props.cources);
});
</script>

<style lang="scss" scoped></style>
